import { action } from 'mobx'

import addFlash from './AddFlash'
import { setChatResponseLoading } from './chatResponseLoadingActions'
import { getInputValue, setInputValue } from './InputValueActions'
import { addMessage, initializeMessages, setMessages } from './messagesActions'
import { resetInputMessage, resetInputFile } from './ResetInput'
import { setIsStreaming } from './streamingStateActions'
import {
  createChatThreadMessage,
  connectFilesWithMessage,
  startBroadcast,
} from '../apis/chatApi'
import { removeStoredMessage } from '../helpers/LocalStorageUtil'
import { rollbarConfig } from '../helpers/rollbarConfig'

const handleError = (store, error, message) => {
  rollbarConfig(store)?.error(error)
  addFlash(store, 'error', message)
  throw error
}

const handleCreateChatThreadMessageResponse = async (
  store,
  response,
  matterId,
  message
) => {
  if (response.status !== 201) {
    const errorMessage = 'Error creating chat thread message'
    handleError(store, new Error(errorMessage), errorMessage)
  }

  const newChatThreadMessage = response.data

  if (message.length > 0) {
    initializeMessages(store, matterId)
    addMessage(store, matterId, newChatThreadMessage)
  }

  setChatResponseLoading(store, matterId, true)
  resetInputMessage(store)

  const startBroadcastMessagePayload = { id: newChatThreadMessage.id }
  await startBroadcast(startBroadcastMessagePayload, store)
}

const submitMessage = action(async (store) => {
  if (!store.selectedMatter) {
    return
  }

  const matterId = store.selectedMatter.id
  setIsStreaming(store, matterId, true)

  const message = getInputValue(store).trim()
  if (message.length === 0 && store.uploadedFiles.length !== 0) {
    return
  }

  const newMessagePayload = {
    content: message,
    matter_id: matterId,
    thread_id: store.selectedMatter.thread_id,
  }

  setInputValue(store, '')
  removeStoredMessage(store.selectedMatter?.id)

  try {
    const response = await createChatThreadMessage(store, newMessagePayload)
    await handleCreateChatThreadMessageResponse(
      store,
      response,
      matterId,
      message
    )
  } catch (error) {
    handleError(store, error, 'Something went wrong. Please refresh the page.')
  }
})

const submitLegacyFiles = action(async (store) => {
  if (!store.selectedMatter || store.uploadedFiles.length === 0) {
    return
  }

  const matterId = store.selectedMatter.id
  setIsStreaming(store, matterId, true)

  const newMessagePayload = {
    matter_id: matterId,
    thread_id: store.selectedMatter.thread_id,
  }

  try {
    const response = await createChatThreadMessage(store, newMessagePayload)
    if (response.status !== 201) {
      const errorMessage =
        'Error creating chat thread message when uploading files'
      handleError(store, new Error(errorMessage), errorMessage)
    }

    const newChatThreadMessage = response.data
    const fileUploadPayload = {
      id: newChatThreadMessage.id,
      thread_id: newChatThreadMessage.thread_id,
      matter_id: matterId,
      e_attachment_ids: (store.uploadedFiles ?? []).map(
        (file) => file.e_attachment_id
      ),
    }

    const connectFilesWithMessageResponse = await connectFilesWithMessage(
      store,
      fileUploadPayload
    )
    setMessages(
      store,
      matterId,
      connectFilesWithMessageResponse.data.chat_thread_messages
    )
    setChatResponseLoading(store, matterId, true)
    resetInputFile(store)

    const startBroadcastMessagePayload = { id: newChatThreadMessage.id }
    await startBroadcast(startBroadcastMessagePayload, store)
  } catch (error) {
    handleError(
      store,
      error,
      'Something went wrong when uploading files. Please refresh the page.'
    )
  }
})

export { submitMessage, submitLegacyFiles }
