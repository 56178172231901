import { observer } from 'mobx-react-lite'
import React from 'react'

import AsyncChatMessage from './AsyncChatMessage'
import ChatMessageSkeleton from './ChatMessageSkeleton'
import { useStore } from '../../helpers/useStore'

const LoadingMessage = observer(() => {
  const store = useStore()

  if (
    !store.computed.mostRecentUserMessage ||
    !store.computed.mostRecentUserMessage.finish_time_at
  ) {
    return <ChatMessageSkeleton />
  } else {
    return (
      <AsyncChatMessage
        chat_thread_message={store.computed.mostRecentUserMessage}
      />
    )
  }
})

export default LoadingMessage
