import React from 'react'

const ReferencedWebSourceIcon = ({
  className = 'stroke-icon-default-default',
}: {
  className?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_5570_26219)">
        <path
          d="M10.0003 18.3337C14.6027 18.3337 18.3337 14.6027 18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699C5.39795 1.66699 1.66699 5.39795 1.66699 10.0003C1.66699 14.6027 5.39795 18.3337 10.0003 18.3337Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.66699 10.0003H18.3337M10.0003 1.66699C7.86052 3.91379 6.66699 6.89761 6.66699 10.0003C6.66699 13.103 7.86052 16.0869 10.0003 18.3337C12.1401 16.0869 13.3337 13.103 13.3337 10.0003C13.3337 6.89761 12.1401 3.91379 10.0003 1.66699Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5570_26219">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ReferencedWebSourceIcon
