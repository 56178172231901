import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { MATTER_BREADCRUMBS } from './queries'
import { fetchBreadcrumbs } from '../apis/filesApi'
import Store from '../helpers/Store'

export interface Breadcrumb {
  id: string
  title: string
  type: string
}

export interface BreadcrumbsResponse {
  breadcrumbs: Breadcrumb[]
}

const useBreadcrumbs = (store: Store) => {
  const { id, folderId = '', documentId = '' } = useParams()

  const documentPaginationParamsString = JSON.stringify([
    store.documentPagination.page,
    store.documentPagination.perPage,
  ])

  return useQuery<BreadcrumbsResponse>({
    queryKey: [
      MATTER_BREADCRUMBS,
      id,
      folderId,
      documentId,
      documentPaginationParamsString,
    ],
    queryFn: () => fetchBreadcrumbs(store, id, folderId, documentId),
    enabled: Boolean(id),
    staleTime: store.computed.uploadInProgress ? 0 : Infinity,
  })
}

export default useBreadcrumbs
