const FolderPlusIcon = ({
  className = 'stroke-icon-default-secondary',
}: {
  className?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M12.51 19H5.51001C4.97958 19 4.47087 18.7893 4.0958 18.4142C3.72072 18.0391 3.51001 17.5304 3.51001 17V6C3.51001 5.46957 3.72072 4.96086 4.0958 4.58579C4.47087 4.21071 4.97958 4 5.51001 4H9.51001L12.51 7H19.51C20.0404 7 20.5492 7.21071 20.9242 7.58579C21.2993 7.96086 21.51 8.46957 21.51 9V12.5M16.51 19H22.51M19.51 16V22"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FolderPlusIcon
