import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { useQueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import React from 'react'

import addFlash from '../../../actions/AddFlash'
import { deleteFolder } from '../../../apis/foldersApi'
import { rollbarConfig } from '../../../helpers/rollbarConfig'
import { Folder } from '../../../helpers/Store'
import { useStore } from '../../../helpers/useStore'
import { FILES_AND_FOLDERS } from '../../../hooks/queries'
import CloseIcon from '../../assets/CloseIcon'

interface FolderDeletionModalProps {
  folder: Folder
  isOpen: boolean
  handleOpen: () => void
}
const FolderDeletionModal = observer(
  ({ folder, isOpen, handleOpen }: FolderDeletionModalProps) => {
    const store = useStore()
    const queryClient = useQueryClient()

    const handleDelete = async () => {
      try {
        await deleteFolder(store, folder.id)
        handleOpen()
        addFlash(
          store,
          'notice',
          `${folder.title} has been permanently deleted!`,
          {
            subType: 'deleteFolder',
          }
        )
        queryClient.invalidateQueries({
          queryKey: [
            FILES_AND_FOLDERS,
            store.selectedMatter?.id,
            folder.parent_id,
          ],
        })
      } catch (error) {
        handleOpen()
        addFlash(
          store,
          'error',
          `We couldn't delete the document. Retry or contact support@alexi.com for assistance.`,
          {
            subType: 'deleteFolder',
          }
        )
        // @ts-expect-error rollbar handles this
        rollbarConfig(store)?.error(error)
      }
    }

    return (
      <Dialog open={isOpen} handler={handleOpen} className="rounded-lg">
        <DialogHeader className="justify-between items-center p-6 border-b border-brd-default-defaul">
          <span className="font-semibold text-lg">Delete the folder</span>
          <button
            onClick={handleOpen}
            className="absolute top-6 right-6 bg-transparent text-black"
          >
            <CloseIcon />
          </button>
        </DialogHeader>
        <DialogBody className="px-6 py-4 justify-center items-center">
          <span className="block mb-4 text-base font-normal text-txt-default-default">
            Are you sure you would like to delete{' '}
            <span className="md:italic text-base font-medium">
              {folder.title}
            </span>
            ?
          </span>
          <span className="text-base font-normal text-txt-default-default">
            This will delete all documents and folders inside it and permanently
            remove them from our system.
          </span>
        </DialogBody>
        <DialogFooter className="flex justify-end items-center">
          <button
            className="bg-bgCol-disabled-default text-sm font-medium text-txt-default-default px-4 py-2 rounded mr-5"
            onClick={handleOpen}
          >
            No, cancel
          </button>
          <button
            className="bg-bgCol-danger-default text-sm font-medium text-white px-4 py-2 rounded mr-2"
            onClick={handleDelete}
          >
            Yes, delete
          </button>
        </DialogFooter>
      </Dialog>
    )
  }
)

export default FolderDeletionModal
