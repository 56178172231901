import { useQueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import addFlash from '../../actions/AddFlash'
import {
  downloadFile,
  updateFile,
  UpdateFilePayload,
} from '../../apis/filesApi'
import { getIconForFileType } from '../../helpers/constants'
import { EnterpriseAttachment } from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import {
  FILES_AND_FOLDERS,
  GET_ENTERPRISE_ATTACHMENT,
  MATTER_BREADCRUMBS,
} from '../../hooks/queries'
import Loading from '../assets/Loading'
import AttachmentActionMenu from '../workflow/document_action/AttachmentActionMenu'
import AttachmentDeleteModal from '../workflow/document_action/AttachmentDeleteModal'
import MoveToFolderModal from '../workflow/document_action/MoveToFolderModal'
import NameEditor from '../workflow/document_action/NameEditor'

interface DocumentProps {
  doc: EnterpriseAttachment
  onClick: (file: EnterpriseAttachment) => void
}

function iconContent(attachment: EnterpriseAttachment) {
  if (attachment.processing_status === 'COMPLETED') {
    return getIconForFileType(attachment)
  } else {
    return (
      <div className="flex justify-center">
        <div className="w-6 h-6">
          <Loading />
        </div>
      </div>
    )
  }
}

function filenameContent(attachment: EnterpriseAttachment) {
  if (attachment.processing_status === 'COMPLETED') {
    return attachment.filename
  } else {
    return (
      <div>
        <div>
          <span className="text-txt-default-tertiary truncate block w-full overflow-hidden text-ellipsis whitespace-nowrap">
            {attachment.filename}
          </span>
        </div>
        <div>
          <span className="text-txt-default-tertiary text-xs">
            {attachment.loadingEstimateMessage}
          </span>
        </div>
      </div>
    )
  }
}

function contentTypeContent(attachment: EnterpriseAttachment) {
  if (attachment.processing_status === 'COMPLETED') {
    return attachment.content_type
  } else {
    return (
      <div className="h-3 rounded bg-pulse-gradient bg-200 animate-pulse-left-to-right"></div>
    )
  }
}

const AttachmentTableRow: React.FC<DocumentProps> = observer(
  ({ doc, onClick }) => {
    const store = useStore()
    const queryClient = useQueryClient()

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isMoveToNewFolderModalOpen, setIsMoveToNewFolderModalOpen] =
      useState(false)

    const [isEditingName, setIsEditingName] = useState(false)
    const [filename, setFilename] = useState(doc.filename)

    const isClickable = doc.processing_status === 'COMPLETED'

    if (
      doc.processing_status === 'FAILED' ||
      doc.processing_status === 'INVALID'
    ) {
      return null
    }

    const handleFileDownload = async () => {
      await downloadFile(store, doc.id)
    }

    const submitFilename = async () => {
      setIsEditingName(false)
      await save({ filename })
    }

    const save = async (payload: UpdateFilePayload) => {
      if (!doc.id) {
        return
      }
      try {
        await updateFile(store, doc.id, payload)
        queryClient.invalidateQueries({
          queryKey: [
            FILES_AND_FOLDERS,
            store.selectedMatter?.id,
            doc.folder_id,
          ],
        })
        queryClient.invalidateQueries({
          queryKey: [
            GET_ENTERPRISE_ATTACHMENT,
            store.selectedMatter?.id,
            doc.id,
          ],
        })
        // invalidate the destination folder cached
        if (payload.folder_id) {
          queryClient.invalidateQueries({
            queryKey: [
              FILES_AND_FOLDERS,
              store.selectedMatter?.id,
              payload.folder_id,
            ],
          })
          queryClient.invalidateQueries({
            queryKey: [
              MATTER_BREADCRUMBS,
              store.selectedMatter?.id,
              payload.folder_id,
              doc.id,
            ],
          })
        }
      } catch (error) {
        addFlash(
          store,
          'error',
          `We couldn't update ${doc.filename}. please try again`
        )
      }
    }

    return (
      <>
        <tr
          key={doc.id}
          className={`h-12 ${isClickable ? 'cursor-pointer hover:bg-bgCol-default-defaultHover' : ''} transition duration-500 ${doc.showCompletedGradient ? 'bg-[#F5F2FF]' : 'bg-white'}`}
          onClick={
            isClickable && !isEditingName ? () => onClick(doc) : undefined
          }
        >
          <td className="p-1">
            <div className="flex justify-center align-center">
              {iconContent(doc)}
            </div>
          </td>
          <td className="p-1 text-sm font-normal max-w-[100px] overflow-hidden truncate">
            {isEditingName ? (
              <NameEditor
                name={filename}
                handleChange={(value: string) => setFilename(value)}
                submitChange={submitFilename}
              />
            ) : (
              filenameContent(doc)
            )}
          </td>
          <td className="p-1 text-sm font-normal">{contentTypeContent(doc)}</td>
          <td className="px-2 py-2 justify-center">
            <AttachmentActionMenu
              attachment={doc}
              onView={() => onClick(doc)}
              onEditName={() => setIsEditingName(true)}
              onDownload={handleFileDownload}
              onDelete={() => setIsDeleteModalOpen(true)}
              onMove={() => {
                setIsMoveToNewFolderModalOpen(true)
              }}
            />
          </td>
        </tr>
        {isDeleteModalOpen && (
          <AttachmentDeleteModal
            doc={doc}
            onClose={() => setIsDeleteModalOpen(false)}
          />
        )}
        <MoveToFolderModal
          name={filename}
          isOpen={isMoveToNewFolderModalOpen}
          handleOpen={() => {
            setIsMoveToNewFolderModalOpen(!isMoveToNewFolderModalOpen)
          }}
          handleSave={(folderId) => {
            save({ folder_id: folderId })
          }}
        />
      </>
    )
  }
)

AttachmentTableRow.displayName = 'AttachmentTableRow'

export default AttachmentTableRow
