import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { setSelectedMatter } from '../../actions/matterActions'
import { getMessages, setMessages } from '../../actions/messagesActions'
import { setShowLatestMessagesButton } from '../../actions/showLayoutActions'
import { getIsStreaming } from '../../actions/streamingStateActions'
import { fetchChatHistory } from '../../apis/chatApi'
import { Message } from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import useContainerSize from '../../hooks/useContainerSize'
import MainChatChatInputGroup from '../main_chat/MainChatChatInputGroup'
import MainChatHeaderLeftButtonGroup from '../main_chat/MainChatHeaderLeftButtonGroup'
import MainChatHeaderRightButtonGroup from '../main_chat/MainChatHeaderRightButtonGroup'
import MainChatMessageGroup from '../main_chat/MainChatMessageGroup'
import SettingsModal from '../SettingsModal'
import CollapsibleLayout from '../shared/CollapsibleLayout'

export const MainChat = observer(() => {
  const store = useStore()
  const { id: matterId } = useParams()

  const {
    containerRef: chatContainerRef,
    containerWidth,
    setContainerWidth,
  } = useContainerSize()

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isLoadingMessages, setIsLoadingMessages] = useState(true)

  const matterMessages = getMessages(store, matterId)
  const lastMessageLength = matterMessages?.at(-1)?.content?.length
  const matterMessageStoreLength = matterMessages?.length
  const isNewChatResponseLoading = getIsStreaming(store, matterId)

  useEffect(() => {
    const matter = store.matters.find((m) => m.id === matterId)
    if (matter && store.selectedMatter?.id !== matter.id) {
      setSelectedMatter(store, matter)
    }

    if (matterId) {
      loadChatHistory()
    }
  }, [matterId])

  useEffect(() => {
    scrollToLastUserMessage()
    setShowLatestMessagesButton(store, false)
  }, [matterMessageStoreLength, lastMessageLength, isLoadingMessages])

  useEffect(() => {
    setContainerWidth()
  }, [store.showLayouts.showSidebar, store.showLayouts.showDocumentManager])

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: 'instant',
      })
    }
  }

  const scrollToLastUserMessage = () => {
    if (!chatContainerRef.current) {
      return
    }
    const chatContainer = chatContainerRef.current
    const messages = chatContainer.querySelectorAll('.chat-message') // Adjust this selector based on your actual class
    const lastUserMessage = Array.from(messages)
      .reverse()
      .find((msg) => msg.getAttribute('data-role') === 'user') // Assuming you set a data attribute for role

    if (lastUserMessage) {
      const chatContainerRect = chatContainer.getBoundingClientRect()
      const messageRect = lastUserMessage.getBoundingClientRect()
      const scrollOffset =
        messageRect.top - chatContainerRect.top + chatContainer.scrollTop
      chatContainer.scrollTo({ top: scrollOffset, behavior: 'instant' })
    } else {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: 'instant',
      })
    }
  }

  const checkIfNewMessagesBelow = () => {
    const chatContainer = chatContainerRef.current
    if (!chatContainer) {
      return
    }

    const { scrollTop, scrollHeight, clientHeight } = chatContainer
    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight)
    // Show the latest messages button only if the distance from the bottom is greater than 200px
    setShowLatestMessagesButton(store, distanceFromBottom > 100)
  }

  const loadChatHistory = async () => {
    setIsLoadingMessages(true)
    try {
      const messages: Message[] = await fetchChatHistory(store, {
        chat_thread_message: {
          matter_id: matterId,
          thread_id: store.selectedMatter?.thread_id,
        },
      })

      setMessages(store, matterId, messages)
    } catch (error) {
      console.error('Error fetching chat history:', error)
    } finally {
      setIsLoadingMessages(false)
    }
  }

  return (
    <div className="border-l border-r">
      <CollapsibleLayout
        navBarClassName="h-[77px] md:min-w-1/2"
        headerLeftComponent={
          <MainChatHeaderLeftButtonGroup
            handleSettingClick={(value) => setIsSettingsOpen(value)}
            containerWidth={containerWidth}
          />
        }
        headerRightComponent={<MainChatHeaderRightButtonGroup />}
      >
        <div className="flex flex-1 flex-col w-full min-h-0">
          {/* Chat Message Container */}
          <div
            className={`flex-1 overflow-auto chat-container`}
            ref={chatContainerRef}
            onScroll={checkIfNewMessagesBelow}
          >
            <MainChatMessageGroup
              isLoadingMessages={isLoadingMessages}
              messages={matterMessages}
              containerWidth={containerWidth}
              isNewChatResponseLoading={isNewChatResponseLoading}
            />
          </div>
          {/* Chat Input */}
          <div className="bg-white p-6 pb-2 sticky bottom-0 items-center">
            <MainChatChatInputGroup
              showChatSuggestions={
                !matterId || (!isLoadingMessages && matterMessages.length === 0)
              }
              scrollToBottom={scrollToBottom}
            />
          </div>
          {/* Setting Modal*/}
          {store.selectedMatter && isSettingsOpen && (
            <SettingsModal
              matter={store.selectedMatter}
              onClose={() => {
                setIsSettingsOpen(false)
              }}
            />
          )}
        </div>
      </CollapsibleLayout>
    </div>
  )
})

export default MainChat
