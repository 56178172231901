import axios from 'axios'

import { extendSessionTimeout } from '../../actions/sessionActions'
import { rollbarConfig } from '../../helpers/rollbarConfig'
import Store from '../../helpers/Store'

interface AdminMatterApiProps {
  store: Store
  matterId?: string
}

const getAdminMatter = async ({ store, matterId }: AdminMatterApiProps) => {
  extendSessionTimeout(store)

  if (!matterId) {
    throw new Error('Matter ID is required')
  }

  try {
    const response = await axios.get(
      '/api/client/v1/admin_matters/get_admin_matter',
      {
        params: {
          matterId: matterId,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error('Failed to fetch admin matters')
    }

    return response.data
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

export default getAdminMatter
