import { observer } from 'mobx-react-lite'
import React from 'react'
import { useParams } from 'react-router-dom'

import { InitialChatSuggestions } from './InitialChatSuggestions'
import LatestMessageButton from './LatestMessageButton'
import ReadOnlyChatInput from './read_only/ReadOnlyChatInput'
import { useStore } from '../../helpers/useStore'
import ChatInput from '../ChatInput'

interface MainChatChatInputGroupProps {
  showChatSuggestions: boolean
  scrollToBottom: () => void
}

const MainChatChatInputGroup = observer(
  ({ scrollToBottom, showChatSuggestions }: MainChatChatInputGroupProps) => {
    const store = useStore()
    const { id: matterId } = useParams()

    return (
      <>
        {store.showLatestMessagesButton && (
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-10">
            <LatestMessageButton
              store={store}
              scrollToBottom={scrollToBottom}
            />
          </div>
        )}
        {showChatSuggestions && <InitialChatSuggestions />}
        {!matterId || store.selectedMatter?.is_alr ? (
          <ChatInput />
        ) : (
          <ReadOnlyChatInput />
        )}
        <div className="mt-2 text-center text-xs text-txt-neutral-secondary">
          Alexi provides information to the best of its abilities. Please verify
          important details.
        </div>
      </>
    )
  }
)

export default MainChatChatInputGroup
