import DocViewer, {
  DocViewerRenderers,
  IDocument,
} from '@cyntler/react-doc-viewer'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import DocumentViewerHeader, { LoadingComponent } from './DocumentViewerHeader'
import DocumentViewerNoRender from './DocumentViewerNoRender'
import PDFViewer from './PDFViewer'
import { setFetchedFile } from '../../../actions/fileActions'
import { previewFile } from '../../../apis/filesApi'
import { useStore } from '../../../helpers/useStore'
import { useEnterpriseAttachment } from '../../../hooks/useEnterpriseAttachments'
import { useScreenSize } from '../../../hooks/useScreenSize'

const DocumentViewer = observer(() => {
  const store = useStore()
  const { screenWidth } = useScreenSize()

  const docViewerKey = `${screenWidth}-${Date.now()}`
  const [isDocumentPreviewLoading, setIsDocumentPreviewLoading] = useState(true)
  const [docs, setDocs] = useState<IDocument[]>([])

  const { data: file, isLoading: isDocumentFetching } =
    useEnterpriseAttachment(store)
  setFetchedFile(store, file)

  useEffect(() => {
    setIsDocumentPreviewLoading(true)
    if (file) {
      previewFile(store, file.id).then((data) => {
        const fileType = 'content_type' in file ? file.content_type : 'html'
        setDocs([{ id: file.id, uri: data, fileType }] as any)
        setIsDocumentPreviewLoading(false)
      })
    }
  }, [file])

  if (isDocumentPreviewLoading || isDocumentFetching) {
    return LoadingComponent
  }

  return (
    <div className="w-full bg-bgCol-neutral-secondary">
      <div className="p-4 overflow-y-auto">
        <DocViewer
          // Bug from DocViewer : If PDF content is unchanged, it shows blank after the first render. so we do force re-render with a unique key when component was called.
          key={docViewerKey}
          className="custom-document-viewer w-full rounded-lg"
          pluginRenderers={[PDFViewer, ...DocViewerRenderers]}
          documents={docs}
          config={{
            header: {
              disableFileName: true,
              retainURLParams: false,
              overrideComponent: DocumentViewerHeader,
            },
            loadingRenderer: {
              overrideComponent: () => LoadingComponent,
            },
            noRenderer: {
              overrideComponent: DocumentViewerNoRender as any,
            },
            pdfVerticalScrollByDefault: true,
          }}
        />
      </div>
    </div>
  )
})

export default DocumentViewer
