import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from '@material-tailwind/react'
import { useDebounce } from '@uidotdev/usehooks'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import FolderList from './FolderList'
import { Folder } from '../../../helpers/Store'
import { useStore } from '../../../helpers/useStore'
import useFolders from '../../../hooks/useFolders'
import CloseIcon from '../../assets/CloseIcon'
import Loading from '../../assets/Loading'

interface MoveToFolderModalProps {
  isOpen: boolean
  name: string
  handleOpen: () => void
  handleSave: (id: string) => void
}

const MoveToFolderModal = observer(
  ({ name, isOpen, handleOpen, handleSave }: MoveToFolderModalProps) => {
    const store = useStore()

    const [warningMessage, setWarningMessage] = useState('')
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedFolder, setSelectedFolder] = useState<Folder | undefined>(
      undefined
    )

    const debouncedSearchTerm = useDebounce(searchTerm, 500)
    const { data: folders, isLoading } = useFolders(store, debouncedSearchTerm)

    useEffect(() => {
      setWarningMessage('')
    }, [selectedFolder])

    const handleMove = () => {
      if (!selectedFolder) {
        setWarningMessage('Please select a folder')
        return
      }

      handleSave(selectedFolder.id)
      handleOpen()
    }

    return (
      <Dialog open={isOpen} handler={handleOpen} className="rounded-lg">
        <DialogHeader className="justify-between items-center p-6 border-b border-brd-default-defaul">
          <span className="font-semibold text-lg">Move {name} to ...</span>
          <button
            onClick={handleOpen}
            className="absolute top-6 right-6 bg-transparent text-black"
          >
            <CloseIcon />
          </button>
        </DialogHeader>
        <DialogBody className="px-6 py-4 justify-center items-center">
          <div className="pb-4">
            <input
              className="w-full p-2 h-10 border rounded shadow-sm focus:outline-none text-sm font-normal"
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
            />
          </div>
          {isLoading ? (
            <div className="w-full h-full flex items-center justify-center">
              <Loading />
            </div>
          ) : folders && folders.length > 0 ? (
            <FolderList
              folders={folders}
              selectedFolder={selectedFolder}
              onClick={(folder) => {
                setSelectedFolder(folder)
              }}
            />
          ) : (
            <span>Folder Not Found</span>
          )}
        </DialogBody>
        <DialogFooter className="px-6 py-4 flex flex-col items-end">
          {warningMessage && <span>{warningMessage}</span>}

          <div className="flex justify-end items-center space-x-4">
            <Button
              className="bg-bgCol-neutral-default text-sm font-medium text-black px-4 py-2 rounded"
              onClick={handleOpen}
            >
              Cancel
            </Button>
            <Button
              className="bg-bgCol-brand-default text-sm font-medium text-white px-4 py-2 rounded"
              onClick={handleMove}
            >
              Move
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    )
  }
)

export default MoveToFolderModal
