import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { FILES_AND_FOLDERS } from './queries'
import { getIsStreaming } from '../actions/streamingStateActions'
import { fetchFilesAndFolders } from '../apis/filesApi'
import Store, { Folder } from '../helpers/Store'

const useFilesAndFolders = (store: Store): UseQueryResult<Folder> => {
  const queryClient = useQueryClient()
  const { id, folderId } = useParams()
  const isStreaming = getIsStreaming(store, id)

  // reload folder data when new response is loaded because the referenced cases and citations might be updated
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [FILES_AND_FOLDERS, store.selectedMatter?.id],
    })
  }, [isStreaming])

  const sortParamsString = JSON.stringify([
    store.documentSortParams.get('sortBy'),
    store.documentSortParams.get('sortOrder'),
  ])

  const paginationParamsString = JSON.stringify([
    store.documentPagination.page,
    store.documentPagination.perPage,
  ])

  return useQuery<Folder>({
    queryKey: [
      FILES_AND_FOLDERS,
      id,
      folderId,
      sortParamsString,
      paginationParamsString,
    ],
    queryFn: () => fetchFilesAndFolders(store, id, folderId),
    enabled: Boolean(id),
    staleTime: store.computed.uploadInProgress ? 0 : Infinity,
  })
}

export default useFilesAndFolders
