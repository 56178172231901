import React from 'react'

import { IInstantMemo } from '../../types/interfaces'

function memoJurisdictionInfo(instantMemo: IInstantMemo) {
  switch (instantMemo.country_code) {
    case 'CA':
      return (
        <p>
          <strong className="font-medium">Jurisdiction: </strong>
          {instantMemo.province_territory}
        </p>
      )
    case 'US':
      return (
        <>
          <p>
            <strong className="font-medium">Jurisdiction: </strong>
            {instantMemo.us_jurisdiction}
          </p>
          <p>
            <strong className="font-medium">
              {instantMemo.us_jurisdiction === 'Federal'
                ? 'Federal: '
                : 'State: '}
            </strong>
            {instantMemo.province_territory}
          </p>
        </>
      )
    default:
      return null
  }
}

const MemoViewHeader = (props: { instantMemo: IInstantMemo }) => {
  const instantMemo: IInstantMemo = props.instantMemo

  if (instantMemo.memo_version === '0.0.9') {
    return null
  }

  return (
    <div className="text-base text-txt-default-default">
      <p>
        <strong className="font-medium">Memo To: </strong> {instantMemo.memo_to}
      </p>
      {memoJurisdictionInfo(instantMemo)}
      <p>
        <strong className="font-medium">Date: </strong> {instantMemo.date}
      </p>
    </div>
  )
}

export default MemoViewHeader
