import { IconButton } from '@material-tailwind/react'
import React from 'react'

import ChatHeader from './ChatHeader'
import { setShowSidebar } from '../../actions/showLayoutActions'
import { useStore } from '../../helpers/useStore'
import MenuIcon from '../assets/MenuIcon'

interface MainChatHeaderLeftButtonGroupProps {
  handleSettingClick: (value: boolean) => void
  containerWidth: number
}
const MainChatHeaderLeftButtonGroup = ({
  handleSettingClick,
  containerWidth,
}: MainChatHeaderLeftButtonGroupProps) => {
  const store = useStore()

  return (
    <div className="flex items-center gap-4">
      {!store.showLayouts.showSidebar ? (
        <div>
          <IconButton
            className="bg-bgCol-default-default w-5 h-5"
            onClick={() => {
              setShowSidebar(store, true)
            }}
          >
            <MenuIcon />
          </IconButton>
        </div>
      ) : null}
      {store.selectedMatter ? (
        <ChatHeader
          matter={store.selectedMatter}
          handleSettingPopup={handleSettingClick}
          titleStyle={{
            maxWidth: containerWidth >= 400 ? containerWidth - 250 : 150,
          }}
        />
      ) : (
        <p className="text-txt-default-default font-medium text-lg">
          New matter
        </p>
      )}
    </div>
  )
}

export default MainChatHeaderLeftButtonGroup
