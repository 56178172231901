import { AxiosError } from 'axios'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import PlaneIcon from './assets/PlaneIcon'
import SpinnerIcon from './assets/SpinnerIcon'
import UploadIcon from './assets/UploadIcon'
import IconButton from './IconButton'
import addFlash from '../actions/AddFlash'
import { submitMessage } from '../actions/chatInputActions'
import { getInputValue, setInputValue } from '../actions/InputValueActions'
import { setNewMatter } from '../actions/matterActions'
import { setShowDocumentManagers } from '../actions/showLayoutActions'
import { getIsStreaming } from '../actions/streamingStateActions'
import { createMatter } from '../apis/mattersApi'
import {
  storeMessageObject,
  getMessageObject,
  removeStoredMessage,
} from '../helpers/LocalStorageUtil'
import Store, { Matter } from '../helpers/Store'
import { useStore } from '../helpers/useStore'

const ChatInput = observer(() => {
  const store = useStore()
  const navigate = useNavigate()
  const inputRef = useRef<HTMLTextAreaElement | null>(null)
  const DEFAULT_TEXTAREA_HEIGHT = 4
  const matterId = store.selectedMatter?.id

  const sendMessageDisabled =
    getInputValue(store).length === 0 || getIsStreaming(store, matterId)

  useEffect(() => {
    runInAction(() => {
      store.inputRef = inputRef.current
      store.inputRef?.focus()
    })

    const savedInput = getInputWithExpiration(matterId || 'newMatter')

    if (savedInput) {
      setInputValue(store, savedInput)
    }
  }, [store, matterId])

  useEffect(() => {
    const textarea = inputRef.current
    if (!textarea) {
      return () => {}
    }

    adjustHeight()

    textarea.addEventListener('input', adjustHeight)

    return () => {
      textarea.removeEventListener('input', adjustHeight)
    }
  }, [])

  const adjustHeight = () => {
    const textarea = inputRef.current
    if (!textarea) {
      return
    }

    textarea.style.height = 'auto'
    textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`
  }

  const resetHeight = () => {
    const textarea = inputRef.current
    if (!textarea) {
      return
    }

    textarea.style.height = `${DEFAULT_TEXTAREA_HEIGHT * 16}px`
  }

  // eslint-disable-next-line consistent-return
  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      if (!sendMessageDisabled && !getIsStreaming(store, matterId)) {
        handleSendMessage()
      } else {
        return false
      }
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(store, e.target.value)
    saveInputWithExpiration(store, 6)
  }

  const handleSendMessage = async () => {
    try {
      if (!matterId) {
        setNewMatter(store, await createMatter(store))
        navigate(`/chat/${(store.selectedMatter as Matter).id}`)
      }

      submitMessage(store)

      resetHeight()
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if ((error as AxiosError)?.response?.status === 401) {
        addFlash(
          store,
          'error',
          'You are not authorized to perform this action.'
        )
      }
    }
  }

  return (
    <div
      className={`bg-white border rounded-lg shadow-md focus-within:border-brd-brand-default transition-all duration-400 ease-in-out`}
    >
      <textarea
        placeholder={
          getIsStreaming(store, matterId) ? '' : 'Start typing here...'
        }
        className={`data-hj-allow p-4 w-full rounded-t-lg h-[4rem] resize-none overflow-y-auto text-base md:text-sm font-normal h-[4rem]`}
        value={getInputValue(store)}
        onKeyPress={handleKeyPress}
        onChange={handleInputChange}
        style={{
          border: 'none',
          outline: 'none',
          transition: 'height 0.2s ease',
        }}
        ref={inputRef}
        onFocus={() => (store.isInputFocused = true)}
        onBlur={() => (store.isInputFocused = false)}
        disabled={getIsStreaming(store, matterId)}
      />

      <div className="bg-bgCol-default-tertiary rounded-b-lg">
        <div className="px-4 py-2 flex items-center justify-between rounded-b-lg">
          <div className="flex space-x-2">
            <IconButton
              icon={UploadIcon}
              onClick={() => {
                setShowDocumentManagers(store, true)
              }}
              id="attachFilesButton"
            />
          </div>
          {sendButton({
            store,
            matterId,
            sendMessageDisabled,
            handleSendMessage,
          })}
        </div>
      </div>
    </div>
  )
})

const saveInputWithExpiration = (store: Store, expirationInHours: number) => {
  if (getInputValue(store).length === 0) {
    removeStoredMessage(store.selectedMatter?.id || 'newMatter')
    return
  }

  const expirationTime =
    new Date().getTime() + expirationInHours * 60 * 60 * 1000
  const data = {
    value: getInputValue(store),
    expirationTime: expirationTime,
  }
  storeMessageObject(store.selectedMatter?.id || 'newMatter', data)
}

const getInputWithExpiration = (key: string) => {
  const data = getMessageObject(key)

  if (!data) {
    return null
  }

  const currentTime = new Date().getTime()
  if (currentTime > data.expirationTime) {
    removeStoredMessage(key)
    return null
  }
  return data.value
}

interface sendButtonArguments {
  store: Store
  matterId?: string
  sendMessageDisabled: boolean
  handleSendMessage: () => void
}

const sendButton = ({
  store,
  matterId,
  sendMessageDisabled,
  handleSendMessage,
}: sendButtonArguments) => {
  if (matterId && getIsStreaming(store, matterId)) {
    return <SpinnerSendButton />
  }

  if (sendMessageDisabled) {
    return <DisabledSendButton />
  }

  return <EnabledSendButton handleSendMessage={handleSendMessage} />
}

const DisabledSendButton = () => (
  <IconButton
    icon={PlaneIcon}
    className="cursor-not-allowed"
    id="sendButton"
    stroke="#CECECE"
  />
)
const SpinnerSendButton = () => (
  <IconButton
    icon={SpinnerIcon}
    className="bg-icon-brand-default"
    id="sendButton"
    stroke="#ffffff"
  />
)

interface enabledSendButtonArguments {
  handleSendMessage: () => void
}

const EnabledSendButton = ({
  handleSendMessage,
}: enabledSendButtonArguments) => (
  <IconButton
    icon={PlaneIcon}
    onClick={handleSendMessage}
    className="bg-icon-brand-default"
    id="sendButton"
  />
)

EnabledSendButton.propTypes = {
  handleSendMessage: PropTypes.func.isRequired,
}

export default ChatInput
