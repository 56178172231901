import { Button } from '@material-tailwind/react'
import React from 'react'

import {
  isMdSize,
  setShowDocumentManagers,
} from '../../actions/showLayoutActions'
import { useStore } from '../../helpers/useStore'
import { useScreenSize } from '../../hooks/useScreenSize'
import ArrowLeftToLine from '../assets/ArrowLeftToLine'
import ArrowRightToLine from '../assets/ArrowRightToLine'
import UploadIcon from '../assets/UploadIcon'

const MainChatHeaderRightButtonGroup = () => {
  const store = useStore()
  const { screenWidth } = useScreenSize()

  return (
    <>
      <Button
        variant="filled"
        className="bg-bgCol-brand-secondary py-3 px-2 md:px-3 flex justify-center items-center rounded h-8 md:h-10"
        onClick={() => {
          setShowDocumentManagers(store, !store.showLayouts.showDocumentManager)
        }}
      >
        {store.showLayouts.showDocumentManager ? (
          <ArrowRightToLine strokeClass="stroke-txt-brand-default" />
        ) : (
          <ArrowLeftToLine strokeClass="stroke-txt-brand-default" />
        )}
        {isMdSize(screenWidth) ? (
          <span className="pl-2 text-sm font-medium text-txt-brand-default">
            {store.showLayouts.showDocumentManager ? 'Hide' : 'Show'} Documents
          </span>
        ) : null}
      </Button>
      {isMdSize(screenWidth) && !store.showLayouts.showDocumentManager ? (
        <Button
          variant="filled"
          className="bg-bgCol-brand-default py-3 px-3 flex justify-center items-center rounded h-8 md:h-10"
          onClick={() => {
            setShowDocumentManagers(store, true)
          }}
        >
          <UploadIcon strokeClass="stroke-white" />
          <span className="pl-2 text-sm font-normal text-txt-white">
            Upload
          </span>
        </Button>
      ) : null}
    </>
  )
}

export default MainChatHeaderRightButtonGroup
