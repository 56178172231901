import { action } from 'mobx'
import PropTypes from 'prop-types'
import React from 'react'

import DownArrowIcon from '../assets/DownArrowIcon'

const LatestMessageButton = ({ store, scrollToBottom }) => {
  return (
    <div
      onClick={action(() => {
        store.showLatestMessagesButton = false
        scrollToBottom()
      })}
      className="w-max px-2 pr-3 py-1 bg-bgCol-brand-default text-txt-utilities-default rounded-full flex items-center cursor-pointer shadow-lg z-50"
    >
      <DownArrowIcon stroke="stroke-icon-utilities-default" />
      <span className="text-xs font-medium leading-4 ml-1">
        Latest messages
      </span>
    </div>
  )
}

LatestMessageButton.propTypes = {
  store: PropTypes.shape({
    showLatestMessagesButton: PropTypes.bool,
  }),
  scrollToBottom: PropTypes.func.isRequired,
}

export default LatestMessageButton
