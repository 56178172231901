import React from 'react'

import { SUPPORTED_EXTENSIONS } from '../../helpers/constants'
import { MAX_FILE_SIZE } from '../../hooks/useUploadToFolderWithConfirmation'
import XIcon from '../assets/XIcon'
import Accordion from '../shared/Accordion'
import Modal from '../shared/Modal'

interface ConfirmUploadModalProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  filesDetected: number
  filesWillUpload: number
  filesUnsupported: number
}

const ConfirmUploadModal: React.FC<ConfirmUploadModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  filesDetected,
  filesWillUpload,
  filesUnsupported,
}) => {
  const hasUnsupported = filesUnsupported > 0

  return (
    <Modal isOpen={isOpen} onClose={onClose} classes="max-w-[31.25rem]">
      <div>
        {/* Header */}
        <div className="flex justify-between items-center pb-4 mb-4 p-6 sm:p-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold leading-6 text-txt-default-default">
            Confirm your upload
          </h2>
          <button onClick={onClose}>
            <XIcon classNames="w-[22px] h-[22px]" />
          </button>
        </div>

        {/* Body */}
        <div className="text-base font-normal space-y-4 pl-6 pr-6 sm:pl-4 sm:pr-4">
          {hasUnsupported ? (
            <>
              <p>
                Alexi detected{' '}
                <span className="font-medium">{filesDetected} documents</span>.
              </p>

              <p>
                <span className="font-medium">{filesWillUpload} documents</span>{' '}
                will be uploaded.
              </p>

              <p>
                <span className="font-medium">
                  {filesUnsupported} documents
                </span>{' '}
                cannot be uploaded because their file types are unsupported or
                their size exceeds the {MAX_FILE_SIZE / (1024 * 1024)} MB limit.
              </p>

              {/* Accordion for "What file types are supported?" */}
              <Accordion
                title="What file types are supported?"
                titleClasses="font-medium leading-6 text-txt-brand-default"
                open={false}
                body={
                  <div>
                    <p className="text-base">
                      Supported file types:{' '}
                      <span>{SUPPORTED_EXTENSIONS.join(', ')}</span>
                    </p>
                  </div>
                }
              />

              <p>
                To continue, click <span className="font-semibold">Upload</span>
                .
              </p>
            </>
          ) : (
            <p>
              Alexi detected{' '}
              <span className="font-medium">{filesDetected} documents</span>. To
              continue, click <span className="font-semibold">Upload</span>.
            </p>
          )}
        </div>

        <div className="flex justify-end space-x-4 mt-4 p-6 sm:p-4">
          <button
            type="button"
            onClick={onClose}
            className="py-[0.625rem] px-6 font-medium text-sm bg-bgCol-neutral-default text-txt-neutral-default rounded-md"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={onConfirm}
            className="py-[0.625rem] px-6 font-medium text-sm bg-bgCol-brand-default text-white rounded-md"
          >
            Upload
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmUploadModal
