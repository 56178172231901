import React from 'react'

interface NameEditorProps {
  name: string
  handleChange: (value: string) => void
  submitChange: () => void
}
const NameEditor = ({ name, handleChange, submitChange }: NameEditorProps) => {
  return (
    <input
      type="text"
      multiple
      value={name}
      onChange={(e) => {
        e.stopPropagation()
        e.preventDefault()
        handleChange(e.target.value)
      }}
      onBlur={submitChange}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          submitChange()
        }
      }}
      autoFocus
      className="border-2 border-brd-brand-default focus:outline-none focus-visible:border-brd-brand-default rounded py-2 px-3 w-full"
    />
  )
}

export default NameEditor
