import React from 'react'

const PdfDocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.3333 18.8333H5.3334C4.80297 18.8333 4.0684 18.8485 3.69333 18.4734C3.31826 18.0983 3.33342 17.3638 3.33342 16.8333V2.83347C3.33342 2.30304 3.31826 1.56847 3.69333 1.1934C4.0684 0.818333 4.80297 0.833489 5.3334 0.833489H12.3333L17.3333 5.83344V16.8333C17.3333 17.3638 17.3484 18.0983 16.9734 18.4734C16.5983 18.8485 15.8637 18.8333 15.3333 18.8333Z"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3346 0.833405V4.83337C12.3346 5.09858 12.44 5.35293 12.6275 5.54047C12.815 5.728 13.0694 5.83336 13.3346 5.83336H17.3346"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49845 14.9388C9.19467 14.4577 9.66009 13.7225 10.0024 12.8219C10.3486 11.9251 10.7179 11.1554 10.5563 10.2702C10.5332 10.1316 10.3371 9.9892 10.2371 10.0007C9.84473 10.0238 9.87165 10.4664 9.91012 10.732C10.0024 11.3902 10.5102 12.1561 11.0025 12.872C11.491 13.5917 11.8949 13.7033 12.4565 13.7418C12.6488 13.7533 12.9373 13.6879 12.9835 13.5186C13.2796 12.4409 9.51393 13.3107 8.25997 14.3191C8.10611 14.4461 7.92917 14.704 8.02918 14.881C8.10611 15.0196 8.36382 15.035 8.5023 14.9388H8.49845Z"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PdfDocumentIcon
