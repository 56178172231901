const ArrowUpIcon = ({
  stroke = 'stroke-icon-default-default',
  fill = 'fill-icon-default-default',
  className = '',
}: {
  fill?: string
  stroke?: string
  className?: string
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} transition-colors duration-200 ease-in-out`}
  >
    <path
      d="M3.33337 8.00004L8.00004 3.33337M8.00004 3.33337L12.6667 8.00004M8.00004 3.33337V12.6667"
      stroke={stroke}
      fill={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowUpIcon
