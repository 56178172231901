import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'

import AttachmentItemGroup from './AttachmentItemGroup'
import AttachmentItemSingle from './AttachmentItemSingle'
import EmptyActivitiesPlaceholder from './EmptyActivitiesPlaceholder'
import MemoItem from './MemoItem'
import SummaryDocItem from './SummaryDocItem'
import { setActivities } from '../../actions/activityActions'
import timezonedDatetime from '../../helpers/DateTimeUtils'
import { useStore } from '../../helpers/useStore'
import Loading from '../assets/Loading'

function renderActivityItem(activityOrGroup) {
  switch (activityOrGroup.activity_item_type) {
    case 'AttachmentActivityGroup':
      return <AttachmentItemGroup activities={activityOrGroup.items} />
    case 'MemoActivityItem':
      return <MemoItem activity={activityOrGroup} />
    case 'AttachmentActivityItem':
      return <AttachmentItemSingle activity={activityOrGroup} />
    case 'SummaryDocActivityItem':
      return <SummaryDocItem activity={activityOrGroup} />
    default:
      return null
  }
}

const ActivitiesColumn = observer(() => {
  const store = useStore()

  const [activitiesLoading, setActivitiesLoading] = React.useState(true)

  useEffect(() => {
    setActivitiesLoading(true)

    setActivities(store).then(() => {
      setActivitiesLoading(false)
    })
    //   eslint-disable-next-line react-hooks/exhaustive-deps -- CORRECT DEPS
  }, [store.selectedMatter?.id])

  if (activitiesLoading) {
    return <Loading />
  }

  return (
    <div className="pb-4 pr-2">
      {Object.keys(store.computed.groupedActivities).length === 0 ? (
        <EmptyActivitiesPlaceholder />
      ) : (
        // Grouped by date
        Object.keys(store.computed.groupedActivities).map((date) => (
          <div key={date} className="mb-8">
            {/* Date */}
            <p className="text-sm mx-7 font-medium text-txt-default-default leading-tight mb-2">
              {timezonedDatetime(date, store, 'fromISODateFormat')}
            </p>
            {store.computed.groupedActivities[date].map(
              (activityOrGroup, index) => {
                return (
                  <div key={index}>{renderActivityItem(activityOrGroup)}</div>
                )
              }
            )}
          </div>
        ))
      )}
    </div>
  )
})

export default ActivitiesColumn
