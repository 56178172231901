import React from 'react'

import { MaxchatFile } from '../../actions/upload_batch/UploadRequest'
import CircularXIcon from '../assets/CircularXIcon'
import XIcon from '../assets/XIcon'

interface FailedToUploadFilesModalProps {
  failedDocuments: MaxchatFile[]
  onCancel: () => void
}

const FailedToUploadFilesModal: React.FC<FailedToUploadFilesModalProps> = ({
  failedDocuments,
  onCancel,
}) => {
  const DUPLICATE_FILE_ERROR_MSG = 'File already exists.'
  const circularIconColor = (file: MaxchatFile) => {
    return file.failureReason === DUPLICATE_FILE_ERROR_MSG
      ? '#D87E03'
      : '#C00D1C'
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4 sm:p-8 min-w-96">
      <div className="bg-white py-6 rounded-lg shadow-lg w-[500px] max-h-[80vh] overflow-y-auto">
        <div className="flex px-6 justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-left text-txt-default-default">
            {failedDocuments.length} documents failed to upload
          </h2>
          <button onClick={onCancel} className="m-2 w-6 h-6">
            <XIcon classNames="w-[22px] h-[22px]" />
          </button>
        </div>

        <div className="px-6 space-y-3 overflow-y-auto max-h-[250px]">
          {failedDocuments.map((file, index) => (
            <div
              key={index}
              className="flex items-start space-x-3 border-b pb-2 last:border-b-0"
            >
              <span>
                <CircularXIcon stroke={circularIconColor(file)} />
              </span>
              <div>
                <p className="text-sm font-medium text-txt-default-default">
                  {file.name}
                </p>
                <p className="text-xs text-txt-neutral-default">
                  {file.failureReason || 'Upload failed.'}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end space-x-4 px-6 mt-4">
          <button
            onClick={onCancel}
            className="bg-txt-brand-default text-white px-4 py-2 rounded-lg"
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  )
}

export default FailedToUploadFilesModal
