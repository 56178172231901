import React from 'react'

import AlexiLogo from '../assets/alexiLogo'

const ChatMessageSkeleton = () => {
  return (
    <div className="flex px-6 pt-3 justify-start items-center ">
      <div className="flex-none w-10 h-10 mr-3">
        <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
          <AlexiLogo
            fill="fill-icon-utilities-default"
            width={16}
            height={16}
          />
        </div>
      </div>
      <div className="flex-grow p-4 bg-slate-50 order-2 rounded-r-lg">
        <div className="h-3 rounded w-3/4 mb-2 bg-pulse-gradient bg-200 animate-pulse-left-to-right"></div>
        <div className="h-3 rounded w-1/2 bg-pulse-gradient bg-200 animate-pulse-left-to-right"></div>
      </div>
    </div>
  )
}

export default ChatMessageSkeleton
