import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import React, { useState } from 'react'

import ThreeDotsIcon from '../../assets/ThreeDotsIcon'

interface FolderActionMenuProps {
  onEditName: () => void
  onDownload: () => void
  onMove: () => void
  onDelete: () => void
}

const FolderActionMenu = ({
  onEditName,
  onDelete,
  onMove,
}: FolderActionMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">
      <MenuHandler>
        <Button className="bg-transparent p-0 h-8 w-full flex justify-center items-center">
          <ThreeDotsIcon variants="secondary" />
        </Button>
      </MenuHandler>
      <MenuList className="p-0 min-w-max text-black text-sm font-normal border-0 z-[9999]">
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onEditName()
          }}
        >
          Edit the name
        </MenuItem>
        {/*TODO: enable entire folder download*/}
        {/*<MenuItem*/}
        {/*  onClick={(e) => {*/}
        {/*    e.stopPropagation()*/}
        {/*    onDownload()*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Download*/}
        {/*</MenuItem>*/}
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            onMove()
          }}
        >
          Move to another folder
        </MenuItem>
        <MenuItem
          className="text-txt-danger-default hover:text-txt-danger-default focus:text-txt-danger-default"
          onClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default FolderActionMenu
