import PropTypes from 'prop-types'
import React from 'react'

const FolderIcon = ({ stroke, backgroundColor, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M17.2 17.3C17.6774 17.3 18.1352 17.1104 18.4728 16.7728C18.8104 16.4352 19 15.9774 19 15.5V6.5C19 6.02261 18.8104 5.56477 18.4728 5.22721C18.1352 4.88964 17.6774 4.7 17.2 4.7H10.09C9.78896 4.70295 9.49199 4.63035 9.22627 4.48884C8.96055 4.34733 8.73456 4.14144 8.569 3.89L7.84 2.81C7.6761 2.56112 7.45298 2.35683 7.19065 2.21546C6.92832 2.07408 6.635 2.00005 6.337 2H2.8C2.32261 2 1.86477 2.18964 1.52721 2.52721C1.18964 2.86477 1 3.32261 1 3.8V15.5C1 15.9774 1.18964 16.4352 1.52721 16.7728C1.86477 17.1104 2.32261 17.3 2.8 17.3H17.2Z"
      strokeWidth="1.5"
      stroke={stroke}
      fill={backgroundColor}
    />
  </svg>
)

FolderIcon.propTypes = {
  stroke: PropTypes.string,
  backgroundColor: PropTypes.string,
}

export default FolderIcon
