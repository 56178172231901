import { Card, List, ListItem, Typography } from '@material-tailwind/react'
import { useNavigate } from 'react-router-dom'

const AdminSidebar = () => {
  const navigate = useNavigate()

  return (
    <Card className="h-full w-full max-w-72 p-2 flex flex-col">
      <div className="p-4 pb-0">
        <Typography variant="h5" color="blue-gray">
          Admin Dashboard
        </Typography>
      </div>
      <hr className="my-3" />

      <List className="flex flex-col flex-grow p-0">
        <ListItem className="" onClick={() => navigate('/admin')}>
          <span className="text-sm">Home</span>
        </ListItem>
        <ListItem className="" onClick={() => navigate('/admin/matters')}>
          <span className="text-sm">Matters</span>
        </ListItem>
        <div className="flex-grow"></div>
        <hr className="my-2" />
        <ListItem className="" onClick={() => navigate('/')}>
          <span className="text-sm">Back to App</span>
        </ListItem>
      </List>
    </Card>
  )
}

export default AdminSidebar
