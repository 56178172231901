import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import AlexiDocumentTableRow from './AlexiDocumentTableRow'
import AttachmentTableRow from './AttachmentTableRow'
import EmptyFolderPlaceholder from './emptyFolderPlaceholders/EmptyFolderPlaceholder'
import EmptyReferencedCasesPlaceholder from './emptyFolderPlaceholders/EmptyReferencedCasesPlaceholder'
import EmptyReferencedWebSourcesPlaceholder from './emptyFolderPlaceholders/EmptyReferencedWebSourcesPlaceholder'
import FilesAndFoldersSkeleton from './FilesAndFoldersSkeleton'
import FolderTableRow from './FolderTableRow'
import ReadOnlyDocumentHeader from './ReadOnlyDocumentHeader'
import UploadButton from './UploadButton'
import { UploadFailuresNotice } from './UploadFailuresNotice'
import UploadSection from './UploadSection'
import { setFetchedFilesAndFolders } from '../../actions/fileActions'
import { FOLDER_TYPE } from '../../helpers/constants'
import {
  isAlexiDocumentFolder,
  isCurrentFolderUploadEnabled,
} from '../../helpers/folderUtils'
import { AlexiDocument, EnterpriseAttachment } from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import useFilesAndFolders from '../../hooks/useFilesAndFolders'
import TableSortableHeader from '../shared/TableSortableHeader'

interface FilesAndFoldersProps {
  onFilesSelected: (files: File[]) => void
}

const FilesAndFolders = observer(
  ({ onFilesSelected }: FilesAndFoldersProps) => {
    const store = useStore()
    const navigate = useNavigate()
    const { id } = useParams()

    const { data, isLoading } = useFilesAndFolders(store)

    useEffect(() => {
      setFetchedFilesAndFolders(store, data)
      //   eslint-disable-next-line react-hooks/exhaustive-deps -- this is correct
    }, [data])

    const currentFolderUploadEnabled = isCurrentFolderUploadEnabled({
      store,
    })

    const hasDocumentsOrFolders =
      (store.currentFolder.documents?.length ?? 0) > 0 ||
      (store.currentFolder.sub_folders?.length ?? 0) > 0 ||
      (store.currentFolder.alexi_documents?.length ?? 0) > 0

    const uploadDisabledFolder =
      !currentFolderUploadEnabled && !hasDocumentsOrFolders

    const matterIsNotALR = store.selectedMatter?.is_alr === false

    const handleSort = (newSortKey: string) => {
      const newSortParams = new Map([['sortBy', newSortKey]])

      const isAscending = store.documentSortParams.get('sortOrder') === 'asc'
      if (newSortKey === store.documentSortParams.get('sortBy')) {
        newSortParams.set('sortOrder', isAscending ? 'desc' : 'asc')
      } else {
        newSortParams.set('sortOrder', 'asc')
      }
      runInAction(() => {
        store.documentSortParams = newSortParams
      })
    }

    const handleFolderClick = (subFolder: { id: string }) => {
      navigate(`/chat/${id}/folder/${subFolder.id}`)
    }

    // Navigate to the clicked folder
    const handleFileClick = (file: EnterpriseAttachment) => {
      navigate(`/chat/${id}/document/${file.id}`)
    }

    if (isLoading) {
      return <FilesAndFoldersSkeleton />
    }

    if (!id) {
      return <UploadSection onFilesSelected={onFilesSelected} />
    }

    if (
      uploadDisabledFolder &&
      store.currentFolder.folder_type === FOLDER_TYPE.referenced_cases &&
      store.currentFolder.alexi_documents?.length === 0
    ) {
      return <EmptyReferencedCasesPlaceholder />
    }

    if (
      uploadDisabledFolder &&
      store.currentFolder.folder_type === FOLDER_TYPE.referenced_web_sources &&
      store.currentFolder.alexi_documents?.length === 0
    ) {
      return <EmptyReferencedWebSourcesPlaceholder />
    }

    if (!hasDocumentsOrFolders) {
      return <EmptyFolderPlaceholder />
    }

    return (
      <div className="p-4 h-full w-full">
        <>
          <div className="pb-4">
            {matterIsNotALR ? (
              <ReadOnlyDocumentHeader />
            ) : (
              <div className="justify-self-end">
                <UploadButton onFilesSelected={onFilesSelected} />
              </div>
            )}
          </div>

          {/* Table Section */}
          <div className="w-full">
            <UploadFailuresNotice />
            <table className="w-full table-auto border-collapse divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    className="px-4 py-2 text-left text-sm font-normal text-gray-700"
                    colSpan={2}
                  >
                    <div className="flex items-center space-x-1">
                      <TableSortableHeader
                        columnName="Name"
                        columnKey="name"
                        sortBy={store.documentSortParams.get('sortBy') || ''}
                        sortOrder={
                          store.documentSortParams.get('sortOrder') || ''
                        }
                        handleSortButtonClick={handleSort}
                      />
                    </div>
                  </th>
                  {!isAlexiDocumentFolder(store.currentFolder.folder_type) && (
                    <th className="px-4 py-2 text-left text-sm font-normal text-gray-700">
                      <div className="flex items-center space-x-1">
                        <TableSortableHeader
                          columnName="Type"
                          columnKey="type"
                          sortBy={store.documentSortParams.get('sortBy') || ''}
                          sortOrder={
                            store.documentSortParams.get('sortOrder') || ''
                          }
                          handleSortButtonClick={handleSort}
                        />
                      </div>
                    </th>
                  )}
                  <th className="px-4 py-2 w-12"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {/* Subfolders */}
                {store.currentFolder.sub_folders?.map((subFolder) => (
                  <FolderTableRow
                    key={`table-folder-row-${subFolder.id}`}
                    folder={subFolder}
                    handleRowClick={() => handleFolderClick(subFolder)}
                  />
                ))}

                {/* Documents */}
                {store.currentFolder.documents?.map((doc) => (
                  <AttachmentTableRow
                    key={doc.id}
                    doc={doc}
                    onClick={() => handleFileClick(doc)}
                  />
                ))}

                {/*Alexi Documents*/}
                {store.currentFolder.alexi_documents?.map(
                  (doc: AlexiDocument) => (
                    <AlexiDocumentTableRow
                      key={doc.id}
                      doc={doc}
                      folderType={store.currentFolder.folder_type}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        </>
      </div>
    )
  }
)

export default FilesAndFolders
