import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'

import SuccessIcon from './assets/SuccessIcon'
import TrashIconDeleteMatter from './assets/TrashIconDeleteMatter'
import UndoIcon from './assets/UndoIcon'
import XIcon from './assets/XIcon'
import { useStore } from '../helpers/useStore'
import { useScreenSize } from '../hooks/useScreenSize'

const classes = {
  error: 'bg-red-50 border border-red-500 text-black-700',
  info: 'bg-blue-50 border border-blue-500 text-black-700',
  notice: 'bg-yellow-50 border border-yellow-500 text-black-700',
  success: 'bg-green-50 border border-green-700 text-black-700',
}

const progressClasses = {
  error: 'bg-red-500',
  info: 'bg-blue-500',
  notice: 'bg-yellow-500',
  success: 'bg-green-500',
}

const defaultIcons = {
  error: null,
  info: null,
  notice: null,
  success: <SuccessIcon />,
}

const FlashMessage = observer(({ flash }) => {
  const store = useStore()
  const { screenWidth } = useScreenSize()

  // if screen size is too small we don't display the matter title in delete message
  const isSmallScreenSize = screenWidth < 780

  const animationClass =
    Object.entries(flash.metadata ?? {}).length > 0 &&
    (flash.metadata ?? {}).subType === 'deleteMatter'
      ? 'animate-progress-15'
      : 'animate-progress'

  return (
    <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-[95vw] sm:max-w-[80vw] md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg">
      <div
        key={flash.id}
        className={`${classes[flash.type] || classes.success} p-4 mb-4 text-sm rounded-lg shadow-md transition-opacity duration-1000 ease-out relative flex items-center w-full`}
        role="alert"
      >
        <div className="grid grid-cols-[1fr,auto] items-start w-full">
          {flash.metadata &&
          Object.entries(flash.metadata).length > 0 &&
          flash.metadata.subType === 'deleteMatter' ? ( // Conditional rendering for deleted matter
            <div className="grid grid-cols-[auto,1fr,auto] items-start gap-4">
              <div className="flex items-start">
                <TrashIconDeleteMatter />
              </div>
              <div className="w-full sm:min-w-32">
                <span
                  style={{
                    overflowWrap: isSmallScreenSize ? 'normal' : 'break-word',
                    wordBreak: isSmallScreenSize ? 'normal' : 'break-word',
                  }}
                >
                  {isSmallScreenSize ? 'Matter deleted!' : flash.message}
                </span>
              </div>
              <div
                className="ml-2 text-primary cursor-pointer inline-flex items-start sm:justify-end"
                onClick={() => {
                  flash.metadata.onDeleteMatterClick(flash.metadata.matter)
                }}
              >
                <UndoIcon />
                <span className="px-2 text-txt-brand-default whitespace-nowrap">
                  Undo
                </span>
              </div>
            </div>
          ) : (
            <div className="flex items-center w-full">
              {defaultIcons[flash?.type]}
              <span className="flex-1 text-left break-words whitespace-normal">
                {flash.message}
              </span>

              <button
                className="pl-4 ml-4 border-l border-brd-default-default flex-shrink-0"
                onClick={() => {
                  runInAction(() => {
                    store.flashes = store.flashes.filter(
                      (f) => f.id !== flash.id
                    )
                  })
                }}
              >
                <XIcon />
              </button>
            </div>
          )}
        </div>

        <div
          className={`absolute bottom-0 left-0 h-1 ${progressClasses[flash.type]} ${animationClass}`}
        ></div>
      </div>
    </div>
  )
})

export default FlashMessage
