import React, { useEffect, useState } from 'react'

import SelectInput from './SelectInput'
import { fetchStates } from '../../apis/fetchStatesApi'
import { useStore } from '../../helpers/useStore'

interface JurisdictionStateSelectorProps {
  countryCode: 'CA' | 'US'
  initialJurisdiction?: string
  initialStateId?: string
  onJurisdictionChange: (jurisdiction: string) => void
  onStateChange: (state: string) => void
}

const JurisdictionStateSelector: React.FC<JurisdictionStateSelectorProps> = ({
  countryCode,
  initialJurisdiction,
  initialStateId,
  onJurisdictionChange,
  onStateChange,
}) => {
  const store = useStore()
  const [jurisdiction, setJurisdiction] = useState(initialJurisdiction || '')
  const [states, setStates] = useState<{ id: string; name: string }[]>([])
  const [state, setState] = useState(initialStateId || '')

  useEffect(() => {
    const loadStates = async () => {
      const fetchedStates = await fetchStates(store, countryCode, false)
      setStates(fetchedStates)
    }
    loadStates()
  }, [countryCode])

  const handleJurisdictionChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedJurisdiction = e.target.value
    setJurisdiction(selectedJurisdiction)
    onJurisdictionChange(selectedJurisdiction)
  }

  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedState = e.target.value
    setState(selectedState)
    onStateChange(selectedState)
  }

  const defineStateLabel = () => {
    if (countryCode === 'CA') {
      return 'Jurisdiction'
    }
    return jurisdiction === 'Federal' ? 'Forum' : 'State'
  }

  return (
    <>
      {countryCode === 'US' ? (
        <div className="flex space-x-4">
          <div className="w-[218px]">
            <label className="block mb-2 text-sm">Jurisdiction</label>
            <SelectInput
              value={jurisdiction}
              onChange={handleJurisdictionChange}
              options={{ State: 'State', Federal: 'Federal' }}
              showPlaceholder={false}
            />
          </div>
          <div className="w-[218px]">
            <label className="block mb-2 text-sm">{defineStateLabel()}</label>
            <SelectInput
              value={state}
              onChange={handleStateChange}
              options={states.reduce<Record<string, string>>(
                (acc, stateOption) => {
                  acc[stateOption.id] = stateOption.name
                  return acc
                },
                {}
              )}
              showPlaceholder={false}
            />
          </div>
        </div>
      ) : (
        <>
          <label className="block mb-2 text-sm">{defineStateLabel()}</label>
          <SelectInput
            value={state}
            onChange={handleStateChange}
            options={states.reduce<Record<string, string>>(
              (acc, stateOption) => {
                acc[stateOption.id] = stateOption.name
                return acc
              },
              {}
            )}
            disabled={!states.length}
          />
        </>
      )}
    </>
  )
}

export default JurisdictionStateSelector
