import { useQuery } from '@tanstack/react-query'

import getAdminMatter from '../../apis/admin/adminMatterApi'
import Store from '../../helpers/Store'

interface useAdminMatterProps {
  store: Store
  matterId?: string
}

const useAdminMatter = ({ store, matterId }: useAdminMatterProps) => {
  return useQuery({
    queryKey: ['adminMatter', matterId],
    queryFn: () => getAdminMatter({ store: store, matterId: matterId }),
    enabled: matterId !== undefined,
    staleTime: Infinity,
  })
}

export default useAdminMatter
