import { action } from 'mobx'

import Store, { EnterpriseAttachment } from '../helpers/Store'

function statusString(enterpriseAttachment: EnterpriseAttachment) {
  // Parsing the date every time is a little inefficient, but I think it's much safer than trying to ensure we parse it at every place where we might deserialize this data.
  const startEpoch = new Date(
    enterpriseAttachment.processing_started_at
  ).getTime()
  const endEpoch =
    startEpoch + enterpriseAttachment.processing_duration_estimate * 1000
  const now = Date.now()

  if (now > endEpoch) {
    return 'Ready soon'
  } else if (now > endEpoch - 60_000) {
    return 'Ready in less than a minute'
  } else if (now > endEpoch - 120_000) {
    return 'Ready in about a minute'
  } else {
    const duration = Math.floor((endEpoch - now) / 60_000)
    return `${duration} min left.`
  }
}

const animateAttachmentTableRows = action((store: Store) => {
  // Visit each attachment in the current folder which is in progress, and set the
  if (!store.currentFolder.documents) {
    return
  }

  for (const attachment of store.currentFolder.documents) {
    // This is ultra messy
    if (!attachment.processing_status) {
      continue
    }

    const enterpriseAttachment = attachment as EnterpriseAttachment

    if (enterpriseAttachment.processing_status === 'STARTED') {
      enterpriseAttachment.loadingEstimateMessage =
        statusString(enterpriseAttachment)
    }
  }
})

const setupAttachmentTableRowAnimation = (store: Store) => {
  setInterval(() => animateAttachmentTableRows(store), 1000)
}

export { setupAttachmentTableRowAnimation, animateAttachmentTableRows }
