import axios, { AxiosError } from 'axios'
import CryptoJS from 'crypto-js'
import { makeAutoObservable, runInAction } from 'mobx'

import UploadBatch from './UploadBatch'
import { completeUpload, getPresignedUrlAndMetadata } from '../../apis/filesApi'
import { rollbarConfig } from '../../helpers/rollbarConfig'
import Store, { EnterpriseAttachment } from '../../helpers/Store'

type MaxchatFile = File & {
  relativePath: string
  failureReason: string
}

class UploadRequest {
  requestStatus:
    | 'initialized'
    | 'uploading'
    | 'processing'
    | 'completed'
    | 'failed' = 'initialized'
  file: MaxchatFile
  uploadBatch: UploadBatch
  store: Store
  enterpriseAttachmentId?: string

  constructor(_file: MaxchatFile, _uploadBatch: UploadBatch) {
    this.file = _file
    this.uploadBatch = _uploadBatch
    this.store = _uploadBatch.store

    makeAutoObservable(this)
  }

  async upload() {
    try {
      // TODO: preflight duplicate file check goes here
      this.requestStatus = 'uploading'
      const reader = new FileReader()
      reader.onload = this.withFileContent
      reader.readAsArrayBuffer(this.file)
    } catch (error) {
      this.handleUploadError(error as AxiosError)
    }
  }

  // NB: bound function for use with FileReader.onload
  public withFileContent = async (event: Event) => {
    try {
      if (!event.target) {
        throw new Error('Unable to read file for upload')
      }

      // Make EnterpriseAttachment, retrieve presigned URL
      const target = event.target as EventTarget & { result: ArrayBuffer }
      const wordArray = CryptoJS.lib.WordArray.create(target.result)
      // S3 requires the checksum base64 encoded for some reason
      const checksum = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64)

      const args = {
        filename: this.file.name,
        byte_size: this.file.size,
        relative_path: this.file.relativePath,
        checksum,
        content_type: this.file.type,
        store: this.store,
        matter_id: this.uploadBatch.matterId,
      }

      const enterpriseAttachmentData = await getPresignedUrlAndMetadata(args)

      this.addEnterpriseAttachmentToCurrentFolderStore({
        store: this.store,
        enterpriseAttachmentData,
      })

      this.enterpriseAttachmentId = enterpriseAttachmentData.id

      // Upload to S3
      await axios.put(enterpriseAttachmentData.direct_upload_url, this.file, {
        headers: {
          'Content-MD5': checksum,
          'Content-Type': this.file.type,
        },
      })

      // Notify platform and perform post-upload tasks
      completeUpload(
        this.store,
        enterpriseAttachmentData.id,
        this.uploadBatch.matterId
      )
      runInAction(() => {
        this.requestStatus = 'processing'
      })
      this.uploadBatch.uploadRequestUploaded()
    } catch (error) {
      this.handleUploadError(error as AxiosError)
    }
  }

  processingFinished() {
    this.requestStatus = 'completed'
    this.uploadBatch.uploadRequestProcessed()
  }

  processingFailed() {
    this.requestStatus = 'failed'
    this.file.failureReason = 'Upload failed'
    this.uploadBatch.uploadRequestProcessed()
  }

  handleUploadError(error: AxiosError) {
    console.error('Upload error:', error)
    this.requestStatus = 'failed'

    if (
      error.response?.data &&
      typeof error.response.data === 'object' &&
      'errors' in error.response.data
    ) {
      this.file.failureReason = error.response.data.errors as string
    } else {
      this.file.failureReason = 'Upload failed.'
    }
    rollbarConfig(this.store)?.error(error)
    this.uploadBatch.uploadRequestUploaded()
    this.uploadBatch.uploadRequestProcessed()
  }

  addEnterpriseAttachmentToCurrentFolderStore({
    store,
    enterpriseAttachmentData,
  }: {
    store: Store
    enterpriseAttachmentData: EnterpriseAttachment
  }) {
    if (
      store.currentFolder.documents &&
      store.currentFolder.id === enterpriseAttachmentData.folder_id
    ) {
      if (
        store.currentFolder.documents.length < store.documentPagination.perPage
      ) {
        store.currentFolder.documents.push(enterpriseAttachmentData)
      }

      store.documentPagination.totalCount += 1
      store.documentPagination.totalPages = Math.ceil(
        store.documentPagination.totalCount / store.documentPagination.perPage
      )
    }
  }
}

export type { MaxchatFile }
export default UploadRequest
