import React from 'react'

const PptDocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M15.3333 18.8333H5.33341C4.80298 18.8333 4.06841 18.8485 3.69334 18.4734C3.31827 18.0983 3.33343 17.3638 3.33343 16.8333V2.83347C3.33343 2.30304 3.31827 1.56847 3.69334 1.1934C4.06841 0.818333 4.80298 0.833489 5.33341 0.833489H12.3333L17.3333 5.83344V16.8333C17.3333 17.3638 17.3484 18.0983 16.9734 18.4734C16.5983 18.8485 15.8637 18.8333 15.3333 18.8333Z"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.3346 0.833405V4.83337C12.3346 5.09858 12.44 5.35293 12.6275 5.54047C12.8151 5.728 13.0694 5.83336 13.3346 5.83336H17.3346"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9.16674H12.2407V12.4074H9V9.16674Z"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 15V9.16674"
      stroke="#454548"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export default PptDocumentIcon
