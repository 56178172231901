import { action } from 'mobx'

import Store from '../helpers/Store'

export const setIsStreaming = action(
  (store: Store, matterId: string, value: boolean) => {
    store.isStreaming[matterId] = value
  }
)

export const getIsStreaming = (store: Store, matterId?: string) => {
  if (!matterId) {
    return false
  }

  return store.isStreaming[matterId]
}
