const CopyIcon = ({
  className = 'stroke-icon-default-secondary',
}: {
  className?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M7.51001 9.667C7.51001 8.95967 7.791 8.28131 8.29116 7.78115C8.79132 7.28099 9.46968 7 10.177 7H18.843C19.1932 7 19.5401 7.06898 19.8636 7.20301C20.1872 7.33704 20.4812 7.53349 20.7289 7.78115C20.9765 8.0288 21.173 8.32281 21.307 8.64638C21.441 8.96996 21.51 9.31676 21.51 9.667V18.333C21.51 18.6832 21.441 19.03 21.307 19.3536C21.173 19.6772 20.9765 19.9712 20.7289 20.2189C20.4812 20.4665 20.1872 20.663 19.8636 20.797C19.5401 20.931 19.1932 21 18.843 21H10.177C9.82677 21 9.47997 20.931 9.15639 20.797C8.83282 20.663 8.53881 20.4665 8.29116 20.2189C8.0435 19.9712 7.84705 19.6772 7.71302 19.3536C7.57899 19.03 7.51001 18.6832 7.51001 18.333V9.667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.52201 16.737C4.21501 16.5626 3.95966 16.31 3.78191 16.0049C3.60416 15.6998 3.51035 15.3531 3.51001 15V5C3.51001 3.9 4.41001 3 5.51001 3H15.51C16.26 3 16.668 3.385 17.01 4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
export default CopyIcon
