import { Alert } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import addFlash from '../../actions/AddFlash'
import {
  isMdSize,
  setShowDocumentManagers,
} from '../../actions/showLayoutActions'
import { getUploadBatches } from '../../actions/upload_batch/uploadBatchActions'
import { isCurrentFolderUploadEnabled } from '../../helpers/folderUtils'
import { useStore } from '../../helpers/useStore'
import { useScreenSize } from '../../hooks/useScreenSize'
import { useUploadToFolderWithConfirmation } from '../../hooks/useUploadToFolderWithConfirmation'
import ShieldCheckmarkIcon from '../assets/ShieldCheckmarkIcon'
import UploadIcon from '../assets/UploadIcon'
import Breadcrumbs from '../breadcrumbs/Breadcrumbs'
import DocumentViewer from '../documents/document_viewer/DocumentViewer'
import FilesAndFolders from '../documents/FilesAndFolders'
import PaginationComponent from '../documents/PaginationComponent'
import UploadProgressBar from '../documents/UploadProgressBar'
import ConfirmUploadModal from '../file_uploads/ConfirmUploadModal'
import CollapsibleLayout from '../shared/CollapsibleLayout'

const DocumentManager = observer(() => {
  const store = useStore()
  const { screenWidth } = useScreenSize()
  const { documentId } = useParams()

  const [dragActive, setDragActive] = useState(false)

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragActive(true)
  }

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    const relatedTarget = e.relatedTarget as HTMLElement | null

    if (!relatedTarget || !e.currentTarget.contains(relatedTarget)) {
      setDragActive(false)
    }
  }

  const {
    isModalOpen,
    filesDetected,
    filesSupported,
    filesUnsupported,
    startUploadFromDrop,
    startUploadFromFiles,
    handleConfirm,
    closeModal,
  } = useUploadToFolderWithConfirmation()

  const currentFolderUploadEnabled = isCurrentFolderUploadEnabled({ store })
  const uploadInProgress = store.computed.matterUploadInProgress

  const disabled = !currentFolderUploadEnabled || uploadInProgress
  const flashMessage = !currentFolderUploadEnabled
    ? 'Files cannot be uploaded to the "Alexi Documents" folder. Drag and drop your files to another folder instead.'
    : uploadInProgress
      ? 'Your current document upload is in progress. You’ll be able to add more documents once the upload is complete.'
      : ''

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    setDragActive(false)

    if (disabled) {
      addFlash(store, 'notice', flashMessage)
      return
    }

    startUploadFromDrop(e)
  }
  const showDocumentViewer = Boolean(documentId)

  const uploadBatches = getUploadBatches(store, store.selectedMatter?.id)

  return (
    <div
      className={`relative h-[99.65%] w-full ${
        dragActive
          ? 'border-[2.5px] border-brd-brand-default bg-[rgba(245,242,255,0.20)] rounded-[8px]'
          : ''
      }`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {dragActive && (
        <Alert
          variant="outlined"
          className="absolute bottom-8 left-1/2 transform -translate-x-1/2
            flex flex-row justify-center items-center w-[19.5rem] [&>*]:!mr-0 px-3 py-2 rounded border
            border-brd-neutral-default
            bg-bgCol-default-default
            shadow-[0px_2px_8px_0px_rgba(156,156,156,0.08)]"
          icon={<UploadIcon />}
        >
          <span className="text-black text-sm">
            Drop into &quot;Uploaded Documents&quot;.
          </span>
        </Alert>
      )}
      <CollapsibleLayout
        className="h-full"
        documentPanelStyling={true}
        showLeftControlButton={!isMdSize(screenWidth)}
        handleLeftControlButtonClick={() => {
          setShowDocumentManagers(store, false)
        }}
        headerLeftComponent={
          <span className="flex items-center">{<Breadcrumbs />}</span>
        }
        footerComponent={
          !showDocumentViewer && (
            <div>
              {uploadBatches.map((batch, idx) => {
                return <UploadProgressBar key={idx} uploadBatch={batch} />
              })}
              <PaginationComponent />
              <div className="flex items-center justify-center gap-2">
                <ShieldCheckmarkIcon />
                <span className="text-sm text-txt-default-default p-2">
                  Your confidential information is protected.{' '}
                  <a
                    href="https://trust.alexi.com/"
                    target={'_'}
                    className="text-txt-brand-default font-medium hover:underline"
                  >
                    Read.
                  </a>
                </span>
              </div>
            </div>
          )
        }
      >
        {showDocumentViewer ? (
          <DocumentViewer />
        ) : (
          <FilesAndFolders onFilesSelected={startUploadFromFiles} />
        )}
      </CollapsibleLayout>
      <ConfirmUploadModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        filesDetected={filesDetected}
        filesWillUpload={filesSupported}
        filesUnsupported={filesUnsupported}
      />
    </div>
  )
})

export default DocumentManager
