import { Navbar, Tooltip } from '@material-tailwind/react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { setMatters, setSelectedMatter } from '../../actions/matterActions'
import { resetInput } from '../../actions/ResetInput'
import { setShowSidebar } from '../../actions/showLayoutActions'
import { useStore } from '../../helpers/useStore'
import CreateIcon from '../assets/CreateIcon'
import CreateIconDisabled from '../assets/CreateIconDisabled'
import FilterIcon from '../assets/FilterIcon'
import SortIcon from '../assets/SortIcon'
import FilterModal from '../FilterModal'
import SortModal from '../SortModal'

const MattersListHeader = observer(() => {
  const navigate = useNavigate()
  const store = useStore()
  const [isSortingDropdownVisible, setIsSortingDropdownVisible] =
    useState(false)
  const [isFilterDropdownVisible, setIsFilterDropdownVisible] = useState(false)

  const handleSort = (sortParams: Map<string, string>) => {
    runInAction(() => {
      store.sortParams = sortParams
    })
    setMatters(store)
    setIsSortingDropdownVisible(false)
  }

  const handleFilter = (filterParams: Map<string, string>) => {
    runInAction(() => {
      store.filterParams = filterParams
    })
    setMatters(store)
    setIsFilterDropdownVisible(false)
  }
  const handleNewMatter = async () => {
    setSelectedMatter(store, null)
    resetInput(store)

    runInAction(() => {
      store.activities = []
      store.inputRef?.focus()
      store.isInputFocused = true
    })

    navigate('/chat')
  }
  const handleMobileMatterClick = () => {
    handleNewMatter()
    setShowSidebar(store, false)
  }

  return (
    <Fragment>
      <Navbar className="p-4 sticky top-0 z-10">
        <div className="flex items-center justify-between relative">
          <div className="text-lg font-medium text-txt-default-default">
            Matters
          </div>
          <div className="flex space-x-2">
            <Tooltip placement="bottom" content="Filter">
              <button
                className="text-xl"
                onClick={() =>
                  setIsFilterDropdownVisible(!isFilterDropdownVisible)
                }
              >
                <FilterIcon
                  className={
                    store.filterParams
                      ? 'stroke-brd-brand-default'
                      : 'stroke-bgCol-utilities-default'
                  }
                />
                {store.filterParams && (
                  <span className="absolute top-0 left-4 mt-0.5 mr-0.5 h-2 w-2 rounded-full bg-bgCol-success-default opacity-75"></span>
                )}
              </button>
            </Tooltip>

            <Tooltip placement="bottom" content="Sort">
              <button
                className="text-xl relative"
                onClick={() =>
                  setIsSortingDropdownVisible(!isSortingDropdownVisible)
                }
              >
                <SortIcon
                  className={
                    store.sortParams
                      ? 'stroke-brd-brand-default'
                      : 'stroke-bgCol-utilities-default'
                  }
                />
                {store.sortParams && (
                  <span className="absolute top-0 left-4 mt-0.5 mr-0.5 h-2 w-2 rounded-full bg-bgCol-success-default opacity-75"></span>
                )}
              </button>
            </Tooltip>

            {store.selectedMatter === null ? (
              <button
                className="bg-bgCol-disabled-default p-1.5 rounded hover:cursor"
                disabled
              >
                <CreateIconDisabled />
              </button>
            ) : (
              <Tooltip placement="bottom" content="New matter">
                <button
                  className="bg-bgCol-brand-default p-1.5 rounded"
                  onClick={handleNewMatter}
                  onTouchEnd={handleMobileMatterClick}
                >
                  <CreateIcon />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </Navbar>
      {isSortingDropdownVisible && (
        <SortModal
          onSort={handleSort}
          onClose={() => {
            setIsSortingDropdownVisible(false)
          }}
          sortParams={store.sortParams}
        />
      )}
      {isFilterDropdownVisible && (
        <FilterModal
          onFilter={handleFilter}
          onClose={() => {
            setIsFilterDropdownVisible(false)
          }}
          filterParams={store.filterParams}
        />
      )}
    </Fragment>
  )
})

export default MattersListHeader
