import React from 'react'

const JpgDocumentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_5258_33588)">
      <path
        d="M16.4815 1.66666H3.51852C2.49577 1.66666 1.66666 2.49577 1.66666 3.51852V16.4815C1.66666 17.5042 2.49577 18.3333 3.51852 18.3333H16.4815C17.5042 18.3333 18.3333 17.5042 18.3333 16.4815V3.51852C18.3333 2.49577 17.5042 1.66666 16.4815 1.66666Z"
        stroke="#454548"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.22221 9.07407C8.24496 9.07407 9.07407 8.24497 9.07407 7.22222C9.07407 6.19947 8.24496 5.37037 7.22221 5.37037C6.19946 5.37037 5.37036 6.19947 5.37036 7.22222C5.37036 8.24497 6.19946 9.07407 7.22221 9.07407Z"
        stroke="#454548"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 12.7777L15.4759 9.92032C15.1287 9.57315 14.6577 9.37812 14.1667 9.37812C13.6756 9.37812 13.2047 9.57315 12.8574 9.92032L4.44444 18.3333"
        stroke="#454548"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5258_33588">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default JpgDocumentIcon
