import React from 'react'

interface WhiteUploadIconProps {
  className: string
}
const WhiteUploadIcon = ({ className }: WhiteUploadIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    className={className}
    fill="none"
  >
    <g clipPath="url(#clip0_6536_23736)">
      <path
        d="M1.5 11.7059V13.353C1.5 13.7898 1.67353 14.2087 1.98241 14.5176C2.2913 14.8265 2.71023 15 3.14706 15H13.0294C13.4662 15 13.8852 14.8265 14.1941 14.5176C14.5029 14.2087 14.6765 13.7898 14.6765 13.353V11.7059M3.97059 5.11766L8.08823 1.00002M8.08823 1.00002L12.2059 5.11766M8.08823 1.00002V10.8824"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6536_23736">
        <rect width="16" height="16" fill="none" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
)
export default WhiteUploadIcon
