import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React from 'react'

import FailedInputFileCard from './FailedInputFileCard'
import InputFileCard from './InputFileCard'

const SlidingFilePopup = observer(({ files }) => {
  return (
    <div
      className={`transition-transform duration-400 ease-in-out transform ${
        files.length > 0 ? 'h-3/4 translate-y-0' : 'h-0 w-0 translate-y-full'
      }`}
    >
      <div
        className={`${
          files.length > 0 ? '' : 'hidden'
        } grid grid-cols-[repeat(auto-fit,minmax(15rem,1fr))] 2xl:grid-cols-3 gap-4 p-4 max-h-[180px] overflow-x-hidden overflow-y-auto scrollbar-visible`}
      >
        {files.map((fileObj, index) =>
          fileObj.legacy_status === 'error' ? (
            <FailedInputFileCard
              key={index}
              fileId={fileObj.file.id}
              filename={fileObj.filename}
              error={fileObj.error_msg}
            />
          ) : (
            <InputFileCard key={index} attachment={fileObj} />
          )
        )}
      </div>
    </div>
  )
})

SlidingFilePopup.propTypes = {
  files: PropTypes.array.isRequired,
}

export default SlidingFilePopup
