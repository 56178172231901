import { Tooltip } from '@material-tailwind/react'
import React from 'react'

import CopyIcon from '../assets/CopyIcon'
import DownloadIcon from '../assets/DownloadIcon'
import FolderPlusIcon from '../assets/FolderPlusIcon'
import MailIcon from '../assets/MailIcon'

interface ChatInteractionButtonGroupProps {
  showButtons: boolean
}

const ChatInteractionButtonGroup = ({
  showButtons,
}: ChatInteractionButtonGroupProps) => {
  const buttonStyle =
    'stroke-icon-default-secondary group-hover:stroke-icon-brand-default focus:stroke-icon-brand-default'

  if (!showButtons) {
    return <div className="flex space-x-4 pt-4 h-10"></div>
  }
  // TODO: fill onClick action
  return (
    <div className="flex space-x-4 pt-4 h-10">
      <Tooltip placement="top" content="Copy">
        <button className="group" onClick={() => {}}>
          <CopyIcon className={buttonStyle} />
        </button>
      </Tooltip>

      <Tooltip placement="top" content="Download">
        <button className="group" onClick={() => {}}>
          <DownloadIcon classNames={buttonStyle} />
        </button>
      </Tooltip>

      <Tooltip placement="top" content='Save to "Alexi Documents"'>
        <button className="group" onClick={() => {}}>
          <FolderPlusIcon className={buttonStyle} />
        </button>
      </Tooltip>

      <Tooltip placement="top" content="Email to myself">
        <button className="group" onClick={() => {}}>
          <MailIcon className={buttonStyle} />
        </button>
      </Tooltip>
    </div>
  )
}

export default ChatInteractionButtonGroup
