import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk'
import { runInAction } from 'mobx'
import { useEffect } from 'react'

import Store from './Store'
import { useStore } from './useStore'

export interface FeatureFlags {
  podCia: boolean
}

export const useFeatureFlags = (): FeatureFlags => {
  const store = useStore()
  const flags = useFlags<Partial<FeatureFlags>>()

  useEffect(() => {
    runInAction(() => {
      store.isAlr = flags.podCia ?? false
    })
  }, [flags.podCia])

  return {
    podCia: flags.podCia ?? false,
  }
}

export async function initializeLDProvider(store: Store) {
  if (!store.user) {
    throw new Error('User info is required to initialize LaunchDarkly')
  }

  return await asyncWithLDProvider({
    clientSideID: store.credentials.launchDarklyClientId ?? '',
    context: {
      kind: 'multi',
      user: {
        key: store.user.id,
        name: store.user.full_name,
        email: store.user.email,
      },
      company: {
        key: store.user.enterprise.id,
        name: store.user.enterprise.name,
      },
    },
  })
}
