import { IconButton } from '@material-tailwind/react'
import React from 'react'

import PaginationLeftIcon from '../assets/PaginationLeftIcon'
import PaginationRightIcon from '../assets/PaginationRightIcon'

interface AdminMattersPaginationProps {
  pagination: {
    current_page: number
    per_page: number
    total_count: number
    total_pages: number
  }
  setPage: (page: number) => void
}

const AdminMattersPagination = ({
  pagination,
  setPage,
}: AdminMattersPaginationProps) => {
  return (
    <div className="flex flex-row justify-between px-4 pt-2">
      <div className="flex flex-row items-center gap-1 text-xs">
        <span className="text-xs font-medium">Showing</span>
        <span className="text-xs font-medium">
          {pagination.per_page * (pagination.current_page - 1) + 1}-
          {Math.min(
            pagination.per_page * pagination.current_page,
            pagination.total_count
          )}
        </span>
        <span className="text-xs font-medium">of</span>
        <span className="text-xs font-medium">{pagination.total_count}</span>
        <span className="text-xs font-medium">items</span>
      </div>

      <div className="flex items-center gap-2 text-xs px-2 py-1">
        <IconButton
          size="sm"
          variant="text"
          disabled={pagination.current_page === 1}
          onClick={() => setPage(pagination.current_page - 1)}
          className="p-1"
        >
          <PaginationLeftIcon />
        </IconButton>

        <div className="flex items-center px-2 gap-1 py-1">
          <span className="text-xs font-medium">Page</span>
          <span className="text-xs font-medium">{pagination.current_page}</span>
          <span className="text-xs font-medium">of</span>
          <span className="text-xs font-medium">{pagination.total_pages}</span>
        </div>

        <IconButton
          size="sm"
          variant="text"
          disabled={pagination.current_page === pagination.total_pages}
          onClick={() => setPage(pagination.current_page + 1)}
          className="p-1"
        >
          <PaginationRightIcon />
        </IconButton>
      </div>
    </div>
  )
}

export default AdminMattersPagination
