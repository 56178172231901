import axios from 'axios'
import { runInAction } from 'mobx'

import addFlash from '../actions/AddFlash'
import { extendSessionTimeout } from '../actions/sessionActions'
import { rollbarConfig } from '../helpers/rollbarConfig'

const formatFolderStructure = (folderEntries) => {
  const folderTree = {}

  // Iterate over the folder entries
  for (const path in folderEntries) {
    const parts = path.split('/') // Split the folder path into parts
    let currentLevel = folderTree

    parts.forEach((part, index) => {
      if (!part) {
        return // Skip empty parts (root path)
      }
      if (!currentLevel[part]) {
        currentLevel[part] = {
          title: part,
          path: parts.slice(0, index + 1).join('/'),
          isEmptyFolder: folderEntries[path][0]?.isEmptyFolder || false,
          children: {},
        }
      }
      currentLevel = currentLevel[part].children
    })

    // If the entry contains files (not folders), attach them to the current level
    folderEntries[path].forEach((item) => {
      if (!item.isEmptyFolder && item.name) {
        currentLevel[item.name] = {
          title: item.name,
          path: item.relativePath,
          isFile: true,
        }
      }
    })
  }

  // Convert to array-based tree structure
  const convertToArray = (node) => {
    return Object.values(node).map((child) => {
      if (child.children) {
        child.children = convertToArray(child.children)
      }
      return child
    })
  }

  return convertToArray(folderTree)
}

export const fetchFolders = async (store, matterId) => {
  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content')

    if (!csrfToken) {
      throw new Error('CSRF token not found')
    }

    const headers = {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
    }

    const response = await axios.get(`/api/client/v1/folders`, {
      headers,
      params: {
        matter_id: matterId, // Pass the `matter_id` as query params
      },
    })

    if (response.status === 200) {
      runInAction(() => {
        store.currentFolder = response.data || {}
      })
    } else {
      throw new Error(
        `Failed to fetch folders. Status code: ${response.status}`
      )
    }
  } catch (error) {
    console.error('Error fetching folders:', error)
    throw error // Rethrow to handle upstream
  }
}

export const createFolders = async (foldersToUpload, store) => {
  extendSessionTimeout(store)

  // Format the folder structure to match backend expectations
  const formattedFolders = formatFolderStructure(foldersToUpload)

  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')

    // API call to create folders
    const response = await axios.post(
      '/api/client/v1/folders',
      {
        folders: formattedFolders,
        parent_folder_id: store.currentFolder?.id,
        matter_id: store.selectedMatter?.id,
        sort_params: Object.fromEntries(store.documentSortParams),
      },
      {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      }
    )

    if (response.status === 200 || response.status === 201) {
      runInAction(() => {
        store.currentFolder = response.data || {}
      })
    } else {
      console.error('Failed to create folders on the server.')
      addFlash(store, 'error', 'Failed to create folders. Please try again.')
      return false
    }
  } catch (error) {
    console.error('Error creating folders:', error)
    addFlash(
      store,
      'error',
      error.response?.data?.error || 'An error occurred while creating folders.'
    )
    throw error
  }
}

export const updateFolder = async (store, folderId, payload) => {
  if (!folderId || Object.keys(payload).length === 0) {
    return
  }
  extendSessionTimeout(store)
  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content')
    const response = await axios.patch(
      `/api/client/v1/folders/${folderId}?matter_id=${store.selectedMatter?.id}`,
      {
        folder: { ...payload },
      },
      {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      }
    )
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(
        `Failed to update folder. Status code: ${response.status}`
      )
    }
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}

export const deleteFolder = async (store, folderId) => {
  if (!folderId) {
    return
  }
  extendSessionTimeout(store)
  try {
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content')

    const response = await axios.delete(
      `/api/client/v1/folders/${folderId}?matter_id=${store.selectedMatter?.id}`,
      {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      }
    )
    if (response.status === 200) {
      return response.data
    } else {
      throw new Error(
        `Failed to delete folder. Status code: ${response.status}`
      )
    }
  } catch (error) {
    rollbarConfig(store)?.error(error)
    throw error
  }
}
