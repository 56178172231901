import { useQueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import React from 'react'

import addFlash from '../../../actions/AddFlash'
import { deleteFile } from '../../../apis/filesApi'
import { rollbarConfig } from '../../../helpers/rollbarConfig'
import { EnterpriseAttachment } from '../../../helpers/Store'
import { useStore } from '../../../helpers/useStore'
import { FILES_AND_FOLDERS } from '../../../hooks/queries'
import XIcon from '../../assets/XIcon'

interface AttachmentDeleteFormProps {
  doc: EnterpriseAttachment
  onClose: () => void
}

// This component is copied from LegacyDocumentDeleteForm
// TODO: refactor with material ui diaglog componnet
const AttachmentDeleteModal: React.FC<AttachmentDeleteFormProps> = observer(
  ({ doc, onClose }) => {
    const store = useStore()
    const queryClient = useQueryClient()

    const handleDelete = async () => {
      try {
        await deleteFile(store, doc.id)
        queryClient.invalidateQueries({
          queryKey: [
            FILES_AND_FOLDERS,
            store.selectedMatter?.id,
            doc.folder_id,
          ],
        })
        onClose()
        addFlash(
          store,
          'notice',
          `${doc.filename} has been permanently deleted!`,
          {
            subType: 'deleteDocument',
          }
        )
      } catch (error) {
        onClose()
        addFlash(
          store,
          'error',
          `We couldn't delete the document. Retry or contact support@alexi.com for assistance.`,
          {
            subType: 'deleteDocument',
          }
        )
        // @ts-expect-error rollbar handles this
        rollbarConfig(store)?.error(error)
      }
    }

    return (
      <div>
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white py-6 rounded-lg shadow-lg max-w-lg w-full text-txt-default-default">
            <div className="px-6 pb-4 flex justify-between items-center border-b border-brd-default-defaul">
              <h2 className="text-lg font-semibold">Delete the document</h2>
              <div onClick={onClose}>
                <XIcon />
              </div>
            </div>
            <div className="py-4 px-6 text-left">
              <label className="block mb-4">
                Are you sure you would like to delete{' '}
                <span className="md:italic">{doc.filename}</span>?
              </label>
              <label>
                Deleting this document will permanently remove it from our
                system.
              </label>
            </div>

            <div className="px-4 text-right">
              <button
                className="bg-bgCol-disabled-default text-black px-4 py-2 rounded mr-5"
                onClick={onClose}
              >
                No, cancel
              </button>
              <button
                className="bg-bgCol-danger-default text-white px-4 py-2 rounded mr-2"
                onClick={handleDelete}
              >
                Yes, delete
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

export default AttachmentDeleteModal
