import { Button, Tooltip } from '@material-tailwind/react'
import React, { useRef, useState } from 'react'

import { SUPPORTED_EXTENSIONS } from '../../helpers/constants'
import { isCurrentFolderUploadEnabled } from '../../helpers/folderUtils'
import { useStore } from '../../helpers/useStore'
import WhiteUploadIcon from '../assets/WhiteUploadIcon'

interface UploadButtonProps {
  onFilesSelected: (files: File[], isFolderUpload: boolean) => void
  className?: string
}

const UploadButton: React.FC<UploadButtonProps> = ({
  onFilesSelected,
  className,
}) => {
  const store = useStore()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen)
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || [])
    const isFolderUpload =
      fileInputRef.current?.hasAttribute('webkitdirectory') || false

    onFilesSelected(files, isFolderUpload)
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Reset input value
    }
    setDropdownOpen(false) // Close dropdown
  }

  const handleOptionClick = (type: 'Documents' | 'Folders') => {
    if (fileInputRef.current) {
      if (type === 'Documents') {
        fileInputRef.current.removeAttribute('webkitdirectory')
      } else if (type === 'Folders') {
        fileInputRef.current.setAttribute('webkitdirectory', 'true')
      }
      fileInputRef.current.click()
    }
  }

  const currentFolderUploadEnabled = isCurrentFolderUploadEnabled({ store })
  const uploadInProgress = store.computed.matterUploadInProgress
  const disabled = !currentFolderUploadEnabled || uploadInProgress

  const tooltipContent = uploadInProgress
    ? 'Your current document upload is in progress. You’ll be able to add more documents once the upload is complete.'
    : !currentFolderUploadEnabled
      ? 'Files cannot be uploaded to the "Alexi Documents" folder. To upload files, first navigate to another folder.'
      : ''

  const UploadButtonComponent = () => {
    return (
      <Button
        disabled={disabled}
        onClick={toggleDropdown}
        className="px-6 py-2 flex items-center space-x-2 bg-bgCol-brand-default text-white rounded-md focus:ring-2 
        disabled:bg-bgCol-default-secondaryHover disabled:text-txt-disabled-default disabled:cursor-not-allowed"
      >
        <WhiteUploadIcon
          className={
            disabled
              ? 'stroke-icon-disabled-default fill-icon-disabled-default'
              : 'stroke-white fill-white'
          }
        />
        <span className="text-sm font-medium">Upload</span>
      </Button>
    )
  }

  return (
    <div className={`relative inline-block ${className || ''}`}>
      {disabled ? (
        <Tooltip
          className="max-w-96"
          content={<span className="text-xs">{tooltipContent}</span>}
          placement="bottom-end"
        >
          <span>
            <UploadButtonComponent />
          </span>
        </Tooltip>
      ) : (
        <UploadButtonComponent />
      )}

      {isDropdownOpen && (
        <div className="absolute top-full mt-2 right-0 bg-white border border-gray-300 rounded-md shadow-lg w-35">
          <button
            onClick={() => handleOptionClick('Documents')}
            className="w-full px-4 py-2 text-left text-txt-default-default hover:bg-bgCol-default-secondary"
          >
            Documents
          </button>
          <button
            onClick={() => handleOptionClick('Folders')}
            className="w-full px-4 py-2 text-left text-txt-default-default hover:bg-bgCol-default-secondary"
          >
            Folders
          </button>
        </div>
      )}
      {/* Hidden File Input */}
      <input
        ref={fileInputRef}
        type="file"
        multiple={true}
        accept={SUPPORTED_EXTENSIONS.join(',')}
        onChange={handleFileChange}
        className="hidden"
      />
    </div>
  )
}

export default UploadButton
