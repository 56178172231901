import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { GET_FOLDERS } from './queries'
import { fetchFolders } from '../apis/filesApi'
import Store, { Folder } from '../helpers/Store'

const useFolders = (
  store: Store,
  searchTerm?: string
): UseQueryResult<Folder[]> => {
  const { id } = useParams()

  return useQuery<Folder[]>({
    queryKey: [GET_FOLDERS, id, searchTerm],
    queryFn: () => fetchFolders(store, searchTerm),
    enabled: Boolean(id),
    staleTime: store.computed.uploadInProgress ? 0 : Infinity,
  })
}

export default useFolders
