import { Tooltip } from '@material-tailwind/react'
import PropTypes from 'prop-types'
import React, { useRef, useEffect, useState } from 'react'

const useIsTextTruncated = (text) => {
  const textRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        setIsTruncated(
          textRef.current.scrollWidth > textRef.current.clientWidth
        )
      }
    }

    checkTruncation()
    window.addEventListener('resize', checkTruncation)

    return () => window.removeEventListener('resize', checkTruncation)
  }, [text])

  return [textRef, isTruncated]
}

export const TruncatedText = ({
  text,
  additionalClasses = '',
  textClasses = 'text-txt-default-secondary',
}) => {
  const [textRef, isTruncated] = useIsTextTruncated(text) // Pass text to the hook

  const textContent = (
    <p
      ref={textRef}
      className={`${textClasses} text-sm font-normal leading-tight truncate w-[290px]`}
    >
      {text}
    </p>
  )

  return isTruncated ? (
    <Tooltip
      content={text}
      placement="bottom"
      className={`${additionalClasses}`}
    >
      {textContent}
    </Tooltip>
  ) : (
    textContent
  )
}

TruncatedText.propTypes = {
  text: PropTypes.string,
  additionalClasses: PropTypes.string,
  textClasses: PropTypes.string,
}
