import { useQueryClient } from '@tanstack/react-query'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import addFlash from '../../actions/AddFlash'
import { updateFolder } from '../../apis/foldersApi'
import { getIconForFolderType } from '../../helpers/constants'
import {
  isAlexiDocumentFolder,
  isAlexiReferencedCasesFolder,
} from '../../helpers/folderUtils'
import { Folder } from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import {
  FILES_AND_FOLDERS,
  GET_FOLDERS,
  MATTER_BREADCRUMBS,
} from '../../hooks/queries'
import FolderActionMenu from '../workflow/document_action/FolderActionMenu'
import FolderDeletionModal from '../workflow/document_action/FolderDeletionModal'
import MoveToFolderModal from '../workflow/document_action/MoveToFolderModal'
import NameEditor from '../workflow/document_action/NameEditor'

interface FolderTableRowProps {
  folder: Folder
  handleRowClick: () => void
}

const FolderTableRow = observer(
  ({ folder, handleRowClick }: FolderTableRowProps) => {
    const store = useStore()
    const queryClient = useQueryClient()

    const [isEditingName, setIsEditingName] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isMoveToNewFolderModalOpen, setIsMoveToNewFolderModalOpen] =
      useState(false)

    const [name, setName] = useState(folder.title)

    const handleDownload = async () => {
      //   TODO: download folder
    }

    const submitFolderName = async () => {
      setIsEditingName(false)
      await save({ title: name })
    }

    const save = async (payload: any) => {
      try {
        const matterId = store.selectedMatter?.id
        await updateFolder(store, folder.id, payload)
        queryClient.invalidateQueries({
          queryKey: [FILES_AND_FOLDERS, matterId, folder.parent_id],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_FOLDERS, matterId],
        })
        // invalidate the destination folder cached
        if (payload.parent_id) {
          queryClient.invalidateQueries({
            queryKey: [FILES_AND_FOLDERS, matterId, payload.parent_id],
          })
          queryClient.invalidateQueries({
            queryKey: [MATTER_BREADCRUMBS, matterId, payload.parent_id],
          })
        }
      } catch (e) {
        addFlash(
          store,
          'error',
          `We couldn't update ${folder.title}. please try again`
        )
      }
    }

    return (
      <>
        <tr
          key={folder.id}
          className="hover:bg-gray-50 cursor-pointer text-sm h-12"
          onClick={handleRowClick}
        >
          <td className="p-1">
            <div className="flex justify-center align-center">
              {getIconForFolderType(folder)}
            </div>
          </td>
          <td className="p-1 text-txt-default-default max-w-[100px] truncate">
            {isEditingName ? (
              <NameEditor
                name={name}
                handleChange={(value: string) => setName(value)}
                submitChange={submitFolderName}
              />
            ) : (
              <span className="overflow-hidden">{name}</span>
            )}
          </td>
          {!isAlexiReferencedCasesFolder(folder.folder_type) && (
            <td className="p-1">Folder</td>
          )}
          {!isAlexiDocumentFolder(folder.folder_type) && (
            <td className="px-2 py-2 justify-center">
              <FolderActionMenu
                onEditName={() => setIsEditingName(true)}
                onDownload={handleDownload}
                onDelete={() => setIsDeleteModalOpen(true)}
                onMove={() => {
                  setIsMoveToNewFolderModalOpen(true)
                }}
              />
            </td>
          )}
        </tr>
        <FolderDeletionModal
          folder={folder}
          isOpen={isDeleteModalOpen}
          handleOpen={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
        />
        <MoveToFolderModal
          name={name}
          isOpen={isMoveToNewFolderModalOpen}
          handleOpen={() => {
            setIsMoveToNewFolderModalOpen(!isMoveToNewFolderModalOpen)
          }}
          handleSave={(folderId) => {
            save({ parent_id: folderId })
          }}
        />
      </>
    )
  }
)
export default FolderTableRow
