import { toJS, runInAction, action } from 'mobx'
import PropTypes from 'prop-types'

import { createActivityChannelSubscription } from './activity_channel'
import consumer from './consumer'
import { createDocumentChannelSubscription } from './document_channel'
import addFlash from '../actions/AddFlash'
import {
  identifyFastCaseUrls,
  replaceFastCaseUrls,
} from '../actions/caselawActions'
import { setChatResponseLoading } from '../actions/chatResponseLoadingActions'
import {
  getLastProcessedId,
  initializeLastProcessedIds,
  setLastProcessedIds,
} from '../actions/lastProcessedIdsStoreActions'
import {
  deleteMatterChannel,
  getMatterChanel,
  setMatterChannel,
  setActivityChannel,
  deleteActivityChannel,
  getActivityChannel,
  setDocumentChannel,
  getDocumentChannel,
  deleteDocumentChannel,
} from '../actions/matterChannelsActions'
import {
  addMessageToQueue,
  getMessageQueue,
  getNextMessageFromQueue,
  initializeMessageQueue,
  setMessageQueue,
} from '../actions/messageQueuesStoreActions'
import {
  getMessages,
  addMessage,
  addToExistingMessage,
  createNewMessage,
  findMessage,
  initializeMessages,
} from '../actions/messagesActions'
import { setIsStreaming } from '../actions/streamingStateActions'
import { rollbarConfig } from '../helpers/rollbarConfig'
import Store, {
  ResponseAdditionalContext,
  Message,
  MessageToolCall,
  ChatMessageToolCallProps,
} from '../helpers/Store'

const handleEndOfStream = async (
  store: Store,
  matterId: string,
  messageId: string
) => {
  const messageQueue = getMessageQueue(store, matterId)
  const lastProcessedId = getLastProcessedId(store, matterId)

  if (lastProcessedId === 0 && messageQueue.length === 0) {
    // If we never processed any chunks, then we are in a serious error state
    reportStreamSyncError(
      store,
      matterId,
      messageId,
      'Stream Sync Error: no received chunks'
    )
    addFlash(store, 'error', 'Something went wrong. Please refresh the page.')
    if (store.selectedMatter) {
      setChatResponseLoading(store, store.selectedMatter.id, false)
    }
  } else if (lastProcessedId > 10 && messageQueue.length > 0) {
    // If we have left-over chunks in the queue but we received at least a few chunks, then we are in an error state, but perhaps not a serious one
    reportStreamSyncError(
      store,
      matterId,
      messageId,
      'Stream Sync Error: stream ended with leftover chunks'
    )
  }

  resetStreamState(store, matterId)

  runInAction(() => {
    setIsStreaming(store, matterId, false)
  })
}

const resetStreamState = (store: Store, matterId: string) => {
  setLastProcessedIds(store, matterId, 0)
  setMessageQueue(store, matterId, [])
}

const reportStreamSyncError = (
  store: Store,
  matterId: string,
  messageId: string,
  errorMessage: string
) => {
  const message = findMessage(store, matterId, messageId)
  const lastProcessedId = getLastProcessedId(store, matterId)
  const messageQueue = getMessageQueue(store, matterId)

  console.error(errorMessage)

  rollbarConfig(store)?.error('Chat Stream Synchronization error', {
    user: toJS(store.user),
    matterId: store.selectedMatter?.id,
    lastProcessedId,
    messageQueue: toJS(messageQueue),
    message: toJS(message),
    errorMessage,
  })
}

const handleMessageToolCall = action(
  (store: Store, updateMessage: ChatMessageToolCallProps, matterId: string) => {
    const messages: Message[] = getMessages(store, matterId)
    const message = messages.find(
      (innerMessage) => innerMessage.id === updateMessage.id
    )

    const toolProcess = updateMessage.tool_processes[0]

    if (!message || !updateMessage.finish_time_at || !toolProcess) {
      return
    }

    const newToolProcess: MessageToolCall = {
      async_estimate_at: toolProcess.async_estimate_at,
      async_estimate_seconds: toolProcess.async_estimate_seconds,
      tool_message: toolProcess.tool_message,
    }

    if (!message.tool_processes) {
      message.tool_processes = []
    }
    message.tool_processes.push(newToolProcess)

    message.finish_time_at = new Date(updateMessage.finish_time_at)
  }
)

const handleChatMessageChunk = async (
  store: Store,
  message: ChatMessageChunk,
  matterId: string
) => {
  if (message.matter_id !== matterId) {
    return
  }

  const messageContent = message.content.parts.join('')
  const messageAdditionalContent =
    (message.response_additional_context as
      | ResponseAdditionalContext
      | undefined) || null
  const currentType = message.current_type
  if (!store.isAlr) {
    await replaceFastCaseUrls(store, matterId, message.id)
  }

  if (messageContent === 'END_OF_STREAM') {
    // Tricky situation here. WS events may arrive out of order, so we may receive the end of stream message while there are still message chunks left to receive. This case is likely to be common. We do not wish to complain about an error until a few seconds after the end of stream chunk arrived.
    setTimeout(() => handleEndOfStream(store, matterId, message.id), 250)
    return
  }

  initializeLastProcessedIds(store, matterId)
  initializeMessageQueue(store, matterId)
  initializeMessages(store, matterId)

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  if (!store.isAlr && store.user!.enterprise.country_code === 'US') {
    await identifyFastCaseUrls(store, message)
  }

  addMessageToQueue(store, matterId, message)

  processMessageQueue(store, matterId, currentType, messageAdditionalContent)
}

const processMessageQueue = (
  store: Store,
  matterId: string,
  currentType: string,
  messageAdditionalContent: ResponseAdditionalContext | null
) => {
  const messageQueue = getMessageQueue(store, matterId)

  let i = 0

  while (i < 100) {
    // In case our base cases fail in some way, use this counter to break the loop
    i += 1
    const nextExpectedChunkId = getLastProcessedId(store, matterId) + 1

    if (messageQueue.length === 0) {
      return
    }

    // We rely on the message queue storing messages sorted by chunk_id. If we fail to sort the array after adding to it, we may get stuck unable to append if the first message in the queue is not the next to be appended.
    if (messageQueue[0].chunk_id !== nextExpectedChunkId) {
      return
    }

    const nextMessage = getNextMessageFromQueue(store, matterId)
    const content = nextMessage.content.parts.join('')

    const existingMessage = findMessage(store, matterId, nextMessage.id)

    if (!existingMessage) {
      createNewMessage(
        store,
        nextMessage,
        matterId,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        store.user!.id,
        currentType,
        content,
        messageAdditionalContent
      )
    } else {
      addToExistingMessage(existingMessage, content)
    }
    setChatResponseLoading(store, matterId, false)

    setLastProcessedIds(store, matterId, nextMessage.chunk_id)
  }
}

const handleChatMessage = (
  store: Store,
  message: Message,
  matterId: string
) => {
  setChatResponseLoading(store, matterId, false)
  const chatMessage = findMessage(store, matterId, message.id)

  if (chatMessage !== undefined) {
    store.messages[matterId] = store.messages[matterId].map((m) =>
      m.id === message.id ? { ...m, ...message } : m
    )
  } else {
    addMessage(store, matterId, message)
  }

  // Sort messages by `created_at` to ensure the correct order
  store.messages[matterId].sort(
    (a, b) =>
      new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
  )
}

const connectToMatterChannel = (store: Store, matterId: string) => {
  if (!matterId) {
    return
  }

  unsubscribeFromMatterChannel(store, matterId)

  setMatterChannel(
    store,
    matterId,
    createMatterChannelSubscription(store, matterId)
  )
  setActivityChannel(
    store,
    matterId,
    createActivityChannelSubscription(store, matterId)
  )
  setDocumentChannel(
    store,
    matterId,
    createDocumentChannelSubscription(store, matterId)
  )
}

const unsubscribeFromMatterChannel = (store: Store, matterId: string) => {
  const existingChannel = getMatterChanel(store, matterId)
  const existingActivityChannel = getActivityChannel(store, matterId)
  const existingDocumentChannel = getDocumentChannel(store, matterId)

  if (existingChannel) {
    existingChannel.unsubscribe()

    deleteMatterChannel(store, matterId)
  }
  if (existingActivityChannel) {
    existingActivityChannel.unsubscribe()
    deleteActivityChannel(store, matterId)
  }
  if (existingDocumentChannel) {
    existingDocumentChannel.unsubscribe()
    deleteDocumentChannel(store, matterId)
  }
}

const createMatterChannelSubscription = (store: Store, matterId: string) => {
  return consumer.subscriptions.create(
    { channel: 'MatterChannel', matter_id: matterId },
    {
      received(data: string) {
        handleReceivedData(store, data, matterId)
      },
    }
  )
}

const handleReceivedData = (store: Store, data: string, matterId: string) => {
  try {
    const parsedChunk: WsMessageWrapper = JSON.parse(data)
    switch (parsedChunk.message_type) {
      case 'chat_message_estimate':
        handleMessageToolCall(
          store,
          parsedChunk.message as ChatMessageToolCallProps,
          matterId
        )
        break
      case 'chat_message_chunk':
        handleChatMessageChunk(
          store,
          parsedChunk.message as ChatMessageChunk,
          matterId
        )
        break
      case 'chat_message':
        handleChatMessage(store, parsedChunk.message as Message, matterId)
        break
      default:
        console.error('Unknown message type:', parsedChunk.message_type)

        rollbarConfig(store)?.error('Unknown message type', { parsedChunk })

        addFlash(
          store,
          'error',
          'Something went wrong. Please refresh the page.'
        )
        if (store.selectedMatter) {
          setChatResponseLoading(store, store.selectedMatter.id, false)
        }
    }
  } catch (e) {
    console.error('Failed to parse JSON chunk:', data, e)
    rollbarConfig(store)?.error(e as Error)
  }
}

interface WsMessageWrapper {
  message_type: 'chat_message_estimate' | 'chat_message_chunk' | 'chat_message'
  message: MessageToolCall | ChatMessageChunk | Message
}

interface ChatMessageChunk {
  id: string
  chunk_id: number
  content: {
    content_type: 'text'
    parts: string[]
  }
  current_type: string
  response_additional_context: ResponseAdditionalContext
  status: string
  chat_thread_id: string
  matter_id: string
}

handleChatMessageChunk.propTypes = {
  store: PropTypes.object.isRequired,
  message: PropTypes.shape({
    id: PropTypes.string,
    content: PropTypes.shape({
      parts: PropTypes.arrayOf(PropTypes.string),
      content_type: PropTypes.string,
    }),
    current_type: PropTypes.string,
    response_additional_context: PropTypes.object,
    status: PropTypes.string,
    chat_thread_id: PropTypes.string,
    chunk_id: PropTypes.number,
    matter_id: PropTypes.string,
  }).isRequired,
}

export { connectToMatterChannel }
