import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

import { GET_ENTERPRISE_ATTACHMENT } from './queries'
import { fetchFile } from '../apis/filesApi'
import Store, { EnterpriseAttachment } from '../helpers/Store'

export const useEnterpriseAttachment = (
  store: Store
): UseQueryResult<EnterpriseAttachment> => {
  const { id, documentId = '' } = useParams()

  return useQuery<EnterpriseAttachment>({
    queryKey: [GET_ENTERPRISE_ATTACHMENT, id, documentId],
    queryFn: () => fetchFile(store, documentId),
    enabled: Boolean(id) && Boolean(documentId),
    staleTime: Infinity,
  })
}
