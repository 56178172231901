import React from 'react'

import ReferencedWebSourcesPlaceholderIcon from '../../assets/ReferencedWebSourcesPlaceholderIcon'

const EmptyReferencedWebSourcesPlaceholder = () => {
  return (
    <>
      <div
        className={`h-full w-full flex flex-col justify-center items-center p-1.5 transition-all`}
      >
        <div className="flex flex-col justify-center items-center flex-grow">
          <ReferencedWebSourcesPlaceholderIcon className="mb-6" />
          <div className="text-center gap-2 flex flex-col">
            <span className="font-medium leading-6">
              Easily find your web sources
            </span>
            <span className="text-sm">
              All websites references by Alexi will be listed here for quick
              access.
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmptyReferencedWebSourcesPlaceholder
