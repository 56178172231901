import { IDocument, IHeaderOverride } from '@cyntler/react-doc-viewer'
import { Tooltip } from '@material-tailwind/react'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { downloadFile } from '../../../apis/filesApi'
import { useStore } from '../../../helpers/useStore'
import useBreadcrumbs from '../../../hooks/useBreadcrumbs'
import CloseIcon from '../../assets/CloseIcon'
import DownloadIcon from '../../assets/DownloadIcon'
import ExternalLinkIcon from '../../assets/ExternalLinkIcon'
import Loading from '../../assets/Loading'
import { ALLOWED_FILE_TYPES } from '../../file_uploads/HandleFilesUpload'

const NO_PREVIEW_FILE = [
  'application/msword', // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
  'application/vnd.ms-powerpoint', // ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
]

export const LoadingComponent = (
  <div className="w-full h-full flex items-center justify-center">
    <Loading />
  </div>
)

export interface ICustomDocument extends IDocument {
  id: string
}

const DocumentViewerHeader: IHeaderOverride = (state) => {
  const navigate = useNavigate()
  const { id: chatId } = useParams()
  const store = useStore()
  const { data: breadcrumbsData } = useBreadcrumbs(store)

  if (!state.currentDocument) {
    return null
  }

  const { id, uri, fileType = '' } = state.currentDocument as ICustomDocument

  const openInNewTab = () => {
    window.open(uri, '_blank')
  }
  const handleDownload = async () => {
    if (id) {
      await downloadFile(store, id)
    }
  }

  const handleClose = () => {
    const folder = breadcrumbsData?.breadcrumbs
      .filter((breadcrumb) => breadcrumb.type !== 'file')
      .at(-1)
    const folder_id = folder?.id || store.currentFolder.id
    navigate(`/chat/${chatId}/folder/${folder_id}`)
  }

  const supportedFileType = ALLOWED_FILE_TYPES.includes(fileType)
  const displayPreviewButton = !NO_PREVIEW_FILE.includes(fileType)

  return (
    <div className="w-full flex items-center justify-end p-4 gap-6">
      {displayPreviewButton && supportedFileType && (
        <button onClick={openInNewTab} className="relative group">
          <Tooltip placement="bottom" content="Open in a new tab">
            <ExternalLinkIcon className="w-6 h-6 stroke-icon-default-secondary group-hover:stroke-icon-brand-default" />
          </Tooltip>
        </button>
      )}
      {supportedFileType && (
        <button className="relative group" onClick={handleDownload}>
          <Tooltip placement="bottom" content="Download">
            <DownloadIcon classNames="w-6 h-6 stroke-icon-default-secondary group-hover:stroke-icon-brand-default" />
          </Tooltip>
        </button>
      )}
      <button className="relative group" onClick={handleClose}>
        <Tooltip placement="bottom" content="Close">
          <CloseIcon className="w-6 h-6 stroke-icon-default-secondary group-hover:stroke-icon-brand-default" />
        </Tooltip>
      </button>
    </div>
  )
}

export default DocumentViewerHeader
