const ArrowDownIcon = ({
  stroke = 'stroke-icon-default-default',
  fill = 'fill-icon-default-default',
  className = '',
}: {
  fill?: string
  stroke?: string
  className?: string
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`${className} transition-colors duration-200 ease-in-out`}
  >
    <path
      d="M12.6667 8.00008L8.00008 12.6667M8.00008 12.6667L3.33341 8.00008M8.00008 12.6667L8.00008 3.33341"
      stroke={stroke}
      fill={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ArrowDownIcon
