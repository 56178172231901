import React from 'react'

interface MatterTitleProps {
  matterName: string
  handleTitleClick: () => void
  titleStyle: any
}

const MatterTitle: React.FC<MatterTitleProps> = ({
  matterName,
  handleTitleClick,
  titleStyle,
}) => {
  return (
    <div className="hover:bg-bgCol-brand-secondaryHover rounded-lg transition-colors duration-200">
      <p
        className="text-txt-default-default overflow-hidden truncate"
        onClick={handleTitleClick}
        style={{ ...titleStyle }}
      >
        {matterName}
      </p>
    </div>
  )
}

export default MatterTitle
