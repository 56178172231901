import { Select, Option, IconButton } from '@material-tailwind/react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  getDocumentPagination,
  setDocumentPage,
  setDocumentPerPage,
} from '../../actions/documentPagination'
import Store from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import PaginationLeftIcon from '../assets/PaginationLeftIcon'
import PaginationRightIcon from '../assets/PaginationRightIcon'

const PaginationComponent = observer(() => {
  const store = useStore()

  if (_.isEmpty(store.currentFolder)) {
    return null
  }

  if (
    store.documentPagination.totalCount < 25 ||
    store.selectedMatter === null
  ) {
    return null
  }

  return (
    <div className="flex flex-row justify-between p-4">
      <CountComponent store={store} />
      <PageComponent store={store} />
    </div>
  )
})

interface CountComponentProps {
  store: Store
}

const CountComponent = observer(({ store }: CountComponentProps) => {
  const { perPage, totalCount } = getDocumentPagination(store)
  const options = [25, 50, 100, 250]

  return (
    <div className="flex flex-row items-center gap-2 text-xs">
      <span className="text-xs font-medium">Show</span>
      <Select
        variant="outlined"
        size="md"
        value={perPage.toString()}
        onChange={(val) => setDocumentPerPage(store, parseInt(val || '25'))}
        containerProps={{
          className: '!min-w-[3.8rem] h-8',
        }}
        labelProps={{
          className: 'before:mr-0 after:ml-0',
        }}
        className="!border-gray-300 !text-gray-700 text-sm p-1"
        menuProps={{
          className: 'bg-white border-gray-200 shadow-md text-sm',
        }}
      >
        {options.map((option) => (
          <Option
            key={option}
            value={option.toString()}
            className="
        rounded-md
        hover:bg-[#FAFAFA]
        data-[selected=true]:bg-[#F5F2FF]
        data-[selected=true]:text-txt-brand-default
        py-1 px-2 text-sm
      "
          >
            {option}
          </Option>
        ))}
      </Select>
      <span className="text-xs font-medium"> of </span>
      <span className="text-xs font-medium"> {totalCount}</span>
      <span className="text-xs font-medium"> items</span>
    </div>
  )
})

interface PageComponentProps {
  store: Store
}

const PageComponent = observer(({ store }: PageComponentProps) => {
  const { page, totalPages } = getDocumentPagination(store)

  return (
    <div className="flex items-center gap-2 text-xs px-2 py-1">
      {/* Left Pagination Button */}
      <IconButton
        size="sm"
        variant="text"
        disabled={page === 1}
        onClick={() => setDocumentPage(store, page - 1)}
        className="p-1"
      >
        <PaginationLeftIcon />
      </IconButton>

      {/* Page Input Box */}
      <div className="flex items-center px-2 gap-2 py-1">
        <span className="text-xs font-medium">Page</span>
        <input
          type="number"
          value={page}
          min={1}
          max={totalPages}
          onChange={(e) => {
            const newPage = Math.max(
              1,
              Math.min(totalPages, Number(e.target.value))
            )
            setDocumentPage(store, newPage)
          }}
          className="w-14 h-8 text-center border rounded-md mx-1 text-gray-900"
        />
        <span className="text-xs font-medium">of</span>
        <span className="text-xs font-medium">{totalPages}</span>
      </div>

      {/* Right Pagination Button */}
      <IconButton
        size="sm"
        variant="text"
        disabled={page === totalPages}
        onClick={() => setDocumentPage(store, page + 1)}
        className="p-1"
      >
        <PaginationRightIcon />
      </IconButton>
    </div>
  )
})

export default PaginationComponent
