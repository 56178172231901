import { Tooltip } from '@material-tailwind/react'
import React, { useState } from 'react'

import { scrollAndHighlightDocument } from '../../actions/activityItemActions'
import { showModal } from '../../actions/modalActions'
import { useStore } from '../../helpers/useStore'
import DocumentMagicIcon from '../assets/DocumentMagicIcon'
import DownloadIcon from '../assets/DownloadIcon'
import ViewIcon from '../assets/ViewIcon'
import MemoDownloadDropdown from '../main_chat/memoGeneration/MemoDownloadDropdown'
import { TruncatedText } from '../TruncatedText'
import {
  IInstantMemo,
  ISummaryDocument,
  IAlexiDoc,
  DocumentType,
} from '../types/interfaces'

interface AlexiDocumentProps {
  doc: IAlexiDoc
}

const AlexiDocument: React.FC<AlexiDocumentProps> = ({ doc }) => {
  const store = useStore()
  const [isHovered, setIsHovered] = useState(false)

  const handleViewDocument = (
    contentType: DocumentType,
    documentable: IInstantMemo | ISummaryDocument
  ) => {
    showModal(store, {
      contentType: contentType,
      contentId: documentable.id,
      data: documentable,
    })
  }

  return (
    <div
      className="group relative flex flex-col items-center text-center w-[10.625rem] pt-4 px-6 rounded-lg duration-300 cursor-pointer hover:bg-bgCol-disabled-default"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => scrollAndHighlightDocument(store, doc)}
      ref={doc.ref}
    >
      <div className="pt-2">
        <DocumentMagicIcon isHovered={isHovered} />
      </div>
      <div className="px-[0.5rem] w-[9.625rem]">
        <TruncatedText
          text={doc.file_name}
          additionalClasses="w-[10rem]"
          textClasses="text-sm font-medium truncate w-full text-txt-default-default"
        />
        <TruncatedText
          text={doc.documentable.generated_name}
          additionalClasses="w-[10rem]"
          textClasses="text-xs font-normal truncate w-full text-txt-default-secondary"
        />
      </div>

      <div className="absolute right-2 top-2 flex items-center opacity-0 space-x-2 group-hover:opacity-100 transition-opacity duration-300">
        <Tooltip content="Open" placement="left">
          <button
            onClick={() =>
              handleViewDocument(doc.documentable_type, doc.documentable)
            }
          >
            <ViewIcon />
          </button>
        </Tooltip>
        {doc.documentable_type === DocumentType.InstantMemo && (
          <MemoDownloadDropdown
            instantMemo={doc.documentable as IInstantMemo}
            forDocumentsSection={true}
          />
        )}
        {doc.documentable_type === DocumentType.SummaryDocument && (
          <Tooltip content="Download" placement="left">
            <a
              href={`/api/client/v1/summary_documents/${doc.documentable.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DownloadIcon />
            </a>
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default AlexiDocument
