import { IDocument } from '@cyntler/react-doc-viewer'
import React, { useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useSearchParams } from 'react-router-dom'

import { LoadingComponent } from './DocumentViewerHeader'
import useContainerSize from '../../../hooks/useContainerSize'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`
).toString()

interface PDFViewerProps {
  mainState: {
    currentDocument?: IDocument
  }
}

const PDFViewer = ({ mainState: { currentDocument } }: PDFViewerProps) => {
  const { containerRef, containerWidth } = useContainerSize()
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get('page'))
  const [totalPages, seTotalPages] = useState<number>()
  useEffect(() => {
    const interval = setInterval(() => {
      if (!page) {
        clearInterval(interval)
      }
      if (checkRenderingComplete()) {
        scrollToPage(page)
        clearInterval(interval)
      }
    }, 100)

    return () => clearInterval(interval) // Proper cleanup
  }, [page, totalPages])

  const checkRenderingComplete = () => {
    const renderedPages = document.querySelectorAll('.react-pdf__Page').length
    if (totalPages && renderedPages >= totalPages) {
      scrollToPage(page)
      return true
    }
    return false
  }

  const scrollToPage = (page: number) => {
    const pageToScroll = document.querySelector(
      `.react-pdf__Page[data-page-number="${page}"]`
    )
    if (pageToScroll) {
      pageToScroll.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    seTotalPages(numPages)
  }

  if (!currentDocument) {
    return null
  }

  return (
    <div id="custom-pdf-render" className="w-full" ref={containerRef}>
      <Document
        file={currentDocument.uri}
        className="w-full h-[calc(100vh-158px)]"
        loading={LoadingComponent}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.error('Error loading PDF:', error)}
      >
        <div className="overflow-y-auto h-full">
          {totalPages &&
            Array.from({ length: totalPages }, (_, index) => index + 1).map(
              (p) => (
                <Page
                  pageNumber={p}
                  loading={<></>}
                  width={containerWidth}
                  key={`pdf-file-page-${p}`}
                />
              )
            )}
        </div>
      </Document>
    </div>
  )
}

PDFViewer.fileTypes = ['pdf', 'application/pdf']
PDFViewer.weight = 1
export default PDFViewer
