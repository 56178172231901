import React, { ReactNode, useState, useEffect, useRef } from 'react'

import ChevronDownIcon from '../assets/ChevronDownIcon'
import ChevronRightIcon from '../assets/ChevronRightIcon'

interface AccordionProps {
  title: string | ReactNode
  body: string | ReactNode
  open: boolean
  titleClasses?: string
  bodyClasses?: string
  chevronStroke?: string
  chevronFill?: string
}

const Accordion = ({
  title,
  body,
  open,
  titleClasses = 'text-sm font-medium leading-default text-txt-brand-default',
  bodyClasses = 'text-sm mx-[1.625rem] mt-2',
  chevronStroke = 'stroke-icon-brand-default',
  chevronFill = 'fill-icon-brand-default',
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(open)
  const accordionRef = useRef<HTMLDivElement>(null)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    // Scroll to the accordion when it opens
    if (isOpen && accordionRef.current) {
      accordionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }
  }, [isOpen])

  return (
    <div ref={accordionRef} className="overflow-y-auto">
      <div
        className="flex justify-start items-center cursor-pointer py-1.5"
        onClick={toggleAccordion}
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row items-center">
            <div className="mr-1.5">
              {isOpen ? (
                <ChevronDownIcon stroke={chevronStroke} fill={chevronFill} />
              ) : (
                <ChevronRightIcon stroke={chevronStroke} fill={chevronFill} />
              )}
            </div>
            <p className={titleClasses}>{title}</p>
          </div>
          {isOpen && (
            <div className={bodyClasses}>
              {typeof body === 'string' ? <p>{body}</p> : body}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Accordion
