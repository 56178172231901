import {
  Button,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
} from '@material-tailwind/react'
import React, { useState } from 'react'

import { Folder } from '../../../helpers/Store'
import CheckMarkIcon from '../../assets/CheckMarkIcon'
import ChevronDownIcon from '../../assets/ChevronDownIcon'
import ChevronRightIcon from '../../assets/ChevronRightIcon'
import FolderIcon from '../../assets/FolderIcon'

interface FolderListProps {
  folders: Folder[]
  selectedFolder?: Folder
  onClick: (folder: Folder) => void
}

const FolderList = ({ folders, selectedFolder, onClick }: FolderListProps) => {
  const [openFolders, setOpenFolders] = useState<{ [key: string]: boolean }>({})
  const filteredFolders = folders.filter(
    (folder) => folder.folder_type !== 'alexi_generated'
  )

  const toggleFolder = (id: string) => {
    setOpenFolders((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const renderToggleIcon = (folder: Folder, isOpen: boolean) => {
    if (isOpen) {
      return (
        <ChevronDownIcon
          stroke="stroke-icon-neutral-default"
          fill="fill-icon-neutral-default"
        />
      )
    }
    return (
      <ChevronRightIcon
        stroke="stroke-icon-neutral-default"
        fill="fill-icon-neutral-default"
      />
    )
  }

  return (
    <List className="p-0">
      {filteredFolders.map((folder) => {
        const isRootFolder = folder.folder_type === 'root'
        const openFolder = isRootFolder || openFolders[folder.id]
        return (
          <React.Fragment key={folder.id}>
            <ListItem
              className="cursor-pointer focus:bg-bgCol-brand-secondary hover:bg-bgCol-brand-secondary active:bg-bgCol-brand-secondary py-2 px-3"
              onClick={() => onClick(folder)}
            >
              <ListItemPrefix className="flex items-center justify-center space-x-2">
                {!isRootFolder && folder.sub_folders.length > 0 && (
                  <Button
                    className="bg-transparent p-0"
                    onClick={() => toggleFolder(folder.id)}
                  >
                    {renderToggleIcon(folder, openFolder)}
                  </Button>
                )}
                <FolderIcon
                  className="stroke-icon-neutral-default"
                  stroke=""
                  backgroundColor=""
                  width={20}
                  height={20}
                />
              </ListItemPrefix>
              <span className="text-sm font-normal text-txt-default-default">
                {folder.title}
              </span>
              {selectedFolder?.id === folder.id && (
                <ListItemSuffix>
                  <CheckMarkIcon className="stroke-icon-brand-default" />
                </ListItemSuffix>
              )}
            </ListItem>
            {openFolder && folder.sub_folders.length > 0 && (
              <div className="ml-7">
                <FolderList
                  folders={folder.sub_folders}
                  onClick={onClick}
                  selectedFolder={selectedFolder}
                />
              </div>
            )}
          </React.Fragment>
        )
      })}
    </List>
  )
}

export default FolderList
