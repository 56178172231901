import {
  Button,
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import { Fragment, useState } from 'react'

import { setShowSidebar } from '../../actions/showLayoutActions'
import { Matter } from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import ThreeDotsIcon from '../assets/ThreeDotsIcon'
import DeleteMatterModal from '../DeleteMatterModal'
import SettingsModal from '../SettingsModal'

export interface MattersListItemProps {
  matter: Matter
  isSelected: boolean
  onMatterClick: (matter: Matter) => void
  onDeleteClick: (matter: Matter) => void
}

const MattersListItem = ({
  matter,
  isSelected,
  onMatterClick,
  onDeleteClick,
}: MattersListItemProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isDeleteMatterModalOpen, setIsDeleteMatterModalOpen] = useState(false)
  const [touchStart, setTouchStart] = useState({ x: 0, y: 0 })
  const [didScroll, setDidScroll] = useState(false)
  const store = useStore()

  const handleSettingsClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsSettingsOpen(true)
    setIsMenuOpen(false)
  }

  const handleDeleteClick = async (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsMenuOpen(false)
    setIsDeleteMatterModalOpen(true)
  }

  const handleMatterClick = () => {
    onMatterClick(matter)
  }

  const handleMobileMatterClick = () => {
    onMatterClick(matter)
    setShowSidebar(store, false)
  }

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart({ x: e.touches[0].clientX, y: e.touches[0].clientY })
    setDidScroll(false)
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    const deltaX = Math.abs(e.touches[0].clientX - touchStart.x)
    const deltaY = Math.abs(e.touches[0].clientY - touchStart.y)
    if (deltaX > 8 || deltaY > 8) {
      setDidScroll(true)
    }
  }

  const handleTouchEnd = () => {
    if (!didScroll) {
      handleMobileMatterClick()
    }
  }

  const focusTextColor = matter.is_alr
    ? 'text-txt-brand-default'
    : 'text-txt-brand-secondary'

  const defaultTextColor = matter.is_alr
    ? 'text-txt-neutral-default'
    : 'text-txt-neutral-secondary'

  const isSelectedBgColor = isSelected ? 'bg-bgCol-brand-secondary' : 'bg-white'
  const isSelectedTextColor = isSelected ? focusTextColor : defaultTextColor

  return (
    <Fragment>
      <ListItem
        key={matter.id}
        selected={isSelected}
        onClick={handleMatterClick}
        className={`h-11 p-2 pr-0 group cursor-pointer ${isSelectedBgColor} ${isSelectedTextColor} focus:bg-bgCol-brand-secondary md:hover:bg-bgCol-brand-secondary hover:${focusTextColor} focus:${focusTextColor}`}
        onMouseLeave={() => setIsMenuOpen(false)}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="flex items-center justify-between w-full space-x-2">
          <div className="flex flex-col whitespace-nowrap overflow-hidden">
            <span className={`truncate text-sm block`}>{matter.title}</span>
            {matter.file_number && (
              <p
                className={`text-xs ${isSelected ? 'text-txt-brand-secondary' : 'text-txt-neutral-secondary'} group-hover:text-txt-brand-secondary`}
              >
                {matter.file_number}
              </p>
            )}
          </div>
          <div>
            <Menu
              open={isMenuOpen}
              handler={setIsMenuOpen}
              placement="bottom-end"
            >
              <MenuHandler>
                <Button className="bg-transparent p-1 pr-2 border-0 opacity-0 group-hover:opacity-100 focus-visible:outline-none transition-opacity duration-150 z-50">
                  <ThreeDotsIcon />
                </Button>
              </MenuHandler>
              <MenuList className="p-0 min-w-max text-black text-sm shadow-md border-0 z-[9999]">
                <MenuItem onClick={handleSettingsClick}>View details</MenuItem>
                <MenuItem
                  className="text-txt-danger-default hover:text-txt-danger-default focus:text-txt-danger-default"
                  onClick={handleDeleteClick}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
      </ListItem>
      {isSettingsOpen && (
        <SettingsModal
          matter={matter}
          onClose={() => setIsSettingsOpen(false)}
        />
      )}
      {isDeleteMatterModalOpen && (
        <DeleteMatterModal
          matter={matter}
          onClose={() => setIsDeleteMatterModalOpen(false)}
          onDelete={onDeleteClick}
        />
      )}
    </Fragment>
  )
}

export default MattersListItem
