import React, { useState } from 'react'

import FailedToUploadFilesModal from './FailedToUploadFilesModal'
import UploadBatch from '../../actions/upload_batch/UploadBatch'
import { getUploadBatches } from '../../actions/upload_batch/uploadBatchActions'
import { useStore } from '../../helpers/useStore'
import WarningIcon from '../assets/WarningIcon'
import XIcon from '../assets/XIcon'

export const UploadFailuresNotice: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(true)
  const [showFailedDocsModal, setShowFailedDocsModal] = useState<boolean>(false)
  const store = useStore()

  // Handle click for "View failed documents" link
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setShowFailedDocsModal(true)
  }
  const uploadBatches = getUploadBatches(store, store.selectedMatter?.id)
  // Extract all failed documents from all upload batches
  const failedFiles = Object.values(uploadBatches)
    .flat() // Flatten all UploadBatch[] arrays into a single array
    .filter((batch): batch is UploadBatch => batch !== undefined) // Ensure batches exist
    .flatMap((batch) => batch.failedDocuments()) // Extract UploadRequest objects
    .map((uploadRequest) => uploadRequest.file) // Extract File objects

  const totalFailedCount = failedFiles.length

  // Hide the notice if there are no failed uploads
  if (!visible || totalFailedCount === 0) {
    return null
  }

  return (
    <>
      <div className="flex flex-col bg-bgCol-warning-tertiary border border-brd-brand-default text-txt-warning-default text-sm rounded-lg w-full">
        {/* First Row - Warning Icon, Message, and Close Button */}
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center text-txt-warning-default text-sm font-medium rounded-lg p-3 w-full">
            {/* Warning Icon on the Left */}
            <div className="mr-3">
              <WarningIcon className="w-[20px] h-[20px]" />
            </div>

            {/* Centered Text (Both Lines) */}
            <div className="flex flex-col flex-grow text-center">
              <span className="text-sm font-medium">
                {totalFailedCount} documents failed to upload.
              </span>
              <a
                href="#"
                onClick={handleClick}
                className="text-txt-brand-default text-sm font-medium hover:underline"
              >
                View failed documents
              </a>
            </div>

            {/* Close Button on the Right */}
            <button
              onClick={() => setVisible(false)}
              className="w-6 h-6 flex items-center justify-center ml-auto"
            >
              <XIcon classNames="w-[16px] h-[16px]" />
            </button>
          </div>
        </div>
      </div>

      {showFailedDocsModal && (
        <FailedToUploadFilesModal
          failedDocuments={failedFiles}
          onCancel={() => setShowFailedDocsModal(false)}
        />
      )}
    </>
  )
}
