import React from 'react'

import { FOLDER_TYPE } from '../../helpers/constants'
import { AlexiDocument } from '../../helpers/Store'
import ReferencedCaseIcon from '../assets/ReferencedCaseIcon'
import ReferencedWebSourceIcon from '../assets/ReferencedWebSourceIcon'

interface DocumentProps {
  doc: AlexiDocument
  folderType?: string
}

function iconContent(folderType?: string) {
  if (folderType === FOLDER_TYPE.referenced_cases) {
    return <ReferencedCaseIcon />
  } else {
    return <ReferencedWebSourceIcon />
  }
}

const AlexiDocumentTableRow: React.FC<DocumentProps> = ({
  doc,
  folderType,
}) => {
  const isUrl =
    doc.documentable_type === 'ReferencedCase' &&
    doc.documentable.case_reference_url

  return (
    <tr className="h-12 cursor-pointer hover:bg-gray-100 transition duration-500">
      <td className="p-1">
        <div className="flex justify-center align-center">
          {iconContent(folderType)}
        </div>
      </td>
      <td className="p-1 text-sm font-normal max-w-[100px] overflow-hidden truncate">
        {isUrl ? (
          <a
            href={doc.documentable.case_reference_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {doc.filename}
          </a>
        ) : (
          doc.filename
        )}
      </td>
      <td></td>
    </tr>
  )
}

export default AlexiDocumentTableRow
