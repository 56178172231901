import { observer } from 'mobx-react-lite'
import React from 'react'
import { useParams } from 'react-router-dom'

import DocumentManager from './DocumentManager'
import MainChat from './MainChat'
import { isMdSize } from '../../actions/showLayoutActions'
import { useStore } from '../../helpers/useStore'
import { useScreenSize } from '../../hooks/useScreenSize'

const ChatTemplate = observer(() => {
  const store = useStore()
  const { documentId } = useParams()
  const { screenWidth } = useScreenSize()
  const { showDocumentManager } = store.showLayouts

  if (!isMdSize(screenWidth)) {
    return (
      <div className="grid grid-cols-1">
        {!showDocumentManager ? <MainChat /> : <DocumentManager />}
      </div>
    )
  }

  // only show one column
  let gridColumns = 'grid-cols-1'
  let mainChatSpan = ''

  if (showDocumentManager) {
    if (documentId) {
      // change chat:document to 1:1 if preview document
      gridColumns = 'grid-cols-2'
      mainChatSpan = 'grid-cols-1'
    } else {
      // change chat:document to 2:1 if folder view
      gridColumns = 'grid-cols-3'
      mainChatSpan = 'col-span-2'
    }
  }

  return (
    <div className={`grid ${gridColumns}`}>
      <div className={mainChatSpan}>
        <MainChat />
      </div>
      {showDocumentManager ? <DocumentManager /> : null}
    </div>
  )
})

export default ChatTemplate
