import { Typewriter } from 'react-simple-typewriter'

const AdminHome = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <h1 className="text-4xl font-extrabold text-black">
        <Typewriter
          words={[
            '🚀 Welcome to the Admin Dashboard Home',
            '🎯 Built for internal Alexi admins',
            '⚙️ A work in progress',
            '📂 Manage everything... eventually',
            '🚧 Updates coming soon... maybe',
          ]}
          loop={0} // Infinite loop
          cursor
          cursorStyle="|"
          typeSpeed={50}
          deleteSpeed={30}
          delaySpeed={2000}
        />
      </h1>
    </div>
  )
}

export default AdminHome
