import {
  Card,
  Typography,
  Spinner,
  Input,
  Button,
} from '@material-tailwind/react'
import React, { useEffect, useState } from 'react'
import { AiFillExclamationCircle } from 'react-icons/ai'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import { useNavigate, useSearchParams } from 'react-router-dom'

import AdminMattersPagination from './AdminMattersPagination'
import { useStore } from '../../helpers/useStore'
import useAdminMatters from '../../hooks/admin/useAdminMatters'
import CloseIcon from '../assets/CloseIcon'

const TABLE_HEAD = [
  'Matter Name',
  'Enterprise Name',
  'Enterprise ID',
  'User Name',
  'User ID',
  'Jurisdiction',
  'State',
  'Country',
  'ALR?',
  'Created At',
]

export const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
}

const AdminMatters = () => {
  const store = useStore()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [page, setPage] = useState(Number(searchParams.get('page')) || 1)
  const [search, setSearch] = useState(searchParams.get('search') || '')
  const [searchInput, setSearchInput] = useState('')

  const { data, isLoading, isError } = useAdminMatters({
    store,
    page,
    search,
  })

  useEffect(() => {
    setSearchParams({ page: String(page), search })
  }, [page, search, setSearchParams])

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="text-center text-red-500 p-4">
        <p>Failed to load matters. Please try again.</p>
      </div>
    )
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setPage(1)
      setSearch(searchInput)
    }
  }

  const handleClearSearch = () => {
    setPage(1)
    setSearch('')
    setSearchInput('')
  }

  return (
    <div className="p-6 h-screen bg-gray-100">
      <Card className="h-full w-full p-4">
        <div className="w-full py-4">
          <div className="flex items-center gap-2">
            <Input
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              placeholder={'Search'}
              icon={<FaMagnifyingGlass className="h-5 w-5" />}
              className="!border-brd-brand-secondary focus:!border-brd-brand-default"
              labelProps={{
                className: 'hidden',
              }}
            />
            {searchInput && (
              <Button
                size="sm"
                variant="text"
                color="gray"
                className="px-2 text-gray-600 hover:text-black"
                onClick={handleClearSearch}
              >
                <CloseIcon />
              </Button>
            )}
          </div>
          <span className="text-sm mt-4 flex items-center gap-1">
            <AiFillExclamationCircle />
            You can search on
            <span className="text-sm font-medium">
              [Matter Name | Enterprise Name | Enterprise ID | User Name | User
              ID]
            </span>
          </span>
        </div>
        <div className="overflow-y-auto h-full">
          <table className="w-full table-auto text-left">
            <thead className="sticky top-0 bg-brd-brand-secondary">
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-semibold uppercase tracking-wide"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.matters.map((matter: any) => (
                <tr
                  key={matter.id}
                  className="hover:bg-brd-brand-tertiary cursor-pointer border-b border-brd-brand-secondary"
                  onClick={() => navigate(`/admin/matters/${matter.id}`)}
                >
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      {matter.assigned_name ||
                        matter.gen_name ||
                        'Untitled Matter'}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      {matter.enterprise_name}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      {matter.enterprise_id}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      {matter.user_full_name}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      {matter.user_id}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {matter.jurisdiction || 'N/A'}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {matter.state?.name || 'N/A'}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {matter.country?.name || 'N/A'}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {matter.is_alr ? 'Yes' : 'No'}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {formatDate(matter.created_at)}
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <AdminMattersPagination
          pagination={data.pagination}
          setPage={setPage}
        />
      </Card>
    </div>
  )
}

export default AdminMatters
