import { Tooltip } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useState, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate, useParams } from 'react-router-dom'

import { BreadcrumbsListModal } from './BreadcrumbsListModal'
import BreadrcrumbsSkeleton from './BreadrcrumbsSkeleton'
import { useStore } from '../../helpers/useStore'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'

interface EllipsisButtonProps {
  onClick: () => void
  tooltipContents: string
}

const EllipsisButton = ({ onClick, tooltipContents }: EllipsisButtonProps) => (
  <Tooltip content={tooltipContents} placement="bottom">
    <button
      className="text-txt-default-secondary hover:underline focus:outline-none"
      onClick={onClick}
    >
      ...
    </button>
  </Tooltip>
)

const Breadcrumbs: React.FC = observer(() => {
  const store = useStore()
  const navigate = useNavigate()
  const { id } = useParams()

  const { data, isLoading } = useBreadcrumbs(store)

  const [showModal, setShowModal] = useState(false)
  const [modalStyle, setModalStyle] = useState<{
    top: string | number
    left: string | number
    position: string
  }>({
    top: 0,
    left: 0,
    position: 'absolute',
  })

  const ellipsisButtonRef = useRef<HTMLDivElement>(null)

  if (!id) {
    return null
  }

  if (isLoading || !data) {
    return <BreadrcrumbsSkeleton />
  }

  const visibleBreadcrumbs = data.breadcrumbs.slice(-2)
  const hiddenBreadcrumbs = data.breadcrumbs.slice(0, -2)

  const handleEllipsisClick = () => {
    if (ellipsisButtonRef.current) {
      const { bottom, left } = ellipsisButtonRef.current.getBoundingClientRect()
      setModalStyle({
        top: bottom,
        left,
        position: 'absolute',
      })
    }
    setShowModal(true)
  }

  const handleBreadcrumbFolderClick = (breadcrumbId: string) => {
    navigate(`/chat/${id}/folder/${breadcrumbId}`)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const ModalContent = showModal && (
    <BreadcrumbsListModal
      closeModal={closeModal}
      modalStyle={modalStyle}
      breadcrumbs={hiddenBreadcrumbs}
      handleBreadcrumbsClick={(breadcrumbId) => {
        handleBreadcrumbFolderClick(breadcrumbId)
        closeModal()
      }}
    />
  )

  return (
    <>
      <nav className="flex items-center space-x-2 text-sm p-4">
        {/* Show ellipsis button if there are more than 3 breadcrumbs */}
        {data.breadcrumbs.length > 3 && (
          <>
            <div ref={ellipsisButtonRef}>
              <EllipsisButton
                onClick={handleEllipsisClick}
                tooltipContents="More"
              />
            </div>
            <span className="text-txt-default-secondary">/</span>
          </>
        )}
        {/* Render visible breadcrumbs (last two breadcrumbs) */}
        {visibleBreadcrumbs.map((breadcrumb, index) => {
          const isLastBreadcrumb = index === visibleBreadcrumbs.length - 1
          return (
            <React.Fragment key={breadcrumb.id}>
              <button
                onClick={() => {
                  // if breadcrumb type is not folder (e.g: file) => do nothing
                  if (breadcrumb.type === 'folder') {
                    handleBreadcrumbFolderClick(breadcrumb.id)
                  }
                }}
                className={`hover:underline ${
                  isLastBreadcrumb
                    ? 'text-txt-neutral-secondary' // Current breadcrumb color
                    : 'text-txt-default-secondary font-semibold' // Previous subdirectory color
                }`}
              >
                <Tooltip content={breadcrumb.title} placement="bottom">
                  <span className="truncate max-w-[8rem] lg:max-w-[12rem] block">
                    {breadcrumb.title}
                  </span>
                </Tooltip>
              </button>
              {!isLastBreadcrumb && (
                <span className="text-txt-default-secondary">/</span>
              )}
            </React.Fragment>
          )
        })}
      </nav>

      {ReactDOM.createPortal(ModalContent, document.body)}
    </>
  )
})

export default Breadcrumbs
