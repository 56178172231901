import React from 'react'

interface CheckMarkIconProps {
  className?: string
}
const CheckMarkIcon = ({
  className = 'text-green-500',
}: CheckMarkIconProps) => {
  return (
    <span className={`ml-2 flex items-center ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 13l4 4L19 7"
        />
      </svg>
    </span>
  )
}

export default CheckMarkIcon
