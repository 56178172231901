import React from 'react'

export const ReferencedCasesPlaceholderIcon: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      d="M38.0348 34.751L14.6055 58.1804C13.4404 59.3455 11.8602 60 10.2125 60C8.56476 60 6.98455 59.3455 5.81946 58.1804C4.65436 57.0153 3.99982 55.4351 3.99982 53.7874C3.99982 52.1397 4.65436 50.5595 5.81946 49.3944L29.2488 25.965M42.4278 45.0014L59.9998 27.4293M18.9985 21.572L36.5705 4M21.9271 18.6433L45.3565 42.0727M57.0711 30.358L33.6418 6.92867"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="stroke-icon-brand-default"
    />
  </svg>
)

export default ReferencedCasesPlaceholderIcon
