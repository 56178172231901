import React from 'react'
import { useParams } from 'react-router-dom'

import ChatMessage from './ChatMessage'
import InitialChatMessage from './InitialChatMessage'
import LoadingMessage from './LoadingMessage'
import { Message } from '../../helpers/Store'
import Loading from '../assets/Loading'

interface MainChatMessageGroupProps {
  isLoadingMessages: boolean
  messages?: Message[]
  containerWidth: number
  isNewChatResponseLoading: boolean
}

const MainChatMessageGroup = ({
  isLoadingMessages,
  messages,
  containerWidth,
  isNewChatResponseLoading,
}: MainChatMessageGroupProps) => {
  const { id: matterId } = useParams()

  const initialMessageGroup = (
    <div className="flex flex-col h-full">
      <div className="flex">
        <InitialChatMessage />
      </div>
    </div>
  )

  // new matter
  if (!matterId) {
    return initialMessageGroup
  }

  // existing matter but loading messages
  if (isLoadingMessages) {
    return <Loading />
  }

  // existing matter and complete load but no message (e.g: user uploads document but didn't input message)
  if (messages?.length === 0) {
    return initialMessageGroup
  }

  // regular chat message display
  return (
    <>
      {messages?.map((message: Message, idx: number) => (
        <ChatMessage
          key={`chat-message-${message.id}`}
          message={message}
          containerWidth={containerWidth}
          isLastMessage={idx === messages.length - 1}
        />
      ))}
      {isNewChatResponseLoading && <LoadingMessage />}
    </>
  )
}

export default MainChatMessageGroup
