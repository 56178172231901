import React from 'react'

import UploadButton from './UploadButton'
import UploadSectionDocumentIcon from '../assets/UploadSectionDocumentIcon'

interface UploadSectionProps {
  onFilesSelected: (files: File[]) => void
}

const UploadSection: React.FC<UploadSectionProps> = ({ onFilesSelected }) => {
  return (
    <>
      <div
        className={`h-full w-full flex flex-col justify-center items-center p-1.5 transition-all`}
      >
        <div className="flex flex-col justify-center items-center flex-grow">
          <UploadSectionDocumentIcon className="mb-6" />
          <div className="text-center gap-2 flex flex-col">
            <span className="font-medium leading-6">
              Drag & Drop or upload folders and files.
            </span>
            <span className="text-sm">
              Upload folders, .doc, .docx, .pdf, .ppt, .pptx files.
            </span>
          </div>
          <UploadButton className="mt-6" onFilesSelected={onFilesSelected} />
        </div>
      </div>
    </>
  )
}

export default UploadSection
