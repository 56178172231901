const MailIcon = ({
  className = 'stroke-icon-default-secondary',
}: {
  className?: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    className={className}
  >
    <path
      d="M20.2 5H5.8C4.80589 5 4 5.80589 4 6.8V17.6C4 18.5941 4.80589 19.4 5.8 19.4H20.2C21.1941 19.4 22 18.5941 22 17.6V6.8C22 5.80589 21.1941 5 20.2 5Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 7.69922L13.927 12.8292C13.6491 13.0033 13.3279 13.0956 13 13.0956C12.6721 13.0956 12.3509 13.0033 12.073 12.8292L4 7.69922"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default MailIcon
