import { Outlet } from 'react-router-dom'

import AdminSidebar from './AdminSidebar'

const AdminLayout = () => {
  return (
    <div className="flex h-screen w-screen">
      <AdminSidebar />

      {/* Main Content */}
      <div className="flex-1">
        <Outlet />
      </div>
    </div>
  )
}

export default AdminLayout
