import { useQuery } from '@tanstack/react-query'

import { getAdminMatters } from '../../apis/admin/adminMattersApi'
import Store from '../../helpers/Store'

interface useAdminMattersProps {
  store: Store
  page?: number
  search?: string
}

const useAdminMatters = ({
  store,
  page = 1,
  search = '',
}: useAdminMattersProps) => {
  return useQuery({
    queryKey: ['adminMatters', page, search],
    queryFn: () => getAdminMatters({ store, page, search }),
    staleTime: Infinity,
  })
}

export default useAdminMatters
