import { Spinner } from '@material-tailwind/react'
import React from 'react'
import { useParams } from 'react-router-dom'

import { formatDate } from './AdminMatters'
import { useStore } from '../../helpers/useStore'
import useAdminMatter from '../../hooks/admin/useAdminMatter'
import AlexiLogo from '../assets/alexiLogo'
import { renderChatContent } from '../main_chat/ChatMessage'

const AdminMatterViewer = () => {
  const store = useStore()
  const { matterId } = useParams()

  const { data, isLoading, isError } = useAdminMatter({
    store,
    matterId,
  })

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spinner />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="text-center text-red-500 p-4">
        <p>Failed to load matter. Please try again.</p>
      </div>
    )
  }

  const sortedChatMessages =
    data?.matter.chat_threads[0]?.chat_thread_messages.sort(
      (a: any, b: any) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    )

  const chatMessagesIsEmpty = sortedChatMessages?.length === 0

  return (
    <div className="w-full p-6 bg-gray-100 h-full flex flex-col">
      <div className="bg-white p-6 rounded-lg shadow-md mb-4">
        <h1 className="text-xl font-bold text-brd-brand-default mb-2">
          Matter Details
        </h1>

        <div className="grid grid-cols-2 gap-6 text-gray-600">
          <div>
            <p>
              <span className="font-semibold">Enterprise:</span>{' '}
              {data?.matter.enterprise_name}
            </p>
            <p>
              <span className="font-semibold">Enterprise ID:</span>{' '}
              {data?.matter.enterprise_id}
            </p>
            <p>
              <span className="font-semibold">User:</span>{' '}
              {data?.matter.user_full_name}
            </p>
            <p>
              <span className="font-semibold">User ID:</span>{' '}
              {data?.matter.user_id}
            </p>
          </div>

          <div>
            <p>
              <span className="font-semibold">Created:</span>{' '}
              {formatDate(data?.matter.created_at || '')}
            </p>
            <p>
              <span className="font-semibold">Jurisdiction:</span>{' '}
              {data?.matter.jurisdiction || 'N/A'}
            </p>
            <p>
              <span className="font-semibold">State:</span>{' '}
              {data?.matter?.state?.name}
            </p>
            <p>
              <span className="font-semibold">Country:</span>{' '}
              {data?.matter?.country?.name}
            </p>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md flex-1 overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Chat Conversation</h2>
        <div className="space-y-4">
          {chatMessagesIsEmpty && (
            <p className="text-gray-600">No chat messages found.</p>
          )}

          {sortedChatMessages.map((message: any) => {
            const isUser = message.role === 'user'

            return (
              <div
                key={message.id}
                className={`flex p-6 pb-0 items-start ${
                  isUser ? 'justify-end' : 'justify-start'
                }`}
              >
                {!isUser && (
                  <div className="flex-none w-10 h-10 mr-3">
                    <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
                      <AlexiLogo
                        fill="fill-icon-utilities-default"
                        width={16}
                        height={16}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={`text-gray-700 items-center py-3 px-4 rounded-lg overflow-hidden max-w-fit 
                  ${isUser ? 'self-end bg-bgCol-default-secondary' : 'self-start bg-bgCol-default-tertiary'}
              `}
                >
                  {renderChatContent({
                    message: message,
                    containerWidth: 0,
                    store,
                    isSystem: message.role !== 'user',
                  })}

                  <p className="text-xs mt-2 opacity-70">
                    {new Date(message.updated_at).toLocaleString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })}
                  </p>

                  {message.logfire_url && (
                    <a
                      href={message.logfire_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-2 inline-block text-blue-500 hover:text-blue-700 text-xs underline"
                    >
                      View Logfire
                    </a>
                  )}
                </div>

                {isUser && (
                  <div className="flex justify-center items-center bg-gray-50 rounded-full w-10 h-10 p-6 ml-3 border border-white shadow">
                    <span className="text-md text-black">User</span>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AdminMatterViewer
