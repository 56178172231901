import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  extractFilesAndFolders,
  processUploads,
} from '../actions/fileUploadActions'
import { setNewMatter } from '../actions/matterActions'
import { createMatter } from '../apis/mattersApi'
import { DEFAULT_UPLOAD_PATH, SUPPORTED_EXTENSIONS } from '../helpers/constants'
import { useStore } from '../helpers/useStore'

export const MAX_FILE_SIZE = 120 * 1024 * 1024 // 120 MB

export function useUploadToFolderWithConfirmation() {
  const store = useStore()
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [allFiles, setAllFiles] = useState<File[]>([])

  const [filesDetected, setFilesDetected] = useState(0)
  const [filesSupported, setFilesSupported] = useState(0)
  const [filesUnsupported, setFilesUnsupported] = useState(0)
  const isSelectedMatter = Boolean(store.selectedMatter)

  const startUploadFromDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    startUpload(e.dataTransfer.items, false)
  }

  const startUploadFromFiles = (
    files: File[],
    isFolderUpload: boolean = false
  ) => {
    startUpload(files, isFolderUpload)
  }

  const startUpload = async (
    filesAndFolders: File[] | DataTransferItemList,
    isFolderUpload: boolean
  ) => {
    const currentFolderPath =
      store.currentFolder.full_path || DEFAULT_UPLOAD_PATH

    const extractedFiles = await extractFilesAndFolders(
      filesAndFolders,
      currentFolderPath
    )

    if (isChromeOrFirefox() && isFolderUpload) {
      proceedWithUpload(extractedFiles)
      return
    }
    processExtractedFiles(extractedFiles)
  }

  function processExtractedFiles(extractedFiles: File[]) {
    let detectedCount = 0
    let supportedCount = 0
    let unsupportedCount = 0

    const filesToUpload: File[] = []

    extractedFiles.forEach((file) => {
      if (!(file instanceof File)) {
        filesToUpload.push(file)
        return
      }

      const ext = file.name.split('.').pop()?.toLowerCase() ?? ''
      const sizeOk = file.size <= MAX_FILE_SIZE

      const extensionOk = SUPPORTED_EXTENSIONS.includes(`.${ext}`)

      detectedCount++
      if (sizeOk && extensionOk) {
        supportedCount++
        filesToUpload.push(file)
      } else {
        unsupportedCount++
      }
    })

    setAllFiles(filesToUpload)
    setFilesDetected(detectedCount)
    setFilesSupported(supportedCount)
    setFilesUnsupported(unsupportedCount)

    setIsModalOpen(true)
  }

  const handleConfirm = async () => {
    setIsModalOpen(false)
    await proceedWithUpload()
  }

  const proceedWithUpload = async (files?: File[]) => {
    try {
      if (!isSelectedMatter) {
        const newMatter = await createMatter(store)
        await setNewMatter(store, newMatter)
        navigate(`/chat/${newMatter.id}`)
      }
      await processUploads(files || allFiles, store)
    } catch (error) {
      console.error('Error handling upload confirm:', error)
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const isChromeOrFirefox = (): boolean => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.includes('chrome') || ua.includes('firefox')
  }

  return {
    isModalOpen,
    filesDetected,
    filesSupported,
    filesUnsupported,
    startUploadFromDrop,
    startUploadFromFiles,
    handleConfirm,
    closeModal,
  }
}
