import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from '@material-tailwind/react'
import React, { useState } from 'react'

import { EnterpriseAttachment } from '../../../helpers/Store'
import ThreeDotsIcon from '../../assets/ThreeDotsIcon'

interface AttachmentActionMenuProps {
  attachment: EnterpriseAttachment
  onView: () => void
  onEditName: () => void
  onDownload: () => void
  onMove: () => void
  onDelete: () => void
}

const AttachmentActionMenu = ({
  attachment,
  onEditName,
  onView,
  onDownload,
  onDelete,
  onMove,
}: AttachmentActionMenuProps) => {
  const [isDocumentMenuOpen, setIsDocumentMenuOpen] = useState(false)

  if (attachment.processing_status !== 'COMPLETED') {
    return (
      <div className="h-3 rounded bg-pulse-gradient bg-200 animate-pulse-left-to-right"></div>
    )
  }

  return (
    <Menu
      open={isDocumentMenuOpen}
      handler={setIsDocumentMenuOpen}
      placement="bottom-end"
    >
      <MenuHandler>
        <Button className="bg-transparent p-0 h-8 w-full flex justify-center items-center focus-visible:outline-none">
          <ThreeDotsIcon variants="secondary" />
        </Button>
      </MenuHandler>
      <MenuList className="p-0 min-w-max text-black text-sm font-normal border-0 z-[9999]">
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            onEditName()
          }}
        >
          Edit the name
        </MenuItem>
        <MenuItem onClick={onView}>View</MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            onDownload()
          }}
        >
          Download
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            onMove()
          }}
        >
          Move to another folder
        </MenuItem>
        <MenuItem
          className="text-txt-danger-default hover:text-txt-danger-default focus:text-txt-danger-default"
          onClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default AttachmentActionMenu
