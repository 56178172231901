import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import rehypeRaw from 'rehype-raw'
import RemarkEmoji from 'remark-emoji'
import RemarkGfm from 'remark-gfm'

import ChatInteractionButtonGroup from './ChatInteractionButtonGroup'
import DocSummaryMessage from './doc_summary/DocSummaryMessage'
import { EmptyMemoMessage } from './memoGeneration/EmptyMemoMessage'
import MemoErrorMessage from './memoGeneration/MemoErrorMessage'
import MemoForm from './memoGeneration/MemoForm'
import MemoFormSubmitted from './memoGeneration/MemoFormSubmitted'
import MemoMessage from './memoGeneration/MemoMessage'
import {
  extractDocumentReferences,
  truncateAndAdjustHtml,
  verifyMarkdownContent,
} from './utils'
import {
  replaceAlexiTextWithLogo,
  replaceUploadIconWithLogo,
} from '../../actions/messagesActions'
import { ActivityItemStatus, MessageType } from '../../helpers/constants'
import { Message, ResponseAdditionalContext, Store } from '../../helpers/Store'
import { useStore } from '../../helpers/useStore'
import AlexiLogo from '../assets/alexiLogo'
import FailedFileCard from '../file_uploads/FailedFileCard'
import FileCard from '../file_uploads/FileCard'

interface ChatMessageProps {
  message: Message
  containerWidth: number
  isLastMessage: boolean
}

const ChatMessage = ({
  message,
  containerWidth,
  isLastMessage,
}: ChatMessageProps) => {
  const store = useStore()
  const [isHovered, setIsHovered] = useState(false)

  const isUser = message.role === 'user'
  const isSystem = message.role === 'system'

  if (
    isSystem &&
    message.message_type === MessageType.SYSTEM_MESSAGE &&
    !message.content &&
    ![
      MessageType.MEMO_FORM_RENDERED,
      MessageType.MEMO_FORM_SUBMITTED,
      MessageType.MEMO_RESULT,
    ].includes(message.message_type)
  ) {
    return null
  }

  const renderMessage = () => {
    switch (message.message_type) {
      case MessageType.MEMO_FORM_RENDERED:
        return (
          <MemoForm
            responseAdditionalContext={
              message.response_additional_context as ResponseAdditionalContext
            }
          />
        )
      case MessageType.MEMO_FORM_SUBMITTED:
        return (
          <MemoFormSubmitted
            responseAdditionalContext={
              message.response_additional_context as ResponseAdditionalContext
            }
          />
        )
      case MessageType.INSTANT_MEMO_RETRY_FORBIDDEN:
      case MessageType.INSTANT_MEMO_RETRY_SUGGESTED:
      case MessageType.INSTANT_MEMO_REJECTED:
        return <MemoErrorMessage message={message} store={store} />
      case MessageType.MEMO_RESULT:
        return <MemoMessage message={message} containerWidth={containerWidth} />
      case MessageType.DOC_SUMMARY_RESULT:
        return <DocSummaryMessage message={message} />
      case MessageType.EMPTY_MEMO_FORM:
        return <EmptyMemoMessage />
      default:
        return renderChatContent({ message, containerWidth, store, isSystem })
    }
  }

  const renderFileAttachments = message.enterprise_attachments
    ? message.enterprise_attachments.map((attachment) => {
        const isFailedOrInvalid = [
          ActivityItemStatus.FAILED,
          ActivityItemStatus.INVALID,
        ].includes(attachment.processing_status as ActivityItemStatus)

        return isFailedOrInvalid ? (
          <FailedFileCard
            key={attachment.file.id}
            id={attachment.file.id}
            filename={attachment.filename}
            error={attachment.error_msg}
          />
        ) : (
          <FileCard key={attachment.file.id} attachment={attachment} />
        )
      })
    : null

  const isMemoCancelledStep =
    MessageType.MEMO_FORM_CANCELLED === message.message_type

  const isFailedMemoStep = [
    MessageType.INSTANT_MEMO_RETRY_FORBIDDEN,
    MessageType.INSTANT_MEMO_RETRY_SUGGESTED,
    MessageType.INSTANT_MEMO_REJECTED,
  ].includes(message.message_type)

  const messageStyles = () => {
    switch (true) {
      case isMemoCancelledStep:
        return 'border-brd-default-default rounded-t-none rounded-tr-2xl border-0.5 border-solid rounded-br-2xl rounded-bl-2xl bg-transparent'
      case isFailedMemoStep:
        return 'border-brd-warning-secondary bg-transparent'
      default:
        return ''
    }
  }

  const isFullWidth = MessageType.MEMO_FORM_RENDERED === message.message_type
  const displayActionButtons = isHovered || isLastMessage

  return (
    <div
      data-role={message.role}
      className={`chat-message flex p-6 pb-0 items-start ${
        isUser ? 'justify-end' : 'justify-start'
      }`}
    >
      {!isUser && (
        <div className="flex-none w-10 h-10 mr-3">
          <div className="flex justify-center items-center bg-bgCol-brand-tertiary rounded-full w-10 h-10 border border-white shadow">
            <AlexiLogo width={17.673} height={16} />
          </div>
        </div>
      )}

      <div
        className={`
          text-gray-700 items-center py-3 px-4 rounded-l-lg rounded-br-lg overflow-hidden
          ${isUser ? 'self-end bg-bgCol-default-secondary' : 'self-start bg-bgCol-default-tertiary'}
          ${isFullWidth ? 'w-full' : 'max-w-fit'}
          ${messageStyles()}
        `}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {renderMessage()}
        <div className="flex flex-wrap justify-end gap-4 max-w-[670px]">
          {renderFileAttachments}
        </div>
        {message.role === 'system' && (
          <ChatInteractionButtonGroup showButtons={displayActionButtons} />
        )}
      </div>

      {message.role === 'user' && (
        <div className="flex justify-center items-center uppercase bg-gray-50 rounded-full w-10 h-10 p-4 ml-3 border border-white shadow">
          <span className="text-lg font-semibold text-black">
            {store.user?.initials}
          </span>
        </div>
      )}
    </div>
  )
}

interface RenderChatContentProps {
  message: Message
  containerWidth: number
  store: Store
  isSystem: boolean
}

export const renderChatContent = ({
  message,
  containerWidth,
  store,
  isSystem,
}: RenderChatContentProps) => {
  const adjustedContent = truncateAndAdjustHtml(message, containerWidth) || ''
  const preProcessedContent = extractDocumentReferences(adjustedContent)
  let processedContent = replaceAlexiTextWithLogo(preProcessedContent, store)
  processedContent = replaceUploadIconWithLogo(processedContent, store)

  // Temp fix for demo because the modal response is not stable as we expected
  processedContent = processedContent.replace(
    /https:\/\/example\.com\/document\//g,
    '/document/'
  )

  if (
    isSystem &&
    message.current_type === 'markdown' &&
    verifyMarkdownContent(processedContent)
  ) {
    return (
      <ReactMarkdown
        remarkPlugins={[[RemarkGfm], [RemarkEmoji]]}
        rehypePlugins={[rehypeRaw]} // Allows rendering of raw HTML
        className="no-tailwind markdown-content"
        components={{
          a: ({ node, ...props }) => {
            const herf = (node?.properties.href || '') as string
            const isInternalLink =
              herf.startsWith('document') || herf.startsWith('/')
            const normalizedHref = herf.startsWith('/') ? herf : `/${herf}`
            return isInternalLink ? (
              <Link
                to={`/chat/${store.selectedMatter?.id}${normalizedHref}`}
                {...props}
              />
            ) : (
              <a
                target={(node?.properties.dataTarget as string) || '_blank'}
                rel="noopener noreferrer"
                {...props}
              />
            )
          },
          p: ({ children }) => <p>{children}</p>,
        }}
      >
        {processedContent}
      </ReactMarkdown>
    )
  }

  // For non-system messages or non-markdown content
  return <span dangerouslySetInnerHTML={{ __html: processedContent }} />
}

export default ChatMessage
