import { Tooltip } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import PropTypes from 'prop-types'
import React from 'react'

import DocumentIcon from '../assets/DocumentIcon'

// Function to format file size
const formatFileSize = (sizeInBytes) => {
  const fileSizeInKB = sizeInBytes / 1024
  if (fileSizeInKB >= 1024) {
    return `${(fileSizeInKB / 1024).toFixed(1)} MB`
  } else {
    return `${fileSizeInKB.toFixed(1)} KB`
  }
}

const FileCard = ({ attachment }) => {
  return (
    <div
      key={attachment.file.id}
      className="relative p-3 border border-brd-neutral-default bg-bgCol-default-default rounded-lg group min-w-64"
    >
      <div className="flex">
        <div className="flex-shrink-0 p-2 bg-bgCol-neutral-default rounded">
          <DocumentIcon />
        </div>
        <div className="ml-2 flex-1">
          <Tooltip content={attachment.filename} placement="top">
            <p className="text-sm font-medium truncate w-40">
              {attachment.filename}
            </p>
          </Tooltip>
          {attachment.file.byte_size && (
            <p className="text-xs text-txt-default-secondary">
              {formatFileSize(attachment.file.byte_size)}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

FileCard.propTypes = {
  attachment: PropTypes.shape({
    content_type: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
    legacy_status: PropTypes.oneOf([
      'uploading',
      'uploaded',
      'validated',
      'error',
    ]),
    progress: PropTypes.any,
    error_msg: PropTypes.string,
    file: PropTypes.shape({
      id: PropTypes.string.isRequired,
      byte_size: PropTypes.number.isRequired,
    }).isRequired,
  }),
}

export default observer(FileCard)
