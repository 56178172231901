import React from 'react'

interface DownArrowIconProps {
  stroke?: string
}

const DownArrowIcon: React.FC<DownArrowIconProps> = ({
  stroke = 'stroke-txt-default-default',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M3 8L8 13M8 13L13 8M8 13L8 3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`${stroke}`}
    />
  </svg>
)

export default DownArrowIcon
