import React from 'react'

export const ReferencedWebSourcesPlaceholderIcon: React.FC<
  React.SVGProps<SVGSVGElement>
> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <path
      d="M32.001 58.6677C46.7286 58.6677 58.6677 46.7286 58.6677 32.001C58.6677 17.2734 46.7286 5.33435 32.001 5.33435C17.2734 5.33435 5.33435 17.2734 5.33435 32.001C5.33435 46.7286 17.2734 58.6677 32.001 58.6677Z"
      className="stroke-icon-brand-default"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33435 32.001H58.6677M32.001 5.33435C25.1536 12.5241 21.3343 22.0723 21.3343 32.001C21.3343 41.9297 25.1536 51.4779 32.001 58.6677C38.8484 51.4779 42.6677 41.9297 42.6677 32.001C42.6677 22.0723 38.8484 12.5241 32.001 5.33435Z"
      className="stroke-icon-brand-default"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ReferencedWebSourcesPlaceholderIcon
