import { useEffect, useRef, useState } from 'react'

const useContainerSize = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, _setContainerWidth] = useState<number>(0)
  const [containerHeight, _setContainerHeight] = useState<number>(0)

  const setContainerWidth = () => {
    if (containerRef.current) {
      _setContainerWidth(containerRef.current.offsetWidth)
    }
  }

  const setContainerHeight = () => {
    if (containerRef.current) {
      _setContainerHeight(containerRef.current.offsetHeight)
    }
  }

  useEffect(() => {
    const updateWidth = () => {
      setContainerWidth()
      setContainerHeight()
    }

    updateWidth() // Initial width calculation
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return {
    containerRef,
    containerWidth,
    containerHeight,
    setContainerWidth,
    setContainerHeight,
  }
}

export default useContainerSize
