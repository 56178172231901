import axios from 'axios'

import { extendSessionTimeout } from '../../actions/sessionActions'
import { rollbarConfig } from '../../helpers/rollbarConfig'
import { Store } from '../../helpers/Store'

interface AdminMatterApiProps {
  store: Store
  page?: number
  sort?: 'asc' | 'desc'
  search?: string
}

const getAdminMatters = async ({
  store,
  page = 1,
  search = '',
}: AdminMatterApiProps) => {
  extendSessionTimeout(store)

  try {
    const response = await axios.get(
      '/api/client/v1/admin_matters/get_admin_matters',
      {
        params: { page, search },
      }
    )

    if (response.status !== 200) {
      throw new Error('Failed to fetch admin matters')
    }

    return response.data
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
    throw error
  }
}

export { getAdminMatters }
