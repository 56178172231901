import { FOLDER_TYPE } from './constants'
import Store from './Store'

const UploadRestrictedFolderTypes = [
  FOLDER_TYPE.alexi_generated,
  FOLDER_TYPE.referenced_cases,
  FOLDER_TYPE.referenced_web_sources,
]

const UploadRestrictedFolderPaths = [
  'Home/Alexi Documents',
  'Home/Alexi Documents/Referenced Cases',
  'Home/Alexi Documents/Referenced Web Sources',
]

interface isCurrentFolderUploadRestrictedProps {
  store: Store
}

export const isCurrentFolderUploadEnabled = ({
  store,
}: isCurrentFolderUploadRestrictedProps): boolean => {
  const currentFolderType = store.currentFolder.folder_type ?? ''
  const currentFolderPath = store.currentFolder.full_path ?? ''

  const isFolderTypeRestricted = UploadRestrictedFolderTypes.includes(
    currentFolderType as FOLDER_TYPE
  )
  const isFolderPathRestricted =
    UploadRestrictedFolderPaths.includes(currentFolderPath)

  return !isFolderTypeRestricted && !isFolderPathRestricted
}

export const isAlexiDocumentFolder = (type?: string) => {
  return [
    FOLDER_TYPE.alexi_generated,
    FOLDER_TYPE.referenced_cases,
    FOLDER_TYPE.referenced_web_sources,
  ].includes(type as FOLDER_TYPE)
}

export const isAlexiReferencedCasesFolder = (type?: string) => {
  return [
    FOLDER_TYPE.referenced_cases,
    FOLDER_TYPE.referenced_web_sources,
  ].includes(type as FOLDER_TYPE)
}
