import React from 'react'

interface AnimatedTextProps {
  text: string
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ text }) => {
  return (
    <p className="font-medium">
      {text.split('').map((char, index) => (
        <span key={index} className={char === ' ' ? '' : 'animated-letter'}>
          {char}
        </span>
      ))}
    </p>
  )
}

export default AnimatedText
