const ReferencedCaseIcon = ({
  className = 'stroke-icon-default-default',
}: {
  className?: string
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.0834 10.417L5.41669 17.0837C5.08517 17.4152 4.63553 17.6014 4.16669 17.6014C3.69785 17.6014 3.24821 17.4152 2.91669 17.0837C2.58517 16.7521 2.39893 16.3025 2.39893 15.8337C2.39893 15.3648 2.58517 14.9152 2.91669 14.5837L9.58336 7.91699M13.3334 13.3337L18.3334 8.33366M6.66669 6.66699L11.6667 1.66699M7.50003 5.83366L14.1667 12.5003M17.5 9.16699L10.8334 2.50033"
        className={className}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ReferencedCaseIcon
