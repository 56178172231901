// AsyncChatMessage.tsx

import { observer } from 'mobx-react-lite'
import React, { useState, useMemo, useEffect } from 'react'

import AnimatedText from './AnimatedText'
import { Message, MessageToolCall } from '../../helpers/Store'
import AlexiLogo from '../assets/alexiLogo'
import CircleCheckIcon from '../assets/CircleCheckIcon'
import SpinnerIcon from '../assets/SpinnerIcon'
import Accordion from '../shared/Accordion'

const isProcessActive = (process: MessageToolCall): boolean => {
  const startTime =
    typeof process.async_estimate_at === 'string'
      ? new Date(process.async_estimate_at).getTime()
      : process.async_estimate_at.getTime()
  const finishTime = startTime + process.async_estimate_seconds * 1000
  return Date.now() < finishTime
}

const getLongestActiveProcess = (
  processes: MessageToolCall[]
): MessageToolCall | null =>
  processes.reduce<MessageToolCall | null>((longest, p) => {
    if (
      isProcessActive(p) &&
      (!longest || p.async_estimate_seconds > longest.async_estimate_seconds)
    ) {
      return p
    }
    return longest
  }, null)

const AsyncChatMessage = observer(
  ({ chat_thread_message }: { chat_thread_message: Message }) => {
    const [accordionKey, setAccordionKey] = useState(0)

    const longestActiveProcess = useMemo(
      () => getLongestActiveProcess(chat_thread_message.tool_processes || []),
      [chat_thread_message.tool_processes]
    )

    useEffect(() => {
      if (longestActiveProcess) {
        setAccordionKey((prev) => prev + 1)
      }
    }, [longestActiveProcess])

    const isAnyActive = Boolean(longestActiveProcess)
    const thinkingText = isAnyActive ? 'Thinking...' : 'Compiling answer...'

    const renderProcessStatus = (
      process: MessageToolCall,
      index: number,
      all: MessageToolCall[]
    ) => {
      if (
        index > 0 &&
        all.slice(0, index).some((p) => p.tool_message === process.tool_message)
      ) {
        return null
      }

      const active =
        isProcessActive(process) || process === longestActiveProcess

      return (
        <div
          key={`${process.tool_message}-${index}`}
          className="flex items-center ml-3 mt-3"
        >
          {active ? (
            <SpinnerIcon width={16} height={16} fill="none" stroke="white" />
          ) : (
            <CircleCheckIcon />
          )}
          <span
            className={`ml-2 ${
              active ? 'text-txt-default-tertiary' : 'text-txt-default-default'
            }`}
          >
            {process.tool_message}
          </span>
        </div>
      )
    }

    return (
      <div className="flex p-6 pb-0 items-start justify-start">
        <div className="flex-none w-10 h-10 mr-3">
          <div className="flex justify-center items-center bg-bgCol-brand-default rounded-full w-10 h-10 border border-white shadow">
            <AlexiLogo
              fill="fill-icon-utilities-default"
              width={16}
              height={16}
            />
          </div>
        </div>

        <div
          className="items-center py-3 px-4 rounded-l-lg rounded-br-lg overflow-hidden
                      self-start bg-bgCol-default-tertiary min-w-max w-1/3"
        >
          {/*
          KEY TRICK:
          Each time `accordionKey` changes, the Accordion unmounts and re-mounts.
          The existing Accordion code uses its `open` prop only as the initial state,
          so re-mounting is the simplest way to ensure it opens again for new processes.
        */}
          <Accordion
            key={accordionKey}
            open={isAnyActive}
            title={<AnimatedText text={thinkingText} />}
            body={
              <div className="ml-2 pr-1 border-l-2 border-brd-default-default">
                {chat_thread_message.tool_processes?.map(renderProcessStatus)}
              </div>
            }
            titleClasses="flex items-center justify-between cursor-pointer"
            bodyClasses="text-sm font-normal"
            chevronStroke="stroke-txt-default-default"
            chevronFill="fill-txt-default-default"
          />
        </div>
      </div>
    )
  }
)

export default AsyncChatMessage
