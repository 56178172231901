import { makeAutoObservable, computed } from 'mobx'

import { DEFAULT_UPLOAD_PATH } from './constants'
import Store, { Activity, Folder, Message } from './Store'
import UploadBatch from '../actions/upload_batch/UploadBatch'
import { groupByDateAndThread } from '../actions/workflowActivityActions'

const hasInProgressBatch = (batches: UploadBatch[] = []) => {
  return batches.some((batch) => batch.batchStatus !== 'completed')
}

class ComputedAttributes {
  store: Store

  constructor(store: Store) {
    this.store = store

    makeAutoObservable(this, {
      groupedActivities: computed,
      uploadDefaultDocumentUploadPath: computed,
      mostRecentUserMessage: computed,
      mostRecentMessage: computed,
      uploadInProgress: computed,
      matterUploadInProgress: computed,
      uploadsPreventUnloadingPage: computed,
    })
  }

  // Computed property for grouping activities by date and thread ID
  get groupedActivities(): {
    [date: string]: { [threadId: string]: Activity[] }
  } {
    return groupByDateAndThread(this.store)
  }

  get uploadDefaultDocumentUploadPath(): Partial<Folder> | null {
    if (this.store.selectedMatter?.id !== this.store.currentFolder.matter_id) {
      return null
    }
    return (
      this.store.currentFolder.sub_folders?.find(
        (folder) => folder.title === DEFAULT_UPLOAD_PATH
      ) || this.store.currentFolder
    )
  }

  get mostRecentUserMessage(): Message | null {
    if (!this.store.selectedMatter) {
      return null
    }

    const messagesForMatter = this.store.messages[this.store.selectedMatter.id]

    // Filter only the user messages. Adjust the property name/value as needed.
    const userMessages = messagesForMatter.filter(
      (msg) => msg.message_type === 'userMessage'
    )

    if (userMessages.length === 0) {
      return null
    }

    // Return the last user message in the filtered array
    return userMessages[userMessages.length - 1]
  }

  get mostRecentMessage(): Message | null {
    if (!this.store.selectedMatter) {
      return null
    }
    const length = this.store.messages[this.store.selectedMatter.id].length

    if (length === 0) {
      return null
    }

    return this.store.messages[this.store.selectedMatter.id][length - 1]
  }

  // If any batch still has any work to complete (upload or processing) across all matters, this should return true
  get uploadInProgress(): boolean {
    return Object.values(this.store.uploadBatches).some((batches) =>
      hasInProgressBatch(batches)
    )
  }

  // Checks if any upload batches are in progress for the current matter
  get matterUploadInProgress(): boolean {
    if (!this.store.selectedMatter?.id) {
      return false
    }

    const batches = this.store.uploadBatches[this.store.selectedMatter.id]

    return hasInProgressBatch(batches)
  }

  // We can leave the page safely if every UploadRequest has uploaded, even if it has not finished processing
  get uploadsPreventUnloadingPage(): boolean {
    for (const matterId of Object.keys(this.store.uploadBatches)) {
      const batches = this.store.uploadBatches[matterId]

      if (!batches) {
        continue
      }

      for (const batch of batches) {
        return !batch.allFilesUploaded()
      }
    }

    return false
  }
}

export default ComputedAttributes
