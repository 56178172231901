import { Navigate, Outlet } from 'react-router-dom'

import { useStore } from '../../helpers/useStore'

const AdminProtectedRoute = () => {
  const store = useStore()

  const isAlexiAdmin = store.user?.is_alexi_admin === true

  if (!isAlexiAdmin) {
    return <Navigate to="/chat" replace />
  }

  return <Outlet />
}

export default AdminProtectedRoute
