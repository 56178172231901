import { action, runInAction } from 'mobx'
import React from 'react'

import { fetchFiles } from '../apis/filesApi'
import { rollbarConfig } from '../helpers/rollbarConfig'
import Store, { EnterpriseAttachment, Folder } from '../helpers/Store'

const removeFile = action((store: Store, blobId: string) => {
  store.uploadedFiles = store.uploadedFiles.filter(
    (attachment) => attachment.file.id !== blobId
  )
})

const setFetchedDocuments = action(async (store: Store) => {
  try {
    const documents = await fetchFiles(store)

    if (documents.length === 0) {
      runInAction(() => {
        store.documents = [] // reset document store
      })
      return
    }

    runInAction(() => {
      store.documents = documents.map((document) => ({
        ...document,
        ref: React.createRef(),
      }))
    })
  } catch (error) {
    rollbarConfig(store)?.error(error as Error)
  }
})

const setFetchedFile = action(
  (store: Store, file: EnterpriseAttachment | undefined) => {
    try {
      if (!file) {
        runInAction(() => {
          store.currentDocument = null
        })
        return
      }

      runInAction(() => {
        store.currentDocument = file
      })
      return file
    } catch (error) {
      rollbarConfig(store)?.error(error as Error)
    }
  }
)

const setFetchedFilesAndFolders = action(
  async (store: Store, folders: Folder | undefined) => {
    try {
      if (folders === undefined) {
        runInAction(() => {
          store.currentFolder = {}
        })
        return
      }

      runInAction(() => {
        store.currentFolder = folders

        // CONSISTENCY SETTING
        store.documentPagination.totalCount = folders.meta.total_count
        store.documentPagination.totalPages = folders.meta.total_pages
        store.documentPagination.page = folders.meta.current_page
        store.documentPagination.perPage = folders.meta.per_page
      })
    } catch (error) {
      console.error(error)
      rollbarConfig(store)?.error(error as Error)
    }
  }
)

const setCurrentFolder = action((store: Store, value: Folder | null) => {
  store.currentFolder = value || {}
})

export {
  removeFile,
  setFetchedFile,
  setFetchedDocuments,
  setFetchedFilesAndFolders,
  setCurrentFolder,
}
