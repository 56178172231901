import React from 'react'

import ReferencedCasesPlaceholderIcon from '../../assets/ReferencedCasesPlaceholderIcon'

const EmptyFolderPlaceholder = () => {
  return (
    <>
      <div
        className={`h-full w-full flex flex-col justify-center items-center p-1.5 transition-all`}
      >
        <div className="flex flex-col justify-center items-center flex-grow">
          <ReferencedCasesPlaceholderIcon className="mb-6" />
          <div className="text-center gap-2 flex flex-col">
            <span className="font-medium leading-6">
              No files found in folder
            </span>
            <span className="text-sm">
              Upload files in the following formats, .doc, .docx,
            </span>
            <span className="text-sm">.pdf, .ppt, .pptx files</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default EmptyFolderPlaceholder
