import React from 'react'

import { EnterpriseAttachment, Folder } from './Store'
import AlexiGeneratedFolderIcon from '../components/assets/AlexiGeneratedFolderIcon'
import DocxDocumentIcon from '../components/assets/DocxDocumentIcon'
import FolderIcon from '../components/assets/FolderIcon'
import JpgDocumentIcon from '../components/assets/JpgDocumentIcon'
import PdfDocumentIcon from '../components/assets/PdfDocumentIcon'
import PptDocumentIcon from '../components/assets/PptDocumentIcon'
import XLSDocumentIcon from '../components/assets/XLSDocumentIcon'

export enum ActivityItemStatus {
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  INVALID = 'INVALID',
  REJECTED = 'REJECTED',
}

export enum MessageType {
  USER_MESSAGE = 'userMessage',
  SYSTEM_MESSAGE = 'systemMessage',
  MEMO_FORM_RENDERED = 'memoFormRendered',
  MEMO_FORM_SUBMITTED = 'memoFormSubmitted',
  INSTANT_MEMO_RETRY_FORBIDDEN = 'instantMemoRetryForbidden',
  INSTANT_MEMO_RETRY_SUGGESTED = 'instantMemoRetrySuggested',
  INSTANT_MEMO_REJECTED = 'instantMemoRejected',
  MEMO_RESULT = 'memoResult',
  DOC_SUMMARY_RESULT = 'docSummaryResult',
  EMPTY_MEMO_FORM = 'emptyMemoForm',
  MEMO_FORM_CANCELLED = 'memoFormCancelled',
}

// These are legacy statuses, related to the chat message + filecard attachment system
export enum FileUploadStatus {
  Uploading = 'uploading',
  Uploaded = 'uploaded',
  Validated = 'validated',
  Error = 'error',
}

const ROOT_FOLDER_TITLE = 'Home'
export enum FOLDER_TYPE {
  root = 'root',
  alexi_generated = 'alexi_generated',
  user_created = 'user_created',
  referenced_cases = 'referenced_cases',
  referenced_web_sources = 'referenced_web_sources',
}

export const DEFAULT_UPLOAD_PATH = ROOT_FOLDER_TITLE

export const LEGACY_INITIAL_CHAT_SUGGESTIONS = [
  {
    label: 'Get an answer to a legal question',
    input: 'Can you help me answer a legal question?',
  },
  {
    label: 'Get a research memo',
    input: 'Can you prepare a research memo for me?',
  },
  {
    label: 'Ask a question about a document',
    input: 'Can you help me answer a question about a document?',
  },
  {
    label: 'Summarize a document',
    input: 'Can you help me summarize a document?',
  },
  {
    label: 'Draft a document',
    input: 'Can you help me draft a document?',
  },
]

// TODO: waiting product team to finalize the chat messages
export const INITIAL_CHAT_SUGGESTIONS = [
  {
    label: 'Analyze uploaded documents',
    input: 'Can you help me analyze uploaded documents?',
  },
  {
    label: 'Build persuasive arguments',
    input: 'Can you help me build persuasive arguments?',
  },
  {
    label: 'Refine legal strategies',
    input: 'Can you help me refine legal strategies?',
  },
  {
    label: 'Ask about the law',
    input: 'Can you help me answer about the law?',
  },
  {
    label: 'Find a case',
    input: 'Can you help me find a case?',
  },
  {
    label: 'Get a research memo',
    input: 'Can you help me get a research memo?',
  },
  {
    label: 'Automate a first draft',
    input: 'Can you help me automate a first draft?',
  },
]

export const SUPPORTED_EXTENSIONS = ['.doc', '.docx', '.pdf', '.ppt', '.pptx']

export const FILE_TYPE_MAP: { [key: string]: string } = {
  'application/msword': 'Word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'Word',
  'application/vnd.ms-powerpoint': 'PowerPoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'PowerPoint',
  'application/pdf': 'PDF',
  'image/jpeg': 'JPEG',
  'image/png': 'PNG',
  'image/gif': 'GIF',
  'image/webp': 'WebP',
}

export const getIconForFileType = (attachment: EnterpriseAttachment) => {
  let IconComponent: React.ComponentType<any>

  switch (attachment.content_type.toLowerCase()) {
    case 'application/pdf':
      IconComponent = PdfDocumentIcon
      break
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
      IconComponent = JpgDocumentIcon
      break
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      IconComponent = DocxDocumentIcon
      break
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
      IconComponent = PptDocumentIcon
      break
    case 'text/csv':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      IconComponent = XLSDocumentIcon
      break
    default:
      IconComponent = FolderIcon
  }

  const commonProps = {
    className: 'stroke-icon-neutral-default',
    stroke: '',
    backgroundColor: '',
    width: 18,
    height: 18,
  }

  return React.createElement(IconComponent, commonProps)
}

export const getIconForFolderType = (folder: Folder) => {
  let IconComponent: React.ComponentType<any>
  switch (folder.folder_type) {
    case FOLDER_TYPE.alexi_generated:
      IconComponent = AlexiGeneratedFolderIcon
      break
    case FOLDER_TYPE.user_created:
      IconComponent = FolderIcon
      break
    default:
      IconComponent = FolderIcon
  }

  const commonProps = {
    className: 'stroke-icon-neutral-default',
    stroke: '',
    backgroundColor: '',
    width: 18,
    height: 18,
  }

  return React.createElement(IconComponent, commonProps)
}
