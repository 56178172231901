import { makeAutoObservable, toJS, reaction } from 'mobx'
import React from 'react'

import ComputedAttributes from './ComputedAttributes'
import { MessageType, FileUploadStatus } from './constants'
import UploadBatch from '../actions/upload_batch/UploadBatch'
import { UserInfo } from '../apis/userApi'
import {
  IInstantMemo,
  ISummaryDocument,
  IAlexiDoc,
  IEnterpriseDoc,
} from '../components/types/interfaces'

class Store {
  computed = new ComputedAttributes(this)

  // User-related Data
  user: UserInfo | null = null
  credentials: Credentials = {
    customerAppUrl: '',
  }

  // Input and Interaction
  inputValue = ''
  inputRef: any = null
  isInputFocused = true
  pendingUrlReplacements = new Map()

  uploadedFiles: File[] = []
  chunkBuffer = ''
  fetchedUrls = new Set()

  // Matter-related Data
  matters: Matter[] = []
  selectedMatter: Matter | null = null
  activities: Activity[] = []
  activitiesLoading: ActivitiesLoading = {}
  documents: (IAlexiDoc | IEnterpriseDoc)[] = []
  currentFolder: Partial<Folder> = {}
  currentDocument:
    | IInstantMemo
    | EnterpriseAttachment
    | ISummaryDocument
    | null = null
  matterUploadsInProgress = {}

  // Messaging and Notifications
  chatResponseLoading = {}
  flashes: Flash[] = []
  lastProcessedIds = {}
  messageQueues = {}
  messages: Record<string, Message[]> = {}
  isStreaming: Record<string, boolean> = {}

  // Channels
  matterChannels = {}
  activityChannels = {}
  documentChannels = {}

  userChannel = null // User channel message related

  showLatestMessagesButton = false
  highlightedMessageRef: null | React.RefObject<HTMLElement> = null
  highlightedActivityRef: null | React.RefObject<HTMLElement> = null

  // Workflow and Steps
  currentStep = 0
  workflowTab = 'activities' // 'activities' or 'documents'

  // Data Management
  sortParams: Map<string, string> | undefined = undefined
  filterParams: Map<string, string> | undefined = undefined
  manifest = null

  documentSortParams: Map<string, string> = new Map([
    ['sortBy', 'name'],
    ['sortOrder', 'asc'],
  ])

  documentPagination: DocumentPagination = {
    page: 1,
    perPage: 25,
    totalPages: 1,
    totalCount: 0,
  }

  modal: ModalStore = {
    isOpen: false,
    isLoading: false,
    contentType: null,
    contentId: null,
    errorMessage: null,
    data: {},
  }

  // Onboarding step
  current_onboarding_step = null
  onboarding_completed = false

  // layout store
  showLayouts: ShowLayout = {
    showSidebar: true,
    showDocumentManager: false,
  }

  // Batched file uploads
  // matterId => UploadBatch[]
  uploadBatches: Record<string, UploadBatch[] | undefined> = {}

  // encapsulates non-alr actions/apis
  isAlr: boolean = false

  constructor() {
    makeAutoObservable(this)

    const storedActivitiesLoading = localStorage.getItem('activitiesLoading')

    if (storedActivitiesLoading !== null) {
      this.activitiesLoading = JSON.parse(storedActivitiesLoading)
    }

    // Automatically reset currentDocument when selectedMatter/currentFolder changes
    reaction(
      () => ({
        currentFolder: this.currentFolder,
        selectedMatterId: this.selectedMatter?.id,
      }),
      (
        { currentFolder, selectedMatterId },
        { currentFolder: prevFolder, selectedMatterId: prevMatterId }
      ) => {
        if (currentFolder !== prevFolder || selectedMatterId !== prevMatterId) {
          this.currentDocument = null
        }
      }
    )
  }
}

export function printStoreAttributes(store: { [x: string]: unknown }) {
  Object.keys(store).forEach((key) => {
    /* eslint-disable no-console */
    console.log(`${key}:`, toJS(store[key]))
  })
}

export default Store

export type { Store }

export interface Activity {
  id: string
  created_at: string
  chat_thread_message_id: string
  [key: string]: any
}

interface ActivitiesLoading {
  [id: string]: ActivityLoadingItem
}

interface ActivityLoadingItem {
  durationElapsed: number
  durationEstimate: number
  durationStartTime: number
  intervalId: ReturnType<typeof setInterval> | null
}

interface ModalStore {
  isOpen: boolean
  isLoading: boolean
  contentType: null | 'InstantMemo' | 'SummaryDocument' | 'EnterpriseAttachment'
  contentId: null | string
  errorMessage: null | string
  data: InstantMemoModalData | object
}

interface InstantMemoModalData {
  instant_memo: IInstantMemo
  instant_memo_html: string
}

export interface Credentials {
  launchDarklyClientId?: string
  customerAppUrl: string
  rollbarAccessToken?: string
  rollbarEnvironment?: string
  hotjarId?: number
  hotjarVersion?: number
  googleAnalyticsId?: string
}

interface ChatThread {
  id: string
  name: string | null
  created_at: string
}

export interface Matter {
  id: string
  assigned: boolean
  title: string
  gen_name: string | null
  user_id: number
  thread_id: string
  updated_at: string
  file_number: string | null
  created_at: string
  is_scheduled_for_deletion: boolean
  chat_threads: ChatThread[]
  jusrisdiction: string | null
  state_id: string
  display_formatted_jurisdiction: string | null
  is_alr: boolean
}

interface ShowLayout {
  showSidebar: boolean
  showDocumentManager: boolean
}

export interface File {
  content_type: string
  e_attachment_id: string
  file: FileDetails
  filename: string
  progress: string
  status: string
  icon_for_content_type: string
  matterId: string
  error_msg: string | null
}

export interface FileDetails {
  id: string
  byte_size: number
  content_type: string
  file_name: string
  url: string
}

interface ReplacementUrlMetadata {
  replacement_url: string
  doc_id: number
}

export interface Message {
  id: string
  content: string
  role: 'user' | 'system'
  created_at: string
  message_type: MessageType
  response_additional_context?: ResponseAdditionalContext
  current_type: 'text' | 'file' | 'markdown'
  replacement_urls_metadata?: Record<string, ReplacementUrlMetadata>
  finish_time_at?: Date
  tool_processes?: MessageToolCall[]
  async_progress: undefined | number // in [0,1], to animate progression of the progress bar

  thread_id: string
  matter_id: string
  instant_memo_id?: string
  instant_memo_title?: string
  instant_memo_retries?: number
  enterprise_attachments?: EnterpriseAttachment[]
  summary_document?: ISummaryDocument
}

export interface ResponseAdditionalContext {
  research_facts?: string
  research_question?: string
  jurisdiction?: string
  state?: string
}

export interface Folder {
  id: string
  title: string
  parent_id?: string
  matter_id: string
  full_path: string
  folder_type: string
  is_empty_folder: boolean
  documents: EnterpriseAttachment[]
  alexi_documents: AlexiDocument[]
  sub_folders: Folder[]
  meta: {
    total_count: number
    per_page: number
    current_page: number
    total_pages: number
  }
}

export interface DocumentPagination {
  page: number
  perPage: number
  totalCount: number
  totalPages: number
}

export interface FileMetadata {
  user_id: number
  analyzed: boolean
}

export interface AttachmentFileDetails {
  id: string
  key: string
  filename: string
  content_type: string
  metadata: FileMetadata
  service_name: string
  byte_size: number
  checksum: string
  created_at: string
  attachable_sgid: string
}

export interface EnterpriseAttachment {
  // Deserialized attributes
  id: string
  content_type: string
  created_at: string
  updated_at: string
  filename: string
  file: AttachmentFileDetails
  key: string
  generated_name?: string | null
  generated_description?: string
  chat_thread_message_id?: string | null
  folder_id: string
  processing_duration_estimate: number // Integer valued, seconds
  processing_started_at: string
  processing_status: EnterpriseAttachmentProcessingStatus

  // Local sttributes
  legacy_status?: FileUploadStatus
  progress?: number // Legacy attribute
  error_msg?: string // is this strictly local? possibly not?
  loadingEstimateMessage?: string
  showCompletedGradient?: boolean
}

export type EnterpriseAttachmentProcessingStatus =
  | null
  | 'STARTED'
  | 'COMPLETED'
  | 'FAILED'
  | 'INVALID'

export interface Flash {
  id: number
  type: 'success' | 'error' | 'notice'
  message: string
  metadata?: {
    subType?: string
  }
}

export interface MessageToolCall {
  id?: string
  async_estimate_seconds: number
  async_estimate_at: Date
  tool_message: string
}
export interface ChatMessageToolCallProps {
  id: string
  finish_time_at: Date
  tool_processes: MessageToolCall[]
}

export type AlexiDocumentType = 'ReferencedCase' // Add more types as needed

interface ReferencedCase {
  id: string
  title: string
  case_reference_url: string
  matter_id: string
  folder_id: string
  chat_thread_message_id: string
  createdAt: string
  updatedAt: string
}

export interface AlexiDocument {
  id: string
  created_at: string
  updated_at: string
  filename: string
  documentable_type: AlexiDocumentType
  documentable: ReferencedCase
  matter_id: string
}
