import { Tooltip } from '@material-tailwind/react'
import React from 'react'

import ArrowDownIcon from '../assets/ArrowDownIcon'
import ArrowUpIcon from '../assets/ArrowUpIcon'

interface TableSortButtonProps {
  columnName: string
  columnKey: string
  sortBy: string
  sortOrder: string
  handleSortButtonClick: (sortKey: string) => void
}
const TableSortableHeader = ({
  columnName,
  columnKey,
  sortBy,
  sortOrder,
  handleSortButtonClick,
}: TableSortButtonProps) => {
  return (
    <button
      className="p-0"
      onClick={() => {
        handleSortButtonClick(columnKey)
      }}
    >
      <div className="flex items-center space-x-0.5 group">
        {' '}
        {/* Updated to flex for horizontal alignment */}
        <span>{columnName}</span>
        {sortBy === columnKey && (
          <div className="flex items-center">
            {' '}
            {/* Add spacing between the text and icon */}
            {sortOrder === 'asc' ? (
              <Tooltip placement="bottom" content="Sort ascending">
                <span>
                  <ArrowUpIcon className="stroke-icon-default-default group-hover:stroke-icon-brand-default" />
                </span>
              </Tooltip>
            ) : (
              <Tooltip placement="bottom" content="Sort descending">
                <span>
                  <ArrowDownIcon className="stroke-icon-default-default group-hover:stroke-icon-brand-default" />
                </span>
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </button>
  )
}

export default TableSortableHeader
